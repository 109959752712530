import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ADD_TOPUP,
  SAVE_TOPUP,
} from '../../core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TopUpService {
  constructor(private readonly http: HttpClient) { }

  addtopup(pan: string, fundId: string) {
    const url = environment.APIURL + ADD_TOPUP + `/${pan}/${fundId}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }



  saveTopup(pan: string,fundId:string,isCKYC:boolean) {
    const url = environment.APIURL + SAVE_TOPUP + `/${pan}`+ `/${fundId}` + `/${isCKYC}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

}
