import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, validationType } from '../../core/model/error.model';
import { AddHolder } from '../../core/model/holder.model';
import { CommonService } from '../../services/common/common.service';
import { HolderService } from '../../services/holder/holder.service';
import { InvestmentService } from '../../services/investment/investment.service';
import { TopUpService } from '../../services/topup/topup.service';
import { ApplicationService } from '../../services/application/application.service';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { formatDate } from '@angular/common';
import { UserService } from '../../services/user/user.service';
import { parse } from 'path';

@Component({
  selector: 'non-kyc-model',
  templateUrl: './non-kyc-model.component.html',
  styleUrls: ['./non-kyc-model.component.scss'],
})
export class nonkycModelComponent implements OnInit {
  holderForm: FormGroup;
  error = Errors;
  showLoader = false;
  headerText = ''; //HEADER_TEXT_PANCHECK;
  messageText = ''; //MESSAGE_TEXT_PANCHECK;
  todayDate = new Date();
  application: any;
  applicantUuid: string;
  parentFund: any;
  holderapplication: any;
  showValidation = false;
  showDraftValidation = false;
  isOnSubmit = true;
  isOnSuccess = false;
  isTopup = false;
  fundDropdownDisable = false;
  @Input() applicationUuid: string;
  @Input() holder: any;
  @Input() topupHolder: any;
  @Input() onPanCheck: boolean;
  @Input() isBeneficiary: boolean;
  @Input() isUBO: boolean;
  @Input() isContactPerson: boolean;
  @Input() isPOA: boolean;
  @Input() BeneficiaryTypeNo: number;
  @Output() add: EventEmitter<any> = new EventEmitter();
  constructor(
    private modalController: ModalController,
    private holderService: HolderService,
    private loadingController: LoadingController,
    private commonService: CommonService,
    private toastController: ToastController,
    private applicationService: ApplicationService,
    private investmentService: InvestmentService,
    private topupService: TopUpService,
    private route: ActivatedRoute,
    private userService: UserService,
    private readonly router: Router,
  ) { }

  ngOnInit() {
    this.createForm();
    console.log('Hii Inside add applicant');
    // this.getApplication();
    this.getDropDownValues()
    var newdata= this.topupHolder;
    this.holderapplication = this.topupHolder;
    console.log('Hii',this.holderapplication);
    console.log('Hiitest',this.holder);

    if(this.holder != undefined && this.holder != null && this.holder != ""){
      this.isTopup=false;
    }
    else if(this.holderapplication != undefined && this.holderapplication != null && this.holderapplication != "" ){
      this.isTopup=true;
    }
    else{
      this.isTopup=false;
    }
  }

  get form() {
    return this.holderForm.controls;
  }

  createForm() {
    this.holderForm = new FormGroup({
      pan: new FormControl('', [Validators.required]),
      fundName: new FormControl('', [Validators.required]),
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }


  getDropDownValues() {
    var userInfo = this.userService.cookieDataFromOtherDomain()
    //this.parentFund = userInfo.userData.objUserFundsList
  }

  async submit() {
    this.holderForm.markAllAsTouched();
    if (this.holderForm.invalid) return;
    // if (this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
    //   this.form.pan.setErrors({ inProper: true });
    //   return;
    // }
    let payload = {} as AddHolder;
    payload.applicationUuid = this.applicationUuid;
    this.topupService.addtopup(this.form.pan.value, this.form.fundName.value.fundID).subscribe(
      async (res: any) => {
        console.log(res)
        this.showLoader = false;
        this.showValidation = false
        this.showDraftValidation = false
        this.fundDropdownDisable = true
        this.isOnSubmit = false
        this.isOnSuccess = true
        this.application = res.data;
        this.applicationUuid = this.application.investmentId
      },
      async (err) => {
        console.log("topup response badrequest    ", err)
        this.showLoader = false;
        if (err.error.message === "Submitted to RTA" || err.error.message === "Ready for Esign") {
          this.showValidation = true
          this.showDraftValidation = false
          return;
        }
        else if (err.error.message === "Draft") {
          this.showValidation = false
          this.showDraftValidation = true
          return;
        }
        else {
          this.showValidation = false
          this.showDraftValidation = false
          const toast = await this.toastController.create({
            message: err.error.message,
            duration: 2000,
          });
          toast.present();
        }
      }
    );
  }

  onContinue() {
    this.dismissModal();

    // if (this.isBeneficiary === true) {
    //   this.router.navigateByUrl('/application/beneficiary/' + this.holder.investorId + '/' + this.holder.investmentId);
    //   return;
    // }

    if(this.isTopup ===true){
      var isCKYCCheck = false;
      this.topupService.saveTopup(this.holderapplication.pan,this.holderapplication.childFundId,isCKYCCheck).subscribe(
        async (res: any) => {
          this.showLoader = false;
          this.dismissModal()
          let application = JSON.parse(res.data)
  
          console.log("after Continue  ", application)
          if (application.status === "Payment Initiated" || application.status === "Payment Success" || application.status === "Done") {
  
            this.commonService.encrypt(application.investmentID).subscribe((res: any) => {
  
              var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');
  
              this.router.navigateByUrl(
                `/application/personal-details/` + investorId
              );

            });
  
  
          }
          else {
  
            this.commonService.encrypt(application.investmentID).subscribe((res: any) => {
  
              var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');
  
              this.router.navigateByUrl(
                `/application/personal-details/` + investorId
              );

            });
  
  
          }
  
        },
        async (err) => {
          console.log("topup response badrequest    ", err)
          this.showLoader = false;
          this.showValidation = false
          this.showDraftValidation = false
          const toast = await this.toastController.create({
            message: err.error.message,
            duration: 2000,
          });
          toast.present();
  
        }
      );
    }
    else{

    this.commonService.encrypt(this.holder.investorId).subscribe((res: any) => {

      var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');


      this.commonService.encrypt(this.holder.investmentId).subscribe((res: any) => {

        var investmentId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');

        this.router.navigateByUrl(
          'application/applicant-kyc-details/' + investorId + `/` + investmentId
        );

        // this.router.navigateByUrl(
        //   'application/applicant-kyc-details/' + this.holder.investorId + `/${this.holder.investmentId}`
        // );
        return;
      });

    });

  }

  }


  onCancel() {
    if(this.isTopup ===true){
      location.reload();
    }
    else{

    
    if (this.isUBO === true)
      this.isBeneficiary = true

    if (this.isContactPerson === true)
      this.isBeneficiary = true

    if (this.isPOA === true)
      this.isBeneficiary = true

    this.investmentService.deleteInvestment(this.holder.investmentId, this.holder.investorId, this.onPanCheck, this.isBeneficiary, this.BeneficiaryTypeNo).subscribe(
      (res: any) => {
        location.reload()
      },
      (err) => {
        console.log(err)
        // location.reload()
      }
    );
  }
  }

  changePOA(event) {
    if (event.checked) {
      this.form.POA.setValue(true);
    }
    else {
      this.form.POA.setValue(false);
    }
  }


  getApplication() {
    this.applicationService.getApplicationByUuid(this.applicationUuid).subscribe(
      (res: any) => {
        this.application = JSON.parse(res.data);
        console.log('Type Of Investor 111', this.application);
        console.log('Type Of Investor ', this.application.typeOfInvestor);
      },
      (err) => {
        //this.commonService.updateLoader(false);
      }
    );
  }

}
