import {
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
  } from '@angular/core';
  import { ModalController, ToastController } from '@ionic/angular';
  import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
  } from '@angular/forms';
  import { DomSanitizer } from '@angular/platform-browser';
  import { BankService } from 'src/app/services/bank/bank.service';
  import { UploadDocument } from 'src/app/core/model/application.model';
  import { CommonService } from 'src/app/services/common/common.service';
  import { ApplicationService } from 'src/app/services/application/application.service';
  import { InvestorPayments } from 'src/app/core/model/payment.model';
  import { Errors, validationType } from '../../core/model/error.model';
  import { ageApprovalbody } from 'src/app/core/model/application.model';
  @Component({
    selector: 'app-age-approval',
  templateUrl: './age-approval.component.html',
  styleUrls: ['./age-approval.component.scss'],
  })
  export class AgeApprovalComponent implements OnInit {
    paymentStatus: boolean = false;
    @ViewChildren('fileInput') fileInputs: QueryList<
      ElementRef<HTMLInputElement>
    >;
    @Input() applicationDetails: any;
    fileAttr: string = 'Choose file';
    error = Errors;
    paymentForm = this.fb.group({
      payments: this.fb.array([]),
    });
    holderForm: FormGroup;
    showLoader = false;
    headerText = '';
    messageText = '';
    isError: boolean=false;
    constructor(
      private modalController: ModalController,
      private fb: FormBuilder,
      private bankService: BankService,
      private sanitizer: DomSanitizer,
      private commonService: CommonService,
      private applicationService: ApplicationService,
      private readonly toastController: ToastController
    ) {}
  
    ngOnInit() {

 //console.log(test);
 this.createForm();
    }
  
    get form() {
      return this.holderForm.controls;
    }
  
    createForm() {
      this.holderForm = new FormGroup({
        comments: new FormControl('', [Validators.required]),
      });
    }
  
    submit(status:string) {
      this.isError=false;
      var errormessage="";

      var test=this.applicationDetails;
     var comments=this.holderForm.controls.comments.value ;
     this.form.comments.setValidators(validationType.Required);
     this.form.comments.updateValueAndValidity();
     console.log(comments);
     if(status.toUpperCase() == "REJECT" && (comments == "" || comments == null || comments == undefined)){
      this.isError=true;
      return;
     }
      
     if(status.toUpperCase() == "REJECT"){
      errormessage = "Age Approval rejected";
     }
     else{
      errormessage = "Age Approval done";
     }

     this.showLoader = true;
     this.commonService
     .encrypt(this.applicationDetails.investmentId)
     .subscribe((res: any) => {
      var encryptedText = res.encryptedText
      .replaceAll('/', 'slh')
      .replaceAll('--', '==')
      .replaceAll('-', '=');

      var test=encryptedText;

      var req = {} as ageApprovalbody;

              req.comments = comments;
              req.investmentId = encryptedText;
              req.status = status;

      this.applicationService
      .setAgeApproval(req)
      .subscribe(
        async (res: any) => {
          this.showLoader = true;
          const toast = await this.toastController.create({
            message: errormessage,
            duration: 5000,
          });

          toast.present();
          this.dismissModal();

        },
        async (err) => {
          this.commonService.updateLoader(false);
          const toast = await this.toastController.create({
            message: err.error.message,
            duration: 2000,
          });
          toast.present();

          // this.router.navigateByUrl('/dashboard/0');
        }
      );
  });

    }

    async presentToast(message: string) {
      const toast = await this.toastController.create({
        message: message,
        duration: 2000,
      });
      toast.present();
    }
  
    dismissModal() {
      this.modalController.dismiss();
    }
  }
  