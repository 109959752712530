import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonService } from './services/common/common.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  showLoader: boolean = false;
  showHeader: boolean = true;
  showSubheader: boolean = false;
  currentUrl: string;

  constructor(
    private readonly commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    this.commonService.loader$.subscribe({
      next: (data) => {
        setTimeout(() => {
          this.showLoader = data;
        });
      },
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        if (
          this.currentUrl === '/account' ||
          this.currentUrl === '/' ||
          this.currentUrl === 'home'||
          this.currentUrl === '/accessdenied'||
          this.currentUrl === '/approvalaffiliate'
        ) {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }
      }
    });
  }
}
