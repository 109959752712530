// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

 // APIURL: 'https://localhost:44328',
  APIURL: 'https://apis.edelweissalternatives.com/swiftapi',
  //APIURL: 'https://uatapis.edelweissalternatives.com/swiftapi',
  DOC_URL: 'https://localhost:44349/uploads',
  MASTERAPIURL:'https://apis.edelweissalternatives.com/api',
  MASTERCORE:'https://swift.edelweissalternatives.com/prod',
  LINUX:'https://swift.edelweissalternatives.com/prod',
  DOC_DOWNLOAD:'https://apis.edelweissalternatives.com/drawdown',

 // MASTERAPIURL:'https://uatapis.edelweissalternatives.com/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
