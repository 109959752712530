import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  userConfirm: boolean = false;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}
  confirm() {
    this.userConfirm = true;
    this.modalController.dismiss(this.userConfirm, 'confirm');
  }
  notConfirm() {
    this.dismissModal();
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
