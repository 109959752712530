import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { FooterPage } from './footer/footer.page';
import { PanCheckComponent } from './pan-check/pan-check.component';
import { AddNewGuardianComponent } from './add-new-guardian/add-new-guardian.component';
import { DescriptionComponent } from './description/description.component';
import { OtpPopupComponent } from './otp-popup/otp-popup.component';
import { NumberToWordsPipe } from './pipes/number-to-words.pipe';
import { CounterDirective } from '../core/constants/counter.directive';
import { OopsModalComponent } from './oops-modal/oops-modal.component';
import { LoaderComponent } from './loader/loader.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddControllingPersonComponent } from './add-controlling-person/add-controlling-person.component';
import { AddContactPersonComponent } from './add-contact-person/add-contact-person.component';
import { AddBeneficiaryComponent } from './add-beneficiary/add-beneficiary.component';
import { AddsignatoriesComponent } from './addsignatories/addsignatories.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddCoparcenerComponent } from './add-coparcener/add-coparcener.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { NfesubcategoryComponent } from './nfesubcategory/nfesubcategory.component';
import { ActivesubcategoryComponent } from './activesubcategory/activesubcategory.component';
import { AddUboComponent } from './add-ubo/add-ubo.component';
import { AddPOAComponent } from './add-poa/add-poa.component';
import { AddTopUpComponent } from './add-topup/add-topup.component';
import { AddAMLCommentsComponent } from './add-AMLComments/add-AMLComments.component';
import { AddDownloadOfflineComponent } from './add-download-offline/add-download-offline.component';
import { ClassApprovalComponent } from './class-approval/class-approval.component';
import { AgeApprovalComponent } from './age-approval/age-approval.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UploadPhysicalSignComponent } from './upload-physical-sign/upload-physical-sign.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { amlStatusModalComponent } from './amlStatus-modal/amlStatus-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MoveRecheckComponent } from './move-recheck/move-recheck.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    IonicModule,
    HttpClientModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    

  ],
  declarations: [
    RightSidebarComponent,
    FooterPage,
    PanCheckComponent,
    DescriptionComponent,
    OtpPopupComponent,
    NumberToWordsPipe,
    CounterDirective,
    OopsModalComponent,
    LoaderComponent,
    SuccessModalComponent,
    AddControllingPersonComponent,
    AddContactPersonComponent,
    AddBeneficiaryComponent,
    AddsignatoriesComponent,
    AddCoparcenerComponent,
    SubcategoryComponent,
    NfesubcategoryComponent,
    ActivesubcategoryComponent,
    AddUboComponent,
    AddPOAComponent,
    AddTopUpComponent,
    AddDownloadOfflineComponent,
    AgeApprovalComponent,
    UploadPhysicalSignComponent,
    ConfirmModalComponent,
    PaymentModalComponent,
    MoveRecheckComponent,
    DeleteModalComponent, 
    AddAMLCommentsComponent,
    amlStatusModalComponent,
    AddNewGuardianComponent,
    ClassApprovalComponent,
  ],
  exports: [
    MaterialModule,
    RightSidebarComponent,
    FooterPage,
    PanCheckComponent,
    DescriptionComponent,
    OtpPopupComponent,
    NumberToWordsPipe,
    CounterDirective,
    OopsModalComponent,
    LoaderComponent,
    SuccessModalComponent,
    AddControllingPersonComponent,
    AddContactPersonComponent,
    AddBeneficiaryComponent,
    AddsignatoriesComponent,
    AddCoparcenerComponent,
    SubcategoryComponent,
    NfesubcategoryComponent,
    ActivesubcategoryComponent,
    AddUboComponent,
    AddPOAComponent,
    AddTopUpComponent,
    AddDownloadOfflineComponent,
    AgeApprovalComponent,
    UploadPhysicalSignComponent,
    ConfirmModalComponent,
    PaymentModalComponent,
    MoveRecheckComponent,
    DeleteModalComponent,
    AddAMLCommentsComponent,
    amlStatusModalComponent,
    AddNewGuardianComponent,
    ClassApprovalComponent,
  ],
})
export class SharedModule {}
