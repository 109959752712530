import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BankService } from 'src/app/services/bank/bank.service';
import { UploadDocument } from 'src/app/core/model/application.model';
import { CommonService } from 'src/app/services/common/common.service';
import { ApplicationService } from 'src/app/services/application/application.service';
import { InvestorPayments } from 'src/app/core/model/payment.model';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
  paymentStatus: boolean = false;
  @ViewChildren('fileInput') fileInputs: QueryList<
    ElementRef<HTMLInputElement>
  >;
  @Input() applicationDetails: any;
  fileAttr: string = 'Choose file';
  submitted = false;
  paymentModes: any;
  paymentTypes: any;
  accountTypes: any;
  bankDetails: any;
  partialPaymentDetails: any;
  selectedFileBLOB: any;
  fileformatError: boolean = false;
  maxSizError: boolean = false;
  fileEmptyErr: boolean = false;
  roleId: any;
  maxDate = new Date();
  selectedFiles: File[][] = [];
  urlArray: any[][] = [];
  choosedFile: any[];
  PaymentDetails: any;
  fileUploaded: boolean = false;
  paymentFile: File;
  paymentForm = this.fb.group({
    payments: this.fb.array([]),
  });
  showLoader = false;
  headerText = '';
  messageText = '';
  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private bankService: BankService,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private applicationService: ApplicationService,
    private readonly toastController: ToastController
  ) {}

  ngOnInit() {
    this.addPaymentForm();
    this.getPaymentModes();
    this.getPaymentTypes();
    this.getAccountTypes();
    this.getPaymentDetails();
  }

  isNumberKey(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    const inputValue = event.target.value + event.key;

    // Allow digits, decimal point, and backspace
    if (
      (charCode >= 48 && charCode <= 57) ||
      charCode === 46 ||
      charCode === 8
    ) {
      // Prevent multiple decimal points
      if (inputValue.split('.').length > 2) {
        event.preventDefault();
      } else if (
        inputValue.includes('.') &&
        inputValue.split('.')[1].length > 3
      ) {
        // Allow up to 3 digits after the decimal point
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  }

  getPaymentModes() {
    this.bankService.getPaymentMode().subscribe((res: any) => {
      this.paymentModes = res.data;
    });
  }

  getPaymentTypes() {
    this.bankService.getPaymentType().subscribe((res: any) => {
      this.paymentTypes = res.data;
      // console.log('payment types', this.paymentTypes);
    });
  }
  getAccountTypes() {
    this.bankService.getAccountType().subscribe((res: any) => {
      this.accountTypes = res.data;
      // console.log('account types', this.accountTypes);
    });
  }

  getBankDetails() {
    const body = {} as any;
    body.InvestmentId = this.applicationDetails.investmentId;
    this.bankService.getBankDetails(body).subscribe((res: any) => {
      this.bankDetails = res.data;
      // console.log('bank details', this.bankDetails);

      // Populate the bank name in all forms within the FormArray
      this.payments.controls.forEach((paymentForm) => {
        paymentForm.get('bankname').patchValue(this.bankDetails[0]?.bankName);
        paymentForm
          .get('accountnumber')
          .patchValue(this.bankDetails[0]?.accountNumber);
        paymentForm.get('ifsc_code').patchValue(this.bankDetails[0]?.ifscCode);
        paymentForm
          .get('accounttype')
          .patchValue(this.bankDetails[0]?.accountType);
      });
    });
  }

  getPaymentDetails() {
    let body = {
      InvestmentId: this.applicationDetails.investmentId,
    };
    this.bankService.getPartialPaymentDetails(body).subscribe((res: any) => {
      this.partialPaymentDetails = res.data;
     
    });
  }

  get payments() {
    return this.paymentForm.controls['payments'] as any;
  }
  addPaymentForm() {
    const paymentForm = this.fb.group({
      Amount: new FormControl('', [Validators.required]),
      tranref_chequeNo: new FormControl('', [Validators.required]),
      paymentmode: new FormControl('', [Validators.required]),
      dateofpayment: new FormControl('', [Validators.required]),
      paymenttype: new FormControl('', [Validators.required]),
      bankname: new FormControl('', [Validators.required]),
      accountnumber: new FormControl('', [Validators.required]),
      ifsc_code: new FormControl('', [Validators.required]),
      accounttype: new FormControl('', [Validators.required]),
      isexistingbankdetails: new FormControl(true),
      uploadFile: [null],
    });
    this.getBankDetails();

    this.payments.push(paymentForm);
  }

  deletePaymentForm(paymentIndex: number) {
    this.payments.removeAt(paymentIndex);
  }

  // payment receipt select and upload

  selectFile(event: Event, formIndex: number) {
    const fileInput = this.fileInputs.toArray()[formIndex].nativeElement;
    const files: File[] = [];
    const sanitizedUrls: any[] = [];

    if (fileInput.files) {
      this.fileEmptyErr = false;
      this.fileformatError = false;
      this.maxSizError = false;

      for (let j = 0; j < fileInput.files.length; j++) {
        const selectedFile = fileInput.files[j];
        const idxDot = selectedFile.name.lastIndexOf('.') + 1;

        // file extension
        const extFile = selectedFile.name
          .substr(idxDot, selectedFile.name.length)
          .toLowerCase();

        if (
          extFile === 'jpg' ||
          extFile === 'jpeg' ||
          extFile === 'png' ||
          extFile === 'pdf'
        ) {
          this.fileformatError = false;
        } else {
          this.fileformatError = true;
          return;
        }

        if (selectedFile.size > 20000000) {
          this.maxSizError = true;
          return;
        } else {
          this.maxSizError = false;
        }

        // file url
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const blob = new Blob([selectedFile], { type: selectedFile.type });
          const url = window.URL.createObjectURL(blob);
          this.selectedFileBLOB = this.sanitizer.bypassSecurityTrustUrl(url);
          sanitizedUrls.push(this.selectedFileBLOB);
        };
        reader.readAsDataURL(selectedFile);
        files.push(selectedFile);

        this.uploadDocument(formIndex, selectedFile);
      }
    } else {
      return;
    }

    this.selectedFiles[formIndex] = files;
    this.urlArray[formIndex] = sanitizedUrls;
  }

  async uploadDocument(formIndex: number, selectedFile: File) {
    this.fileUploaded = false;
    this.showLoader = true;
    const body = {} as UploadDocument;

    body.applicationUuid = this.applicationDetails.investmentId;
    body.holderUuid = this.applicationDetails.investorId;
    body.documentType = 'Payment_Upload';
    const date = new Date();
    body.filename = 'Payment_Upload_' + date.toISOString();

    try {
      const response = await this.applicationService.uploadFile(
        selectedFile,
        body
      );

      if (response) {
        this.showLoader = false;
        const toast = await this.toastController.create({
          message: 'Payment Receipt Uploaded',
          duration: 10000,
        });
        toast.present();
      } else {
        this.showLoader = false;
        const toast = await this.toastController.create({
          message: 'Something went wrong',
          duration: 2000,
        });
        toast.present();
      }
    } catch (error) {
      console.error('Error:', error);
      this.showLoader = false;

      const toast = await this.toastController.create({
        message: 'Something went wrong',
        duration: 2000,
      });
      toast.present();
    }
  }

  submit() {
    if (this.paymentStatus) {
      this.submitted = true;
      this.showLoader = true;

      if (this.paymentForm.valid && this.urlArray.length > 0) {
        this.fileEmptyErr = false;
        let body = {} as InvestorPayments;

        body.userid = Number(localStorage.getItem('userId'));
        body.InvestmentId = this.applicationDetails.investmentId;
        const allPaymentDetails = this.paymentForm.get('payments').value;
        const paymentDetailsArray = [];

        for (let i = 0; i < allPaymentDetails.length; i++) {
          const paymentDetail = allPaymentDetails[i];
          const paymenttype = paymentDetail.paymenttype;
          const Amount = paymentDetail.Amount.toString();
          const tranref_chequeNo = paymentDetail.tranref_chequeNo;
          const paymentmode = paymentDetail.paymentmode;

          const year = paymentDetail.dateofpayment.getFullYear();
          const month = String(
            paymentDetail.dateofpayment.getMonth() + 1
          ).padStart(2, '0');
          const day = String(paymentDetail.dateofpayment.getDate()).padStart(
            2,
            '0'
          );
          const dateofpayment = `${year}-${month}-${day}`;
          const isexistingbankdetails = paymentDetail.isexistingbankdetails
            ? 1
            : 0;
          const bankname = paymentDetail.bankname;
          const accountnumber = paymentDetail.accountnumber;
          const ifsc_code = paymentDetail.ifsc_code;
          var accounttypeNum;
          if (paymentDetail.accounttype == 'Savings') {
            accounttypeNum = 1;
          } else if (paymentDetail.accounttype == 'Current') {
            accounttypeNum = 2;
          } else {
            accounttypeNum = paymentDetail.accounttype;
          }
          const date = new Date();
          // const receiptpath = 'Payment_Upload_' + i+1 + '_' + date.toISOString();
          const receiptpath = this.selectedFiles[i][0].name;
          // Create an object to hold the values of each payment detail
          const paymentObject = {
            paymenttype: paymenttype,
            Amount: Amount,
            tranref_chequeNo: tranref_chequeNo,
            paymentmode: paymentmode,
            dateofpayment: dateofpayment,
            isexistingbankdetails: isexistingbankdetails,
            bankname: bankname,
            accountnumber: accountnumber,
            ifsc_code: ifsc_code,
            accounttype: accounttypeNum,
            receiptpath: receiptpath,
          };

          // Push the paymentObject into the paymentDetailsArray
          paymentDetailsArray.push(paymentObject);
         
        }
        body.tranlist = paymentDetailsArray;
        const response = this.bankService.savePaymentDetails(body).subscribe(
          (res) => {
          
            if (res) {
              this.showLoader = false;
              this.presentToast('Application sent to R&T for verification.');

              this.dismissModal();
            } else {
              // Show the error toast
              this.showLoader = false;
              this.presentToast('Something went wrong');
              this.dismissModal();
            }
          },
          (error: any) => {
            this.showLoader = false;
            console.error('Error:', error);
            // Show the error toast
            this.presentToast('Something went wrong');
            this.dismissModal();
          }
        );
      } else {
        this.showLoader = false;
     
      }
    } else {
      let body = {} as InvestorPayments;
      body.userid = Number(localStorage.getItem('userId'));
      body.tranlist = null;
      body.InvestmentId = this.applicationDetails.investmentId;
      this.bankService.savePaymentDetails(body).subscribe((res) => {
   
        this.dismissModal();
      });
    }
  }
  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  deleteFile(formIndex: number, fileToDelete: File) {
    const fileInput = this.fileInputs.toArray()[formIndex].nativeElement;
    const fileControl = this.paymentForm
      .get('payments')
      .get(formIndex.toString())
      .get('uploadFile');

    const fileIndex = this.selectedFiles[formIndex].indexOf(fileToDelete);

    if (fileIndex !== -1) {
      this.selectedFiles[formIndex].splice(fileIndex, 1);
      this.urlArray[formIndex].splice(fileIndex, 1); // Remove only the URL, not the whole array

      // Reset the file input value to allow selecting the same file again
      fileInput.value = '';

      // Reset the form control value
      fileControl.setValue(null);
    }
  }
  dismissModal() {
    this.modalController.dismiss();
  }
}
