import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.page.html',
  styleUrls: ['./footer.page.scss'],
})
export class FooterPage implements OnInit {
  status: boolean = false;

  constructor() {}

  ngOnInit() {}

  clickEvent() {
    this.status = !this.status;
  }
}
