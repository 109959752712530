import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, validationType } from '../../core/model/error.model';
import { AddHolder } from '../../core/model/holder.model';
import { CommonService } from '../../services/common/common.service';
import { HolderService } from '../../services/holder/holder.service';
import { TopUpService } from '../../services/topup/topup.service';
import { ApplicationService } from '../../services/application/application.service';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { formatDate } from '@angular/common';
import { UserService } from '../../services/user/user.service';
import { parse } from 'path';
import { DropdownService } from '../../services/dropdown/dropdown.service';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component'

@Component({
  selector: 'app-add-topup',
  templateUrl: './add-topup.component.html',
  styleUrls: ['./add-topup.component.scss'],
})
export class AddTopUpComponent implements OnInit {
  holderForm: FormGroup;
  error = Errors;
  showLoader = false;
  headerText = ''; //HEADER_TEXT_PANCHECK;
  messageText = ''; //MESSAGE_TEXT_PANCHECK;
  todayDate = new Date();
  application: any;
  applicantUuid: string;
  parentFund: any;
  showValidation = false;
  showDraftValidation = false;
  isOnSubmit = true;
  isOnSuccess = false;
  fundDropdownDisable = false;
  showAffiliateValidation = false;
  @Input() applicationUuid: string;
  @Output() add: EventEmitter<any> = new EventEmitter();
  constructor(
    private modalController: ModalController,
    private holderService: HolderService,
    private loadingController: LoadingController,
    private commonService: CommonService,
    private toastController: ToastController,
    private applicationService: ApplicationService,
    private topupService: TopUpService,
    private route: ActivatedRoute,
    private userService: UserService,
    private readonly router: Router,
    private readonly dropdownService: DropdownService,

  ) { }

  ngOnInit() {
    this.createForm();
    console.log('Hii Inside add applicant');
    // this.getApplication();
    this.getDropDownValues()
    //console.log('Hii',this.applicationUuid);
  }

  get form() {
    return this.holderForm.controls;
  }

  createForm() {
    this.holderForm = new FormGroup({
      pan: new FormControl('', [Validators.required]),
      fundName: new FormControl('', [Validators.required]),
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }


  getDropDownValues() {
    // var userInfo = this.userService.cookieDataFromOtherDomain()
    // this.parentFund = userInfo.userData.objUserFundsList

    // this.dropdownService.getParentFund().subscribe(
    //   (res: any) => {
    //     var addNewFund = {} as any;
    //     addNewFund.fundID="FUND252";
    //     addNewFund.fundName="INFRASTRUCTURE YIELD PLUS II A";
    //     addNewFund.fundShortName=null;
    //    res.push(addNewFund);
    //   this.parentFund = res;
    //   },
    //   (err) => {
    //     console.log(err)
    //   }
    // );

    this.dropdownService
    .getChildFund()
   // .getChildFund(this.form.fundName.value.fundID)
    .subscribe(
      (res: any) => {
        this.parentFund = res;
        this.parentFund=this.parentFund.filter(x=> x.fundId=="FUND252"  || x.fundId == "FUND255" || x.fundId == "FUND241" || x.fundId == "FUND261" || x.fundId == "FUND360" );
        // || x.fundId =="FUND255" || x.fundId == "FUND239" || x.fundId  =="FUND343"
        console.log(this.parentFund);
      },
      (err) => { }
    );
  }

  async submit() {
    debugger
    this.holderForm.markAllAsTouched();
    if (this.holderForm.invalid) return;
    // if (this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
    //   this.form.pan.setErrors({ inProper: true });
    //   return;
    // }
    let payload = {} as AddHolder;
    payload.applicationUuid = this.applicationUuid;
    console.log('this.form.pan.value', this.form.pan.value);
    console.log('this.form.fundName.value.fundID', this.form.fundName.value.fundId);
    this.topupService.addtopup(this.form.pan.value, this.form.fundName.value.fundId).subscribe(
      async (res: any) => {
        console.log(res)
        this.showLoader = false;
        this.showValidation = false;
        this.showDraftValidation = false;
        this.fundDropdownDisable = true;
        this.isOnSubmit = false;
        this.isOnSuccess = true;

        console.log('Res count', res.status);
        if (res.data.length > 1) {
          console.log('res.data', res.data[1]);
          this.application = res.data[res.data.length - 1];
          var commitmentAmount = 0;
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].tp_CommitmentAmount == 0) {
              commitmentAmount += parseInt(res.data[i].commitmentAmount);
            }
            else {
              commitmentAmount += parseInt(res.data[i].tp_CommitmentAmount);
            }
          }
          console.log('commitmentAmount',commitmentAmount);
          this.application.commitmentAmount = commitmentAmount;
          this.application.class = res.data[res.data.length -1].tp_Class;
          this.application.commitmentAmount = this.commonService.commaSeparateNumber(this.application.commitmentAmount);
          this.application.isAffiliate = res.data[0].groupId =='' || res.data[0].groupId == null?"No":"Yes"
          this.applicationUuid = this.application.investmentId
          if(this.application.isAffiliate == "Yes")
            {
              this.application.class = this.application.revisedClass;
            }
        }
        else {
          if(res.data[0].tp_CommitmentAmount != 0 && res.data[0].tp_CommitmentAmount != null)
          {
            console.log('Inside if tp');
            this.application = res.data[0];
            console.log('Response', this.application);
            this.application.class = res.data[0].tp_Class;
            this.application.commitmentAmount = parseInt(this.application.tp_CommitmentAmount) + parseInt(this.application.commitmentAmount);
            this.application.commitmentAmount = this.commonService.commaSeparateNumber(this.application.commitmentAmount);
            this.application.isAffiliate = res.data[0].groupId =='' || res.data[0].groupId == null?"No":"Yes";
            this.applicationUuid = this.application.investmentId;
            if(this.application.isAffiliate == "Yes")
            {
              this.application.class = this.application.revisedClass;
            }
          }
          else{
            console.log('Inside else');
            this.application = res.data[0];
            console.log('Response', this.application);
            this.application.isAffiliate = res.data[0].groupId =='' || res.data[0].groupId == null?"No":"Yes";
            this.application.commitmentAmount = this.commonService.commaSeparateNumber(this.application.commitmentAmount);
            this.applicationUuid = this.application.investmentId;
            if(this.application.isAffiliate == "Yes")
            {
              this.application.class = this.application.revisedClass;
            }
            console.log('this.application.isAffiliate',this.application.isAffiliate);
          }
          
        }


      },
      async (err) => {
        console.log("topup response badrequest    ", err)
        this.showLoader = false;
        if (err.error.message === "Submitted to RTA" || err.error.message === "Ready for Esign" || err.error.message === "Esign Pending" || err.error.message === "Recheck") {
          this.showValidation = true
          this.showDraftValidation = false
          return;
        }  
        if (err.error.message === "Approval Pending") {
          this.showValidation = false;
          this.showDraftValidation = false;
          this.showAffiliateValidation = true;
          return;
        }  
        
        else if (err.error.message === "Draft") {
          this.showValidation = false
          this.showDraftValidation = true
          return;
        }
        else {
          this.showValidation = false
          this.showDraftValidation = false
          const toast = await this.toastController.create({
            message: err.error.message,
            duration: 2000,
          });
          toast.present();
        }
      }
    );
  }

  onContinue() {
    debugger
    this.showLoader = true;

    var data1= this.application;
    var pan="";
    var dob="";
    var isCkyc = true;

 
  if(!this.application.isCkyc_Expired || this.application.isCkyc_Expired== null || this.application.isCkyc_Expired == undefined){
   // return;
    this.saveTopup(this.application.pan,this.application.childFundId,isCkyc,false);
  }
else{
  this.headerText = 'Check CKYC';
  this.messageText = 'Please wait, Verifying CKYC and AML';
    if(this.application.typeOfInvestor=="Individual"){
      pan = this.application.pan;
      dob = this.application.dateOfBirth;
    }else{
      pan = this.application.pan;
      dob = this.application.dateOfIncorporation;
    }
    var reqBody;
    reqBody = {
      PAN: pan,
      DOB: dob,
    }

    // return;
    this.applicationService.getCKYC(reqBody).subscribe(
      async (res: any) => {
        if (res.error == 1) {
          isCkyc=false;
          isCkyc=false;
          this.dismissModal();
              const modal = await this.modalController.create({
                component: nonkycModelComponent,
                backdropDismiss: false,
                cssClass: 'my-custom-class',
                componentProps: {
                  topupHolder: this.application,
                  onPanCheck: true
                }
              });
              await modal.present();
              modal.onDidDismiss().then((event) => { });
              return;
          
        }
        else {
          if (res.value.status == "SUCCESS") {
            isCkyc=true;
            this.saveTopup(this.application.pan,this.application.childFundId,isCkyc,true);
          }
          else{
            isCkyc=false;
            this.dismissModal();
                const modal = await this.modalController.create({
                  component: nonkycModelComponent,
                  backdropDismiss: false,
                  cssClass: 'my-custom-class',
                  componentProps: {
                    topupHolder: this.application,
                    onPanCheck: true
                  }
                });
                await modal.present();
                modal.onDidDismiss().then((event) => { });
                return;
          }
        }
      })
    }
    //  return;
    // this.topupService.saveTopup(this.application.pan,this.application.childFundId,isCkyc).subscribe(
    //   async (res: any) => {
    //     this.showLoader = false;
    //     this.dismissModal()
    //     let application = JSON.parse(res.data)

    //     console.log("after Continue  ", application)
    //     if (application.status === "Payment Initiated" || application.status === "Payment Success" || application.status === "Done") {

    //       this.commonService.encrypt(application.investmentID).subscribe((res: any) => {

    //         var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');

    //         // this.router.navigateByUrl(
    //         //   `/application/investment-details/` + investorId
    //         // );

    //         this.router.navigateByUrl(
    //           `/application/bank-details/` + investorId
    //         );


    //         // this.router.navigateByUrl(
    //         //   `/application/investment-details/${application.investmentID}`
    //         // );

    //       });


    //     }
    //     else {

    //       this.commonService.encrypt(application.investmentID).subscribe((res: any) => {

    //         var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');

    //         // this.router.navigateByUrl(
    //         //   `/application/investment-details/` + investorId
    //         // );

    //         this.router.navigateByUrl(
    //           `/application/bank-details/` + investorId
    //         );

    //         // this.router.navigateByUrl(
    //         //   `/application/investment-details/${application.investmentID}`
    //         // );

    //       });


    //     }

    //   },
    //   async (err) => {
    //     console.log("topup response badrequest    ", err)
    //     this.showLoader = false;
    //     this.showValidation = false
    //     this.showDraftValidation = false
    //     const toast = await this.toastController.create({
    //       message: err.error.message,
    //       duration: 2000,
    //     });
    //     toast.present();

    //   }
    // );
  }


  saveTopup(pan:string,dob:string,isCkyc: boolean,ckyc_expired:boolean){
    this.topupService.saveTopup(this.application.pan,this.application.childFundId,isCkyc).subscribe(
      async (res: any) => {
        this.showLoader = false;
        this.dismissModal()
        let application = JSON.parse(res.data)

        console.log("after Continue  ", application)
        if (application.status === "Payment Initiated" || application.status === "Payment Success" || application.status === "Done") {

          this.commonService.encrypt(application.investmentID).subscribe((res: any) => {

            var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');

            // this.router.navigateByUrl(
            //   `/application/investment-details/` + investorId
            // );

            if(!application.isCkyc_Expired || application.isCkyc_Expired== null || application.isCkyc_Expired == undefined){
              this.router.navigateByUrl('application/bank-details/' + investorId);
           
             }
             else{
              this.router.navigateByUrl('application/personal-details/' + investorId);
         
             }
            // this.router.navigateByUrl(
            //   `/application/bank-details/` + investorId
            // );
          });


        }
        else {

          this.commonService.encrypt(application.investmentID).subscribe((res: any) => {

            var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');

            // this.router.navigateByUrl(
            //   `/application/investment-details/` + investorId
            // );

            if(!application.isCkyc_Expired || application.isCkyc_Expired== null || application.isCkyc_Expired == undefined){
              this.router.navigateByUrl('application/bank-details/' + investorId);
           
             }
             else{
              this.router.navigateByUrl('application/personal-details/' + investorId);
         
             }
        // //    this.router.navigateByUrl(
        //       `/application/bank-details/` + investorId
        //     );

            // this.router.navigateByUrl(
            //   `/application/investment-details/${application.investmentID}`
            // );

          });


        }

      },
      async (err) => {
        console.log("topup response badrequest    ", err)
        this.showLoader = false;
        this.showValidation = false
        this.showDraftValidation = false
        const toast = await this.toastController.create({
          message: err.error.message,
          duration: 2000,
        });
        toast.present();

      }
    );

  }
  onCancel() {
    this.showValidation = false
    this.showDraftValidation = false
    this.isOnSubmit = true
    this.isOnSuccess = false
    this.fundDropdownDisable = false
  }

  changePOA(event) {
    if (event.checked) {
      this.form.POA.setValue(true);
    }
    else {
      this.form.POA.setValue(false);
    }
  }


  getApplication() {
    this.applicationService.getApplicationByUuid(this.applicationUuid).subscribe(
      (res: any) => {
        this.application = JSON.parse(res.data);
        console.log('Type Of Investor 111', this.application);
        console.log('Type Of Investor ', this.application.typeOfInvestor);
      },
      (err) => {
        //this.commonService.updateLoader(false);
      }
    );
  }

}
