import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Errors, validationType } from '../../core/model/error.model';
import { SignatoryService } from 'src/app/services/signatory.service';
@Component({
  selector: 'app-addsignatories',
  templateUrl: './addsignatories.component.html',
  styleUrls: ['./addsignatories.component.scss'],
})
export class AddsignatoriesComponent implements OnInit {
  floatLabelControl = new FormControl('always');
  addContactPerson: FormGroup;
  error = Errors;
  isSignatoryYesOrNoValue = 'yes';
  @Input() application: any;
  @Input() signatory: any;
  @Input() beneficiaryKind: any;
  isHUF: boolean = false;
  constructor(
    private modalController: ModalController,
    private signatoryService: SignatoryService
  ) {
    this.createForm();
  }
  createForm() {
    this.addContactPerson = new FormGroup({
      sameAsBeneficiary: new FormControl('yes'),
      selectBeneficiary: new FormControl('', validationType.Required),
      signatoryName: new FormControl(''),
      signatoryEmailId: new FormControl('', validationType.emailId),
      signatoryTwo: new FormControl('yes'),
      signatoryThree: new FormControl('yes'),
      designation: new FormControl(''),
      sameEmailAddressMessage: new FormControl(''),
    });
  }

  ngOnInit() {

    // if (this.beneficiaryKind === "Hindu Undivided Family" || this.beneficiaryKind === "Karta") {
    //   this.isHUF = true
    // }

    console.log('in signer', this.application.beneficiaryList)

    let filterdList = this.application.beneficiaryList.filter(x => x.typeOfInvestor === "Individual")
    for (let i = 0; i < this.application.signerList.length; i++) {
      for (let b = 0; b < filterdList.length; b++) {
        console.log(this.application.signerList[i].investorId, filterdList[b].investorId)
        if (+this.application.signerList[i].investorId === +filterdList[b].investorId) {
          filterdList.splice(b, 1)
        }
        if (filterdList.length === 0) {
          this.form.sameAsBeneficiary.setValue('no');
          this.form.sameAsBeneficiary.updateValueAndValidity();
          this.form.selectBeneficiary.setValidators(null);
          this.form.selectBeneficiary.updateValueAndValidity();
        }
      }
    }

    this.application.beneficiaryList = filterdList

    // this.application.signatory.filter((val) => {
    //   for (const iterator of this.application.beneficiaries) {
    //     if (+val.selectBeneficiary === +iterator.beneficiaryIndex) {
    //       let index = this.application.beneficiaries.findIndex(i => +i.beneficiaryIndex === +val.selectBeneficiary);
    //       console.log(index)
    //       this.application.beneficiaries.splice(index, 1)
    //       if (this.application.beneficiaries.length === 0) {
    //         this.form.sameAsBeneficiary.setValue('no');
    //         this.form.sameAsBeneficiary.updateValueAndValidity();
    //       }
    //     }
    //   }
    // });

    this.beneficiaryLister();

  }
  get form() {
    return this.addContactPerson.controls;
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  beneficiaryLister() {
    this.form.sameAsBeneficiary.valueChanges.subscribe((data) => {
      if (data === 'yes') {
        this.form.selectBeneficiary.setValidators(validationType.Required);
        this.form.signatoryName.setValidators(null);
        this.form.signatoryName.updateValueAndValidity();
        this.form.signatoryEmailId.setValidators(null);
        this.form.signatoryEmailId.updateValueAndValidity();
        this.form.designation.setValidators(null);
        this.form.designation.updateValueAndValidity();
      } else {
        console.log('clear -----');
        this.form.selectBeneficiary.setValidators(null);
        this.form.selectBeneficiary.updateValueAndValidity();
        this.form.signatoryName.setValidators(validationType.Required);
        this.form.signatoryEmailId.setValidators(validationType.email);
        this.form.designation.setValidators(validationType.Required);
        this.form.designation.updateValueAndValidity();
      }
    });
  }

  isSignatory(event) {
    this.isSignatoryYesOrNoValue = event.value;
    if (event.value === 'yes') {
      this.form.selectBeneficiary.setValidators(validationType.Required);
      this.form.signatoryName.setValidators(null);
      this.form.signatoryName.updateValueAndValidity();
      this.form.signatoryEmailId.setValidators(null);
      this.form.signatoryEmailId.updateValueAndValidity();
      this.form.designation.setValidators(null);
      this.form.designation.updateValueAndValidity();
    } else {
      console.log('clear -----');
      this.form.selectBeneficiary.setValidators(null);
      this.form.selectBeneficiary.updateValueAndValidity();
      this.form.signatoryName.setValidators(validationType.Required);
      this.form.signatoryEmailId.setValidators(validationType.email);
      this.form.designation.setValidators(validationType.Required);
      this.form.designation.updateValueAndValidity();

      this.form.sameEmailAddressMessage.setValue('');
    }
  }

  submit() {
    console.log(this.form.sameAsBeneficiary.value);
    if (this.form.sameAsBeneficiary.value === 'no') {
      console.log('Inside No');
      var emailAddress = this.form.signatoryEmailId.value;
      if (this.application.signerList.length >= 1) {
        let same = false;
        for (var j = 0; j <= this.application.signerList.length - 1; j++) {
          if (this.application.signerList[j].email == emailAddress) {
            same = true;
            console.log('Email address same');
          }
        }
        if (same === true) {

          this.form.sameEmailAddressMessage.setValue('Signatory email id is same as pervious added Signatory, kindly click on \'No\' and proceed with different email id');
          return;
        }
      }
    }
    else {
      if (this.application.signerList.length >= 1) {
        for (var j = 0; j <= this.application.signerList.length - 1; j++) {
          var existEmail = this.application.beneficiaryList.filter((val) => val.investorId === parseInt(this.form.selectBeneficiary.value));
          if (existEmail.length > 0) {
            if (this.application.signerList[j].email == existEmail[0].email) {
              console.log('Email address same');
              this.form.sameEmailAddressMessage.setValue('Signatory email id is same as pervious added Signatory, kindly click on \'No\' and proceed with different email id');
              console.log(this.form.sameEmailAddressMessage.value);
              return;
            }
          }
          else {
            console.log('Email address not same');
          }
        }
      }
    }

    console.log('this.application', this.application);

    if (this.application.poaDetail.length > 0) {
      if (this.application.poaDetail.length == 1) {
        if (emailAddress == this.application.poaDetail[0].email) {
          this.form.sameEmailAddressMessage.setValue('POA and Signatory Email are same.');
          return;
        }

        if (emailAddress == this.application.poaDetail[0].signatoryEmail1) {
          this.form.sameEmailAddressMessage.setValue('POA and Signatory Email are same.');
          return;
        }

        if (emailAddress == this.application.poaDetail[0].signatoryEmail2) {
          this.form.sameEmailAddressMessage.setValue('POA and Signatory Email are same.');
          return;
        }
      }
      
    }


    if (this.form.sameAsBeneficiary.value === 'yes') {
      this.form.selectBeneficiary.setValidators(validationType.Required);
      this.form.signatoryName.setValidators(null);
      this.form.signatoryName.updateValueAndValidity();
      this.form.signatoryEmailId.setValidators(null);
      this.form.signatoryEmailId.updateValueAndValidity();
      this.form.designation.setValidators(null);
      this.form.designation.updateValueAndValidity();
    } else {
      this.form.selectBeneficiary.setValidators(null);
      this.form.selectBeneficiary.updateValueAndValidity();
      this.form.signatoryName.setValidators(validationType.Required);
      this.form.signatoryName.updateValueAndValidity();
      this.form.signatoryEmailId.setValidators(validationType.email);
      this.form.signatoryEmailId.updateValueAndValidity();
      this.form.designation.setValidators(validationType.Required);
      this.form.designation.updateValueAndValidity();

      this.form.sameEmailAddressMessage.setValue('');
    }


    let body = {} as any;
    console.log(this.addContactPerson);
    if (this.addContactPerson.invalid) return;

    body.sameAsBeneficiary = this.form.sameAsBeneficiary.value;
    body.selectBeneficiary = this.form.selectBeneficiary.value;
    body.name = this.form.signatoryName.value;
    body.email = this.form.signatoryEmailId.value;
    body.designation = this.form.designation.value;
    body.applicationUuid = this.application.investmentID;
    this.signatoryService.addSignatory(body).subscribe(
      (res) => {
        this.dismissModal();
      },
      (err) => { }
    );
   }
}
