import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InvestorGuard } from './core/guards/investor.guard';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./featured/account/account.module').then(
        (m) => m.AccountPageModule
      ),
  },
  {
    path: 'accessdenied',
    loadChildren: () =>
      import('./featured/accessdenied/access-denied.module').then(
        (m) => m.AccessDeniedPageModule
      ),
  },
  {
    path: 'approval-affiliate/:email/:groupid',
    loadChildren: () =>
      import('./featured/approval-affiliate1/approval-affiliate1.module').then(
        (m) => m.ApprovalaffiliatePageModule
      ),
  },
  {
    path: 'approval-AML/:email/:investmentId/:investorId',
    loadChildren: () =>
      import('./featured/approval-AML/approval-AML.module').then(
        (m) => m.ApprovalAMLPageModule
      ),
  },
  // {
  //   path: 'home',
  //   loadChildren: () =>
  //     import('./home/home.module').then((m) => m.HomePageModule),
  // },

  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full',
  },
  {
    path: 'application',
    loadChildren: () =>
      import('./featured/application/application.module').then(
        (m) => m.ApplicationPageModule
      ),
    canActivate: [InvestorGuard],
  },
  // {
  //   path: 'dashboard/:enc',
  //   loadChildren: () =>
  //     import('./featured/dashboard/dashboard.module').then(
  //       (m) => m.DashboardPageModule
  //     ),
  //   canActivate: [InvestorGuard],
  // },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./featured/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [InvestorGuard],
  },
  {
    path: 'affiliate-dashboard',
    loadChildren: () =>
      import('./featured/affiliate-dashboard/affiliate-dashboard.module').then(
        (m) => m.AffiliateDashboardModule
      ),
    canActivate: [InvestorGuard],
  },
 
  {
    path: 'documents',
    loadChildren: () =>
      import('./featured/download/download.module').then(
        (m) => m.DownloadPageModule
      ),
    canActivate: [InvestorGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
