import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BankService } from 'src/app/services/bank/bank.service';
import { UploadDocument,classApprovalbody } from 'src/app/core/model/application.model';
import { CommonService } from 'src/app/services/common/common.service';
import { ApplicationService } from 'src/app/services/application/application.service';
import { InvestorPayments } from 'src/app/core/model/payment.model';
import { Errors, validationType } from '../../core/model/error.model';

@Component({
  selector: 'app-class-approval',
templateUrl: './class-approval.component.html',
styleUrls: ['./class-approval.component.scss'],
})
export class ClassApprovalComponent implements OnInit {
  paymentStatus: boolean = false;
  @ViewChildren('fileInput') fileInputs: QueryList<
    ElementRef<HTMLInputElement>
  >;
  @Input() applicationDetails: any;
  fileAttr: string = 'Choose file';
  error = Errors;
  paymentForm = this.fb.group({
    payments: this.fb.array([]),
  });
  holderForm: FormGroup;
  showLoader = false;
  headerText = '';
  messageText = '';
  investorname ="";
  commitmentamount ="";
  class ="";
  formid ="";
  isError: boolean=false;
  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private bankService: BankService,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private applicationService: ApplicationService,
    private readonly toastController: ToastController
  ) {}

  ngOnInit() {
    this.binddata();

//console.log(test);
this.createForm();
  }

  get form() {
    return this.holderForm.controls;
  }

  createForm() {
    this.holderForm = new FormGroup({
      comments: new FormControl('', [Validators.required]),
    });
  }

  binddata(){
    this.formid =this.applicationDetails.formId;
    if(this.applicationDetails.typeOfInvestor == "Individual"){
      this.investorname = this.applicationDetails.firstName + " " + (this.applicationDetails.middleName == null ? " ": this.applicationDetails.middleName )+ " "+ (this.applicationDetails.middleName == null ? " ": this.applicationDetails.lastName );
  
    }
    else{
      this.investorname = this.applicationDetails.entityName;
    }
if(this.applicationDetails.isTopUp == 1){
  this.class = this.applicationDetails.tp_Class;
 // this.commitmentamount = this.applicationDetails.tp_CommitmentAmount;
  this.commitmentamount = this.commonService.commaSeparateNumber(this.applicationDetails.tp_CommitmentAmount);
}
else{
  this.class = this.applicationDetails.class;
 // this.commitmentamount = this.applicationDetails.commitmentAmount;
  this.commitmentamount = this.commonService.commaSeparateNumber(this.applicationDetails.commitmentAmount);
}

  }
  submit(status:string) {
    this.isError=false;
    var test=this.applicationDetails;
   
   var comments=this.holderForm.controls.comments.value ;
   this.form.comments.setValidators(validationType.Required);
   this.form.comments.updateValueAndValidity();
   console.log(comments);
   if(status.toLowerCase() == "reject" && (comments == "" || comments == null || comments == undefined)){
    this.isError=true;
    return;
   }
 //  this.commonService.updateLoader(true);
   this.showLoader = true;
   this.commonService
   .encrypt(this.applicationDetails.investmentId)
   .subscribe((res: any) => {
    var encryptedText = res.encryptedText
    .replaceAll('/', 'slh')
    .replaceAll('--', '==')
    .replaceAll('-', '=');

    var test=encryptedText;

    var req = {} as classApprovalbody;

              req.comment = comments;
              req.aaplicationuidd = encryptedText;
              req.status=status;

      this.applicationService
      .setClassApproval(req)
      .subscribe(
        async (res: any) => {
          this.showLoader = true;

          var message="";
          if(status == "Approved"){
            message = "Class Approval done";
          }
          else{
            message = "Application is rejected due to incorrect class.";
          }

          const toast = await this.toastController.create({
            message: message,
            duration: 5000,
          });

          toast.present();
          this.dismissModal();

        },
        async (err) => {
          this.commonService.updateLoader(false);
          const toast = await this.toastController.create({
            message: err.error.message,
            duration: 2000,
          });
          toast.present();
          this.dismissModal();
          // this.router.navigateByUrl('/dashboard/0');
        }
      );

});

  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
