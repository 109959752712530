import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { OtpTriggerTypes } from '../../core/constants/enum';
import { User } from '../../core/model/user.model';
import {
  GeneratedOtpResponse,
  GenerateOTP,
  Login,
  VerfiyOTP,
} from '../../core/model/auth.model';
import { Errors, validationType } from '../../core/model/error.model';
import { CommonService } from '../../services/common/common.service';
import { OtpService } from '../../services/otp/otp.service';
import { ACCESS_TOKEN } from '../../core/constants/app-strings';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'otp-popup',
  templateUrl: './otp-popup.component.html',
  styleUrls: ['./otp-popup.component.scss'],
})
export class OtpPopupComponent implements OnInit, OnChanges {
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Input() otpData: GeneratedOtpResponse;
  @Input() holderData: any;
  @Input() holder: any;
  user: User;
  otpForm: FormGroup;
  error = Errors;
  remainingSeconds = 0;
  counter = 60;
  interval = 1000;
  resentOTP: boolean = false;
  floatLabelControl = new FormControl('always');
  showLoader = false;
  headerText = ''; //HEADER_TEXT_PANCHECK;
  messageText = ''; //MESSAGE_TEXT_PANCHECK;

  constructor(
    private readonly otpService: OtpService,
    private commonService: CommonService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private toastController: ToastController
  ) { }

  get form() {
    return this.otpForm.controls;
  }

  ngOnInit() {
    this.createForm();
    this.getUserDetails();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.holderData) {
      this.maskInputValues();
    }
  }
  onTimerChange(event) {
    this.remainingSeconds = event;
  }

  resendOTP() {
    this.otpForm.reset();
    this.otpForm.controls['otp'].setValue('');
    this.otpForm.controls['otp'].setErrors(null);
    this.otpForm.controls['otp'].markAsUntouched();
    this.otpForm.controls['otp'].updateValueAndValidity();
    this.cdr.detectChanges();

    let application_rmName = this.holderData.rmName;
    let rmDetails = JSON.parse(this.user.distributorDetails).filter(function (item) {
      return item.RmName == application_rmName;
    })

    const payload = {} as GenerateOTP;
    payload.holderUuid = this.holderData.investorId; //this.holder.uuid;
    //payload.phone =objInvestor.mobile // this.holder.mobile;
    //payload.email = this.holder.email;
    payload.email = rmDetails[0].RmEmail;
    payload.rmName = rmDetails[0].RmName;
    payload.action = OtpTriggerTypes.ADD_NEW_APPLICATION_OTP;
    payload.user = this.user;

    // const payload = this.mapLoginPayload();
    this.otpService.generateOTP(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.resentOTP = true;
          setTimeout(() => {
            this.resentOTP = false;
          }, 60000);
        }
      },
      error: (err) => {
        if (err.status === 401) {
          this.otpForm.controls.otp.setErrors({ blocked: true });
          this.otpForm.controls.otp.updateValueAndValidity();
          this.cdr.detectChanges();
        }
      },
    });
  }

  mapLoginPayload() {
    let application_rmName = this.holderData.application.rmName;

    let rmDetails = JSON.parse(this.user.distributorDetails).filter(function (item) {
      return item.RmName == application_rmName;
    });

    const body = {} as GenerateOTP;

    //body.email = this.holderData.email;
    body.email = rmDetails[0].RmEmail;
    body.phone = this.holderData.mobile;
    body.holderUuid = this.holderData.uuid;
    body.rmName = application_rmName;

    body.action = OtpTriggerTypes.RESEND;

    return body;
  }

  getUserDetails() {
    this.commonService.user$.subscribe((res: User) => {
      this.user = res;
    });
    this.userService.getUser().subscribe((res: User) => {
      this.user = res;
    });
  }

  isNumberKey(event) {
    if (this.commonService.isNumberKey(event)) {
      return true;
    } else {
      return false;
    }
  }

  maskInputValues() {

    let application_rmName = this.holderData.rmName;

    let rmDetails = JSON.parse(this.user.distributorDetails).filter(function (item) {
      return item.RmName == application_rmName;
    });

    if (this.holderData) {
      this.holderData.maskedEmail = this.commonService.maskInput(
        rmDetails[0].RmEmail,
        'email'
      );
      // this.holderData.maskedEmail = this.commonService.maskInput(
      //   this.holderData.email,
      //   'email'
      // );
      this.holderData.maskedPhone = this.commonService.maskInput(
        this.holderData.mobile,
        'mobile'
      );
    }
  }

  createForm() {
    this.otpForm = new FormGroup({
      otp: new FormControl('', validationType.OTP),
    });
  }

  otpLogin() {
    let application_rmName = this.holderData.rmName;
    // let application_rmName = this.user.distributorEntityName;
    
    let rmDetails = JSON.parse(this.user.distributorDetails).filter(function (item) {
      return item.RmName == application_rmName;
    });

    this.otpForm.markAllAsTouched();
    if (this.otpForm.invalid) return;
    // this.next.emit();
    let payload = {} as VerfiyOTP;
    //payload.email = this.holderData.email;
    payload.userUuid = this.user.uuid;
    payload.email = rmDetails[0].RmEmail;
    payload.mobileNumber = this.holderData.mobile;
    payload.holderUuid = this.holderData.investorId;
    payload.otp = this.form.otp.value;
    payload.action = OtpTriggerTypes.ADD_NEW_APPLICATION_OTP;
    console.log('Before verifyOTPCKYC');

    this.otpService.verifyOTPCKYC(payload).subscribe({
      next: (res: any) => {
        console.log('INside verifyOTPCKYC');
        if (res.token) {
          this.userService.setStroage(ACCESS_TOKEN, res.token);
          this.next.emit();
        }
      },
      error: async (err) => {
        this.commonService.updateLoader(false);
        const toast =await this.toastController.create({
          message: err.error,
          duration: 2000,
        });
        toast.present();
      },
    });

  
  }
}
