import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-move-recheck',
  templateUrl: './move-recheck.component.html',
  styleUrls: ['./move-recheck.component.scss'],
})
export class MoveRecheckComponent implements OnInit {
  @Input() applicationDetails: any;
  recheckReason: string = '';
  submitted: boolean = false;
  reasonEmptyErr: boolean = false;
  showLoader = false;
  headerText = '';
  messageText = '';
  constructor(
    private modalController: ModalController,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {}
  confirm() {
    this.submitted = true;
    if (this.recheckReason == '' || this.recheckReason == null) {
      this.reasonEmptyErr = true;
      console.log('no recheck reason');
      return;
    }
    this.showLoader = true;
    let body = {} as any;
    body.UserId = Number(localStorage.getItem('userId'));
    body.InvestmentId = this.applicationDetails.investmentId;
    body.Remark = this.recheckReason;
    this.dashboardService.recheckApplication(body).subscribe((res) => {
      this.showLoader = false;
      this.dismissModal();
      console.log('recheck response', body);
    });
  }
  cancel() {
    this.dismissModal();
  }
  dismissModal() {
    this.modalController.dismiss();
  }
}
