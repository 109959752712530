import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements OnInit {
  @Input() applicationDetails: any;
  deleteReason: string = '';
  reasonEmptyErr: boolean = false;
  submitted: boolean = false;
  showLoader = false;
  headerText = '';
  messageText = '';
  constructor(
    private modalController: ModalController,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {}
  confirm() {
    this.submitted = true;
    if (this.deleteReason == '' || this.deleteReason == null) {
      this.reasonEmptyErr = true;
      return;
    }
    this.reasonEmptyErr = false;
    this.showLoader = true;
    let body = {} as any;
    body.UserId = Number(localStorage.getItem('userId'));
    body.InvestmentId = this.applicationDetails.investmentId;
    body.Remark = this.deleteReason;
    this.dashboardService.deleteApplication(body).subscribe((res) => {
      this.showLoader = false;
      this.dismissModal();
      console.log('delete response', body);
    });
  }
  cancel() {
    this.dismissModal();
  }
  dismissModal() {
    this.modalController.dismiss();
  }
}
