import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  CREATE_APPLICATION,
  DOWNLOAD_NOnIndividual_PDF,
  DOWNLOAD_PDF,
  DOWNLOAD_PDF_DIGIO,
  DOWNLOAD_PDF_AFTER_ESIGN,
  DOWNLOAD_MULTIPLEFILES,
  DOWNLOAD_PHYSICAL_SIGN_MULTIPLEFILES,
  APPLICATION_RECHECK,
  DELETE_APPLICATION,
} from '../../core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';
import { Subject } from 'rxjs';
import { Application } from 'src/app/core/model/application.model';
import { share } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) { }

  private dataSource = new Subject<Application>();
  application = this.dataSource.asObservable().pipe(share());

  // getDashboard(body: ApplicationList) {
  //   const url = environment.APIURL + CREATE_APPLICATION;

  //   return this.http.get(url, { headers });
  // }
  // sortOrder, pageNumber = 0, pageSize = 10, query

  getApplicationList(sortOrder, pageNumber = 0, pageSize = 10, query, type, roleId, istopup) {
    if (!sortOrder || Object.keys(sortOrder).length === 0)
      sortOrder = { createdOn: 'DESC' };
    if (!query) query = "";

    const params = new HttpParams()
      .set('limit', pageSize.toString())
      .set('start', (pageNumber).toString())
      .set('offset', (pageNumber * pageSize).toString())
      .set('sort', JSON.stringify(sortOrder))
      .set('filters', query)
      .set('type', type)
      .set('roleId', roleId)
      .set('istopup', istopup);
    // const params = new HttpParams();
    const url = environment.APIURL + CREATE_APPLICATION;

    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);

    return this.http.get(url, { headers, params });
  }

  downloadPDF(body: any) {
    //const url = environment.APIURL + DOWNLOAD_PDF + `/${body.applicationUuid}`;
    const url = environment.APIURL + DOWNLOAD_PDF_DIGIO;

    let req = {} as any;
    req.formId = body.formId.toString();
    req.InvestmentId = body.InvestmentId.toString();
    req.GroupId = body.GroupId.toString();
    req.Type = body.Type.toString();
    
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url,req, { headers });
  }

  downloadNonIndividualPDF(body: any) {
    //const url = environment.APIURL + DOWNLOAD_NOnIndividual_PDF + `/${body.applicationUuid}`;
    const url = environment.APIURL + DOWNLOAD_PDF_DIGIO;

    let req = {} as any;
    req.formId = body.formId.toString();
    req.InvestmentId = body.InvestmentId.toString();
    req.GroupId = body.GroupId.toString();
    req.Type = body.Type.toString();

    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url,req, { headers });
  }

  downloadPDFAfterEsign(body: any) {
    const url = environment.APIURL + DOWNLOAD_PDF_AFTER_ESIGN + `/${body.applicationUuid}` + `/${body.type}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  downloadMultipleAttachment(body: any) {
    //const url = environment.APIURL + DOWNLOAD_PDF + `/${body.applicationUuid}`;
    const url = environment.APIURL + DOWNLOAD_MULTIPLEFILES;

    let req = {} as any;
    req.formId = body.formId.toString();
    req.InvestmentId = body.InvestmentId.toString();
    req.GroupId = body.GroupId.toString();
    req.Type = body.Type.toString();
    
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url,req, { headers });
  }

  downloadPhysicalMultipleFiles(body: any) {
 
    const url = environment.APIURL + DOWNLOAD_PHYSICAL_SIGN_MULTIPLEFILES;

    let req = {} as any;
 
    req.InvestmentId = body.InvestmentId;
     req.DocType = body.DocType;
     req.UserId = body.UserId;
    
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url,req, { headers });
  }

  // recheck application by client 
  recheckApplication(body: any){
   const url = environment.APIURL + APPLICATION_RECHECK;
   let req ={} as any;
   req.UserId = body.UserId;
   req.InvestmentId = body.InvestmentId;
   req.Remark = body.Remark;
    let headers = {};
   headers[AUTHORIZATION] = BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
   return this.http.post(url, req, { headers });

  }
  // delete application by client
  deleteApplication(body: any){
    const url = environment.APIURL + DELETE_APPLICATION;
    let req ={} as any;
    req.UserId = body.UserId;
    req.InvestmentId = body.InvestmentId;
    req.Remark = body.Remark;
     let headers = {};
    headers[AUTHORIZATION] = BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, req, { headers });
 
   }
}
