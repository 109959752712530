
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ADD_GUARDIAN,
  //DELETE_POA,
  DELETE_GUARDIAN,
} from '../../core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GuardianService {
  constructor(private readonly http: HttpClient) { }

  addGuardian(body) {
    const url = environment.APIURL + ADD_GUARDIAN;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

//   updatePOA(body) {
//     const url = environment.APIURL + UPDATE_POA;
//     let headers = {} as any;
//     headers[AUTHORIZATION] =
//       BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
//     return this.http.put(url, body, { headers });
//   }

  deleteGuardian(uuid: string, applicationuuid: string) {
    const url = environment.APIURL + DELETE_GUARDIAN + `/${uuid}` + `/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }
}
