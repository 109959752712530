import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Errors, validationType } from '../../core/model/error.model';
import { FatcaService } from 'src/app/services/fatca/fatca.service';
import { CommonService } from 'src/app/services/common/common.service';
import { formatDate } from '@angular/common';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component';
import { ErrorMessage } from 'src/app/core/constants/enum';
import { OfflinepopupComponent } from '../offline-popup/offline-popup.component';
@Component({
  selector: 'app-add-controlling-person',
  templateUrl: './add-controlling-person.component.html',
  styleUrls: ['./add-controlling-person.component.scss'],
})
export class AddControllingPersonComponent implements OnInit {
  floatLabelControl = new FormControl('always');
  addControllingPerson: FormGroup;
  error = Errors;
  selectBeneficiaryValue = 'yes';
  todayDate = new Date();
  ckycVerified: boolean = false;
  beneficiaryTaxResidentAnyOtherCountryYesNoValue = 'yes';
  showLoader: boolean = false;
  headerText = 'Check CKYC';
  messageText = 'Please wait, Verifying CKYC';
  countryList: any = [];
  countryBirth: any = [];
  occupationList: any = [];
  controllingType: any = [];
  controllingPerson: any;
  @Input() application;
  @Input() controllingPersonDetail;
  @Input() isNonKyc: boolean = false;
  existData: boolean = false;
  investorId: any;
  disableIscontrollingperson: boolean = false;
  addressType = [{ value: "Residence or Business" }, { value: "Residential" }, { value: "Business" }, { value: "Registered Office" }]
  constructor(
    private modalController: ModalController,
    private fatcaService: FatcaService,
    private alertController: AlertController,
    private commonService: CommonService,
    private toastController: ToastController,
  ) {
    this.createForm();
  }

  ngOnInit() {

    this.getMasterData();
    console.log()
    if (!this.controllingPersonDetail?.investorId)
      this.panListerner();
    console.log('controllingPersonDetail', this.controllingPersonDetail);
    console.log("Hindu Undivided Family", this.application?.beneficiaryType);
    //this.taxResidentListerner()

  }
  taxResidentListerner() {
    this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.valueChanges.subscribe(data => {
      if (data && data === 'no') {
        this.form.countryOfBirth.setValidators(validationType.Required);
        this.form.countryOfBirth.updateValueAndValidity();
        this.form.cityOfBirth.setValidators(validationType.Required);
        this.form.cityOfBirth.updateValueAndValidity();
        this.form.addressType.setValidators(validationType.Required);
        this.form.addressType.updateValueAndValidity();
      }
      else {
        this.form.countryOfBirth.setValidators(null);
        this.form.countryOfBirth.updateValueAndValidity();
        this.form.cityOfBirth.setValidators(null);
        this.form.cityOfBirth.updateValueAndValidity();
        this.form.addressType.setValidators(null);
        this.form.addressType.updateValueAndValidity();
      }
    })

  }

  ionViewWillEnter() {


    if (this.isNonKyc === true) {
      this.form.isControllingPersonSameAsBeneficiary.setValue('no');
      this.investorId = this.controllingPersonDetail?.investorId

    }
    else {

      this.getMasterData();

      if (this.controllingPersonDetail) {
        this.ckycVerified = true;
        console.log('this.controllingPersonDetail.occupation', this.controllingPersonDetail.occupation);
        this.prefillForm();
      }
      var filterdList = this.application.beneficiaryList.filter(x => x.typeOfInvestor === "Individual")

      if (this.application && !this.controllingPersonDetail) {
        for (let i = 0; i < this.application.controllingPersonList.length; i++) {
          for (let b = 0; b < filterdList.length; b++) {
            console.log(this.application.controllingPersonList[i].investorId, filterdList[b].investorId)
            if (+this.application.controllingPersonList[i].investorId === +filterdList[b].investorId) {
              filterdList.splice(b, 1)
            }
            if (filterdList.length === 0) {
              this.form.isControllingPersonSameAsBeneficiary.setValue('no');
              this.form.isControllingPersonSameAsBeneficiary.updateValueAndValidity();
            }
          }
        }
        this.application.beneficiaryList = filterdList
      }





      // for (const iterator of this.application.controllingPerson) {

      //   let index = this.application.beneficiaries.findIndex(val => {+val.beneficiaryIndex === +iterator.selectBeneficiary});
      //     console.log('index--',index)
      //     this.application.beneficiaries.splice(index, 1);
      //     console.log('this.application.beneficiaries', this.application.beneficiaries)
      //   // if (val.selectBeneficiary == iterator.beneficiaryIndex) {
      //   //   console.log('match doeunf----')

      //   //   if (this.application.beneficiaries.length === 0) {
      //   //     this.form.isControllingPersonSameAsBeneficiary.setValue('no');
      //   //     this.form.isControllingPersonSameAsBeneficiary.updateValueAndValidity();
      //   //   }
      //   // }
      // }

    }

    if (
      this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.value === 'yes' &&
      this.fatcaArray.length === 0
    ) {
      const form = new FormGroup({
        country: new FormControl('INDIA'),
        identificationType: new FormControl('', validationType.Required),
        referenceNumber: new FormControl('', validationType.invalidCharacter),
      });
      this.fatcaArray.push(form);
    }
  }

  get form() {
    //console.log(this.applicantForm.controls);
    return this.addControllingPerson.controls;
  }

  prefillForm() {
    this.form.pan.setValue(this.controllingPersonDetail.pan);
    this.form.dob.setValue(this.controllingPersonDetail.dateOfBirth);
    this.form.typeCode.setValue(this.controllingPersonDetail.personTypeCode);
    this.form.occupation.setValue(this.controllingPersonDetail.occupation);
    this.form.countryOfBirth.setValue(this.controllingPersonDetail.countryOfBirth);
    this.form.cityOfBirth.setValue(this.controllingPersonDetail.cityOfBirth);
    this.form.addressType.setValue(this.controllingPersonDetail.addressType);
    this.form.nationality.setValue(this.controllingPersonDetail.nationality);
    if (this.controllingPersonDetail?.objFatcaList?.length > 0)
      this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.setValue(this.controllingPersonDetail.taxResidentIndia)
    else
      this.form.isControllingPersonSameAsBeneficiary.setValue('no');

    if (this.controllingPersonDetail.isControllingPersonSameAsBeneficiary) {
      this.form.isControllingPersonSameAsBeneficiary.setValue(
        this.controllingPersonDetail.isControllingPersonSameAsBeneficiary
      );
    }

    for (const iterator of this.application?.beneficiaryList) {
      if (+iterator.investorId === +this.controllingPersonDetail.investorId) {
        this.form.selectBeneficiary.setValue(iterator.investorId.toString());
        this.form.selectBeneficiary.updateValueAndValidity();
      }
    }

    if (
      this.controllingPersonDetail.objFatcaList &&
      this.controllingPersonDetail.objFatcaList.length > 0
    ) {
      for (const fatca of this.controllingPersonDetail.objFatcaList) {
        const form = new FormGroup({
          uuid: new FormControl(fatca.uuid, []),
          country: new FormControl(fatca.country, validationType.Required),
          identificationType: new FormControl(
            fatca.identificationType,
            validationType.Required
          ),
          referenceNumber: new FormControl(
            fatca.referenceNumber,
            validationType.invalidCharacter
          ),
        });
        this.fatcaArray.push(form);
        // this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.setValue('no');
      }

      // this.guardianDetails.guardianPan =
    }
  }

  getMasterData() {
    this.commonService.getMasterData(1, 'country').subscribe((res: any) => {
      this.countryList = res.value;
      this.countryBirth = res.value;
    });

    this.commonService
      .getMasterData(1, 'controllingOccupation')
      .subscribe((res: any) => {
        this.occupationList = res.value;
      });

    this.commonService
      .getMasterData(1, 'controllingType')
      .subscribe((res: any) => {
        this.controllingType = res.value;
      });
  }
  createForm() {
    this.addControllingPerson = new FormGroup({
      isControllingPersonSameAsBeneficiary: new FormControl('yes'),
      selectBeneficiary: new FormControl(''),
      beneficiaryTaxResidentAnyOtherCountryIfYes: new FormControl('yes'),

      pan: new FormControl('', validationType.PAN),
      dob: new FormControl('', validationType.Required),
      typeCode: new FormControl('', validationType.Required),
      occupation: new FormControl('', validationType.Required),
      countryOfBirth: new FormControl(''),
      cityOfBirth: new FormControl(''),
      addressType: new FormControl(''),
      nationality: new FormControl(''),
      fatcaArray: new FormArray([]),
    });
  }

  get fatcaArray() {
    return this.addControllingPerson.controls.fatcaArray as FormArray;
  }

  async removeFatca(fatca, i: number) {
    const alert = await this.alertController.create({
      header: 'Confirm!',
      message: `Are you sure you want to delete FATCA details ?`,
      buttons: [
        {
          text: 'No',
          handler: () => { },
        },
        {
          text: 'Yes',
          handler: () => {
            if (fatca.value.uuid) {
              this.fatcaService.deleteFatca(fatca.value.uuid).subscribe({
                next: () => {
                  this.fatcaArray.removeAt(i);
                  if (this.fatcaArray.length === 0) {
                    this.form.payTaxOutsideIndia.setValue(false);
                  }
                },
              });
            }
            if (!fatca.value.uuid) {
              this.fatcaArray.removeAt(i);
            }
            if (this.fatcaArray.length === 0) {
              this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.setValue(
                'yes'
              );
            }
          },
        },
      ],
    });
    await alert.present();
  }

  addFatca() {
    const form = new FormGroup({
      country: new FormControl('INDIA', validationType.Required),
      identificationType: new FormControl('', validationType.Required),
      referenceNumber: new FormControl('', validationType.invalidCharacter),
    });
    this.fatcaArray.push(form);
  }

  isAlphaNumericKey(event) {
    if (this.commonService.isAlphaNumericKey(event)) {
      return true;
    } else {
      return false;
    }
  }

  isControllingPerson1IsSameAsBeneficiaryYesNo(event) {
    this.selectBeneficiaryValue = event.value;

  }

  beneficiaryTaxResidentAnyOtherCountryYesNo(event) {
    this.beneficiaryTaxResidentAnyOtherCountryYesNoValue = event.value;
    if (this.fatcaArray.length === 0) {
      const form = new FormGroup({
        country: new FormControl('INDIA'),
        identificationType: new FormControl('', validationType.Required),
        referenceNumber: new FormControl('', validationType.invalidCharacter),
      });

      this.fatcaArray.push(form);
    }
    // if (event.value === 'yes') {
    //   this.form.fatcaArray = new FormArray([]);
    // }

    if (event.value === 'no') {
      this.countryList = this.countryList.filter(
        (val) => val.countryName !== 'INDIA'
      );
    }
    //this.taxResidentListerner()
  }

  // selectBeneficiary(event) {
  //   for (const iterator of this.application.beneficiaryList) {
  //     console.log(iterator.beneficiaryIndex, this.form.selectBeneficiary.value)
  //     if(+iterator.beneficiaryIndex === +this.form.selectBeneficiary.value){
  //       if(!iterator.payTaxOutsideIndia){
  //         this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.setValue('no');
  //         this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.updateValueAndValidity();
  //       }
  //     }
  //   }
  //   //alert("hellow world");
  // }

  dismissModal() {
    this.modalController.dismiss();
  }

  panListerner() {
    this.form.pan.valueChanges.subscribe((data) => {
      if (data) {
        let pan = this.form.pan.value;
        for (const iterator of this.application.holdersList) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }

        for (const iterator of this.application.nomineeList) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }

        for (const iterator of this.application.beneficiaryList) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }
        for (const iterator of this.application.controllingPersonList) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }
      }
    });
  }

  async submit() {

    if (this.controllingPerson) {
      this.controllingPersonDetail = this.controllingPerson
    }


    if (this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.value === "yes") {

      this.form.countryOfBirth.setValidators(null);
      this.form.countryOfBirth.updateValueAndValidity();
      this.form.cityOfBirth.setValidators(null);
      this.form.cityOfBirth.updateValueAndValidity();
      this.form.addressType.setValidators(null);
      this.form.addressType.updateValueAndValidity();

      this.form.typeCode.setValidators(validationType.Required);
      this.form.typeCode.updateValueAndValidity();

      this.form.occupation.setValidators(validationType.Required);
      this.form.occupation.updateValueAndValidity();

    }
    else {
      this.form.typeCode.setValidators(validationType.Required);
      this.form.typeCode.updateValueAndValidity();

      this.form.occupation.setValidators(validationType.Required);
      this.form.occupation.updateValueAndValidity();

      this.form.countryOfBirth.setValidators(validationType.Required);
      this.form.countryOfBirth.updateValueAndValidity();
      this.form.cityOfBirth.setValidators(validationType.Required);
      this.form.cityOfBirth.updateValueAndValidity();
      this.form.addressType.setValidators(validationType.Required);
      this.form.addressType.updateValueAndValidity();
    }


    // if (
    //   this.form.pan.value &&
    //   this.form.pan.value.charAt(3).toUpperCase() !== 'P'
    // ) {
    //   this.form.pan.setErrors({ inProper: true });
    //   return;
    // }
    if (this.controllingPersonDetail?.ckycCheck === false) {
      this.form.pan.setValidators(null);
      this.form.pan.updateValueAndValidity();
    }

    if (!this.controllingPerson && !this.controllingPersonDetail) {

      this.fatcaArray.removeAt(0);

      this.form.typeCode.setValidators(null);
      this.form.typeCode.updateValueAndValidity();

      this.form.occupation.setValidators(null);
      this.form.occupation.updateValueAndValidity();
    }

    if (this.form.isControllingPersonSameAsBeneficiary.value === "yes" && !this.controllingPerson && !this.controllingPersonDetail) {
      this.form.pan.setValidators(null);
      this.form.pan.updateValueAndValidity();
      this.form.dob.setValidators(null);
      this.form.dob.updateValueAndValidity();

      this.form.selectBeneficiary.setValidators(validationType.Required);
      this.form.selectBeneficiary.updateValueAndValidity();

      this.form.typeCode.setValidators(validationType.Required);
      this.form.typeCode.updateValueAndValidity();

      this.form.occupation.setValidators(validationType.Required);
      this.form.occupation.updateValueAndValidity();
    }

    if (this.form.isControllingPersonSameAsBeneficiary.value === "no" && !this.controllingPerson && !this.controllingPersonDetail) {

      this.form.pan.setValidators(validationType.Required);
      this.form.pan.updateValueAndValidity();

      this.form.dob.setValidators(validationType.Required);
      this.form.dob.updateValueAndValidity();

      this.form.selectBeneficiary.setValidators(null);
      this.form.selectBeneficiary.updateValueAndValidity();

      this.form.typeCode.setValidators(null);
      this.form.typeCode.updateValueAndValidity();

      this.form.occupation.setValidators(null);
      this.form.occupation.updateValueAndValidity();
    }

    if (this.form.isControllingPersonSameAsBeneficiary.value === "yes") {
      this.fatcaArray.removeAt(0);
    }

    // if (this.form.pan.value && this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
    //   this.form.pan.setErrors({ inProper: true });
    // }
    this.addControllingPerson.markAllAsTouched();
    if (this.addControllingPerson.invalid) return;


    if (this.form.pan.value.charAt(3).toUpperCase() !== 'P' && this.form.isControllingPersonSameAsBeneficiary.value !== "yes") {
      this.dismissModal();
      const modal = await this.modalController.create({
        component: OfflinepopupComponent,
        backdropDismiss: false,
        cssClass: 'my-custom-class',
        componentProps: {
          errorMessage: ErrorMessage.IndividualError,
          isIndividual: true
        }
      });
      await modal.present();
      await modal.onDidDismiss().then((event) => { });
      return;
    }



    let payload = {} as any;
    // if (this.form.isControllingPersonSameAsBeneficiary.value === 'yes') {
    payload.sameAsBeneficiary = this.form.isControllingPersonSameAsBeneficiary.value;
    payload.selectBeneficiary = this.form.selectBeneficiary.value;

    payload.pan = this.form.pan.value;//this.application.pan;// this.form.pan.value;
    payload.dob = this.form.dob.value;//this.application.dob; //this.form.dob.value;

    if (!this.controllingPersonDetail?.investorId) {

      if (payload.dob !== "") {
        let newDate = new Date(payload.dob);
        newDate.setDate(newDate.getDate())
        let formatedDate = formatDate(newDate, 'yyyy-MM-dd', 'en');
        payload.dob = formatedDate;
      }
    }

    payload.personTypeCode = this.form.typeCode.value;
    payload.occupation = this.form.occupation.value;
    if (this.form.isControllingPersonSameAsBeneficiary.value === "no") {
      payload.occupation = this.form.occupation.value;
      payload.countryOfBirth = this.form.countryOfBirth.value;
      payload.cityOfBirth = this.form.cityOfBirth.value;
      payload.addressType = this.form.addressType.value;
      payload.nationality = this.form.nationality.value;
      payload.taxResidentIndia = this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.value;
    }


    payload.applicationUuid = this.application.investmentID;
    this.controllingPerson = this.controllingPersonDetail
    if ((this.controllingPerson && this.controllingPerson.investorId) || this.controllingPersonDetail) {
      payload.uuid = this.controllingPerson ? this.controllingPerson.investorId : this.controllingPersonDetail.investorId;
      payload.fatcaArray = this.form.fatcaArray.value;
      this.showLoader = true;
      this.fatcaService.updateControllingPerson(payload).subscribe(
        (res: any) => {
          this.showLoader = false;
          let controllingPersonData = JSON.parse(res.data);
          console.log('payload.sameAsBeneficiary',payload.sameAsBeneficiary);
          console.log('this.form.isControllingPersonSameAsBeneficiary.value',this.form.isControllingPersonSameAsBeneficiary.value);
          if(this.form.isControllingPersonSameAsBeneficiary.value == 'yes')
          {
            controllingPersonData.ckycCheck = 1;
          }
          
          if (controllingPersonData.ckycCheck === 0) {
            console.log('Inside If');

            this.commonService.encrypt(this.application.investmentID).subscribe((res: any) => {
              var investmentID = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');
              window.location.href = '/application/fatca-details/' + investmentID
              return;
              
            });

            
          }
          else {
            //console.log('Inside Else');
            this.modalController.dismiss(controllingPersonData);
            //location.reload();
          }
        },
        async (err) => {
          const toast = await this.toastController.create({
            message: err.error.message,
            duration: 2000,
          });
          toast.present();
        }
      );
      return;
    }
    this.showLoader = true;
    this.fatcaService.addControllingPerson(payload).subscribe(
      async (res: any) => {
        this.controllingPerson = JSON.parse(res.data);

        if (this.controllingPerson.ckycCheck === 0 && this.form.isControllingPersonSameAsBeneficiary.value === "no") {
          this.dismissModal();
          const modal = await this.modalController.create({
            component: nonkycModelComponent,
            backdropDismiss: false,
            cssClass: 'my-custom-class',
            componentProps: {
              holder: this.controllingPerson,
              onPanCheck: false,
              isUBO: true,
              BeneficiaryTypeNo: 8
            }
          });
          await modal.present();
          modal.onDidDismiss().then((event) => { });
          return;
        }


        this.showLoader = false;
        this.ckycVerified = true;
        this.disableIscontrollingperson = true;
        this.form.beneficiaryTaxResidentAnyOtherCountryIfYes.setValue('yes')
        this.addFatca()
        if (this.form.isControllingPersonSameAsBeneficiary.value === 'yes') {
          this.modalController.dismiss(this.controllingPerson);
        }
        // location.reload()
      },
      async (err) => {
        this.showLoader = false;
        const toast = await this.toastController.create({
          message: err.error.message,
          duration: 2000,
        });
        toast.present();
      }
    );
  }

  validateTaxCountryRequired(index: number) {
    return (
      this.fatcaArray.controls[index] as FormGroup
    ).controls.country.hasError('required');
  }

  validateReferenceNumberRequired(index: number) {
    return (
      this.fatcaArray.controls[index] as FormGroup
    ).controls.referenceNumber.hasError('required');
  }

  validateidentificationTypeRequired(index: number) {
    return (
      this.fatcaArray.controls[index] as FormGroup
    ).controls.identificationType.hasError('required');
  }
}
