import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Errors, validationType } from 'src/app/core/model/error.model';
import { CommonService } from 'src/app/services/common/common.service';
import { FatcaService } from 'src/app/services/fatca/fatca.service';
@Component({
  selector: 'app-activesubcategory',
  templateUrl: './activesubcategory.component.html',
  styleUrls: ['./activesubcategory.component.scss'],
})
export class ActivesubcategoryComponent implements OnInit {
  subsidiary: FormGroup;
  error = Errors;
  todayDate = new Date();
  showLoader: boolean = false;
  setValueInSubject;
  giinSubcategory: any = [];

  constructor(
    private modalController: ModalController,
    private fatcaService: FatcaService,
    private readonly commonService: CommonService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getMasterData();
  }

  getMasterData() {
    this.commonService
      .getMasterData(1, 'activeNFESubcategory')
      .subscribe((res: any) => {
        this.giinSubcategory = res.value;
      });
  }

  createForm() {
    this.subsidiary = new FormGroup({
      selectValue: new FormControl(''),
    });
  }
  get form() {
    return this.subsidiary.controls;
  }

  dismissModal() {
    this.modalController.dismiss();
  }
  submit() {
    this.fatcaService.activesubCategory.next(this.setValueInSubject);
    this.modalController.dismiss();
  }

  setValueInSubcategory(e) {
    this.setValueInSubject = e.value;

    // this.fatcaService.subCategory.next(e.value);
  }
}
