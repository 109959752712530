import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Errors, validationType } from '../../core/model/error.model';
import { ContactPersonService } from 'src/app/services/contact-person.service';
import { ApplicationService } from 'src/app/services/application/application.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Application } from 'src/app/core/model/application.model';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { formatDate } from '@angular/common';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component';
import { JsonpClientBackend } from '@angular/common/http';
import { OfflinepopupComponent } from '../offline-popup/offline-popup.component';
import { ErrorMessage } from 'src/app/core/constants/enum';
@Component({
  selector: 'app-add-contact-person',
  templateUrl: './add-contact-person.component.html',
  styleUrls: ['./add-contact-person.component.scss'],
})
export class AddContactPersonComponent implements OnInit {
  @Input() application: any;
  @Input() contactPerson: any;
  floatLabelControl = new FormControl('always');
  addContactPerson: FormGroup;
  error = Errors;
  todayDate = new Date();
  showLoader: boolean = false;
  headerText = 'Check CKYC';
  messageText = 'Please wait, Verifying CKYC';
  beneficiaryList: any = [];
  applicantUuid: string;

  IsControllingPersonIsBeneficiaryYesOrNoValue = 'yes';
  constructor(
    private route: ActivatedRoute,
    private modalController: ModalController,
    private toastController: ToastController,
    private contactPersonService: ContactPersonService,
    private commonService: CommonService,
    private applicationService: ApplicationService,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.panListerner();
    this.beneficiaryList = this.application.beneficiaryList.filter(x => x.typeOfInvestor === "Individual")
    
  }
  get form() {
    return this.addContactPerson.controls;
  }
  createForm() {
    this.addContactPerson = new FormGroup({
      sameAsBeneficiary: new FormControl('yes'),
      selectBeneficiary: new FormControl(''),
      beneficiaryTaxResidentAnyOtherCountryIfYes: new FormControl(''),
      pan: new FormControl('', validationType.PAN),
      dob: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
    });
  }
  //   selectBeneficiary(event){
  //     //alert("hellow world");

  //  }

  panListerner() {
    this.form.pan.valueChanges.subscribe((data) => {
      if (data) {
        let pan = this.form.pan.value;
        for (const iterator of this.application.holders) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }

        for (const iterator of this.application.nominees) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }

        for (const iterator of this.application.beneficiaries) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }
      }
    });
  }

  

  IsControllingPersonIsBeneficiaryYesOrNo(event) {
    this.IsControllingPersonIsBeneficiaryYesOrNoValue = event.value;
  }
  dismissModal() {
    this.modalController.dismiss();
  }

  async submit() {
    // if (
    //   this.form.pan.value &&
    //   this.form.pan.value.charAt(3).toUpperCase() !== 'P'
    // ) {
    //   this.form.pan.setErrors({ inProper: true });
    //   return;
    // }
    var emailVal = this.form.email;

    if(emailVal.toString().includes("@"))
    {

    }
    else{
      //this.form.email.setErrors({ pattern: true });
      this.form.email.setValidators(validationType.email)
      this.form.email.updateValueAndValidity();
    }
    

    if (this.form.sameAsBeneficiary.value === "yes") {
      this.form.selectBeneficiary.setValidators([Validators.required]);
      this.form.selectBeneficiary.updateValueAndValidity();

      this.form.name.clearValidators();
      this.form.name.updateValueAndValidity();

      this.form.pan.clearValidators();
      this.form.pan.updateValueAndValidity();

      this.form.dob.clearValidators();
      this.form.dob.updateValueAndValidity();

      this.form.mobile.clearValidators();
      this.form.mobile.updateValueAndValidity();

      this.form.email.clearValidators();
      this.form.email.updateValueAndValidity();

    }
    else {
      this.form.selectBeneficiary.clearValidators();
      this.form.selectBeneficiary.updateValueAndValidity();

      this.form.pan.setValidators([Validators.required]);
      this.form.pan.updateValueAndValidity();

      this.form.dob.setValidators([Validators.required]);
      this.form.dob.updateValueAndValidity();

    }

    // if (this.form.pan.value && this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
    //   this.form.pan.setErrors({ inProper: true });
    // }


    this.addContactPerson.markAllAsTouched();
    if (this.addContactPerson.invalid) return;

    if (this.form.pan.value.charAt(3).toUpperCase() !== 'P' && this.form.sameAsBeneficiary.value !== "yes") {
      this.dismissModal();
      const modal = await this.modalController.create({
        component: OfflinepopupComponent,
        backdropDismiss: false,
        cssClass: 'my-custom-class',
        componentProps: {
          errorMessage: ErrorMessage.IndividualError,
          isIndividual: true
        }
      });
      await modal.present();
      await modal.onDidDismiss().then((event) => { });
      return;
    }


    let body = {} as any;

    body.sameAsBeneficiary = this.form.sameAsBeneficiary.value;
    body.selectBeneficiary = this.form.selectBeneficiary.value;

    body.pan = this.form.pan.value;

    //Dateofbirth
    body.dob = this.form.dob.value;

    if (body.dob !== "") {
      let newDate = new Date(body.dob);
      newDate.setDate(newDate.getDate())
      let formatedDate = formatDate(newDate, 'yyyy-MM-dd', 'en');
      body.dob = formatedDate;
    }
    body.applicationUuid = this.application.investmentID;
    body.email = this.form.email.value;
    body.mobile = this.form.mobile.value;
    body.name = this.form.name.value;
    this.showLoader = true;

    this.contactPersonService.createContactPerson(body).subscribe(
      async (res: any) => {
        this.showLoader = false;

        console.log("Contact person details.....  ", res)
        // if (
        //   res.Message === 'AML not verified' ||
        //   res.Message === 'Something went wrong at CKYC'
        // ) {
        //   this.dismissModal();
        //   const modal = await this.modalController.create({
        //     component: OopsModalComponent,
        //     backdropDismiss: false,
        //     cssClass: 'my-custom-class',
        //   });
        //   await modal.present();
        //   modal.onDidDismiss().then((event) => { });

        //   return;
        // }
        var contactPersonDetails = JSON.parse(res.data);

        if (this.form.sameAsBeneficiary.value === "yes") {
          this.dismissModal();
          location.reload()
        }
        else {
          console.log('contactPersonDetails.ckycCheck',contactPersonDetails.ckycCheck);
          // if (contactPersonDetails.ckycCheck === 0) {
          //   this.dismissModal();
          //   const modal = await this.modalController.create({
          //     component: nonkycModelComponent,
          //     backdropDismiss: false,
          //     cssClass: 'my-custom-class',
          //     componentProps: {
          //       holder: contactPersonDetails,
          //       onPanCheck: false,
          //       isContactPerson: true,
          //       BeneficiaryTypeNo: 5
          //     }
          //   });
          //   await modal.present();
          //   modal.onDidDismiss().then((event) => { });
          //   return;
          // }
          // else {
            //window.location.href = '/application/applicant-kyc-details/' + contactPersonDetails.investorId + "/" + this.application.investmentID
            this.commonService.encrypt(this.application.investmentID).subscribe((res: any) => {

              var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');

              window.location.href = '/application/personal-details/'+ investorId

              //window.location.href = '/v2/application/personal-details/'+ this.application.investmentID
            });
            
            //return;
         // }
        }
      },
      async (err) => {

        this.showLoader = false;
        const toast = await this.toastController.create({
          message: err.error.message,
          duration: 2000,
        });
        toast.present();

      }
    );
  }
}
