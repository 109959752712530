import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  ToastController,
  ModalController,
  LoadingController,
} from '@ionic/angular';
import { filter, map } from 'rxjs/operators';
import { ACCESS_TOKEN, ParentURL, CurrentURL, ACCESS_ROLE } from '../../core/constants/app-strings';
import { CommonService } from '../../services/common/common.service';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { AddNewApplicantPage } from '../../featured/application/add-new-applicant/add-new-applicant.page';
import { UserService } from '../../services/user/user.service';
import { User } from '../../core/model/user.model';
import { UserTypes } from 'src/app/core/constants/enum';
import { microfrontendService } from '../../services/microfronend/microfronend.service';
import { AddDownloadOfflineComponent } from '../add-download-offline/add-download-offline.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.page.html',
  styleUrls: ['./header.page.scss'],
})
export class HeaderPage implements OnInit {
  userName: any;
  shortName: any;
  uuid: any;
  @ViewChild(MatMenuTrigger) resMenu: MatMenuTrigger;

  @Input()
  showSubheader = false;

  @Input()
  showOnLogin = false;

  @Input()
  portfolioHeader = false;

  lastUpdatedOn = ''; //moment().subtract(1, 'days').format('DD MMM YYYY');
  router1: any;
  status: any = '';
  baseUrl;
  loggedIn = false;
  panCheck: any; //PanCheckAPIResponse;
  existingFolio: ''; //ExistingFolio;
  modeOfHoldings = ''; //ModeOfHoldings;
  selectedMobileMenu: string = ''; // DashboardMenuValues.DASHBOARD;
  dashboardMenuValues = ''; //DashboardMenuValues;
  investorTypes = ''; //InvestorTypes;
  userType: string = '';
  UserTypeConstant = UserTypes;
  ApplicationsList: any = [];
  roleId: any;
  parentLink: any;
  currentLink: any;

  constructor(
    private readonly router: Router,
    private route: ActivatedRoute,
    private toastController: ToastController,
    private readonly modalController: ModalController,
    private readonly userService: UserService,
    private readonly loadingController: LoadingController,
    private microfrontendService: microfrontendService,
  ) { }

  ngOnInit() {

    if (localStorage.getItem('UserType')) {
      this.userType = localStorage.getItem('UserType');
    }
    //console.log('local storage',localStorage.getItem('UserType'))
    //this.userType = "DISTRIBUTOR";
    this.router1 = this.router;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: any) => {
          if (this.isLoggedIn()) this.loggedIn = true;
          else this.loggedIn = false;
          return event;
        })
      )
      .subscribe({
        next: (res) => { },
        error: (err) => { },
      });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.baseUrl = event.url;
      }
    });
    this.userService.getUser().subscribe((res: User) => {
      console.log(res.uuid);
      this.userName = res.name;
      this.uuid = res.uuid;
      this.shortName = this.userName.match(/\b(\w)/g).join('');
    });

    //  this.getUserCookieDetail()
  }

  // ionViewWillEnter() {
  //   //this.getuserData();
  //   this.getUserCookieDetail();
  // }

  getuserData() {
    let encstring = this.route.snapshot.params.enc;
    this.microfrontendService.getApplications(encstring).subscribe(
      (res: any) => {
      },
      (err) => {
      }
    );
  }

  getroutevalue() {
    this.router1 = this.router;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: any) => {
          if (this.isLoggedIn()) this.loggedIn = true;
          else this.loggedIn = false;
          return event;
        })
      )
      .subscribe({
        next: (res) => { },
        error: (err) => { },
      });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.baseUrl = event.url;
      }
    });
    this.userService.getUser().subscribe((res: User) => {
      console.log(res.uuid);
      this.userName = res.name;
      this.uuid = res.uuid;
      this.shortName = this.userName.match(/\b(\w)/g).join('');
      localStorage.setItem('UserType', res.userType);
      this.userType = res.userType;
    });
  }

  getUserCookieDetail() {

    console.log("Before")
    let url = window.location.href;
    var splitUrl = url.split('/');
    var requiredLastElement = splitUrl[splitUrl.length - 1];

    console.log("requiredLastElement  ", requiredLastElement)
    let token = localStorage.getItem(ACCESS_TOKEN);

    console.log("token  ", token)
    if (requiredLastElement || token) {
      console.log("entered  ")
      if (token === null || token === undefined || token === "") {

        console.log("entered after condition")
        this.microfrontendService.getApplications(requiredLastElement).subscribe(
          (res: any) => {
            if (res) {

              console.log("after Call")

              this.userService.setStroage(ACCESS_TOKEN, res.token);

              console.log("res.applicationList[0].objUserRolesList  ", res.applicationList[0].objUserRolesList)

              if (res.applicationList[0].objUserRolesList.length > 0) {

                console.log("after list condition  ")
                this.roleId = res.applicationList[0].objUserRolesList[0].roleId
                this.userService.setStroage("userDetail", JSON.stringify(res.applicationList[0]));
                this.userService.setStroage(ACCESS_ROLE, this.roleId);
                this.parentLink = ParentURL;
                this.getroutevalue()

                console.log("url  ", url)
                window.location.href = url
              }
              else
                this.router.navigateByUrl('/accessdenied');
            }
            else {
              this.logout()
              this.router.navigateByUrl('/accessdenied');
            }

          },
          (err) => {
          }
        );
      }
      else {
        this.roleId = localStorage.getItem(ACCESS_ROLE);
        this.parentLink = ParentURL;
        this.getroutevalue();
      }
    }
    else
      this.router.navigateByUrl('/accessdenied');

    // var userInfo = this.userService.cookieDataFromOtherDomain()
    // if (userInfo && userInfo?.access_token && userInfo?.userData) {
    //   this.userService.setStroage(ACCESS_TOKEN, userInfo.access_token);
    //   if (userInfo.userData.objUserRolesList.length > 0) {
    //     this.roleId = userInfo.userData.objUserRolesList[0].roleId
    //     this.parentLink = ParentURL;
    //     this.currentLink = CurrentURL;
    //     // this.getApplicationsList(userInfo.userData)
    //   }
    //   else
    //     this.router.navigateByUrl('/accessdenied');
    // }
    // else {
    //   this.logout()
    //   this.router.navigateByUrl('/accessdenied');
    // }
  }

  getApplicationsList(enc: any) {
    let userdetails = enc;
    if (userdetails && userdetails?.applicationURL) {
      if (userdetails?.applicationURL !== this.currentLink) {
        this.logout()
        this.router.navigateByUrl('/accessdenied');
      }
    }
    else {
      this.logout()
      this.router.navigateByUrl('/accessdenied');
    }
  }

  public get username() {
    // if (
    //   this.commonService.panCheckResponse &&
    //   this.commonService.panCheckResponse.user.name
    // ) {
    //   let user = this.commonService.panCheckResponse.user.name.split(' ')[0];
    //   return user;
    // } else {
    return null;
    // }
  }

  public get pancheckResponse() {
    let panCheck = JSON.parse(localStorage.getItem('pancheckResponse'));
    return panCheck;
  }

  close() {
    this.router.navigateByUrl('/dashboard');
  }

  onSelectTransaction(transactionType: string) {
    this.panCheck = JSON.parse(localStorage.getItem('pancheckResponse'));
    // this.userService.getUserDetails(this.panCheck?.user?.uuid).subscribe(
    //   (res: any) => {
    //     const userDetails = res;
    //     for (const iterator of this.panCheck.folio) {
    //       if (
    //         iterator.Folio &&
    //         iterator.Folio.toString() === userDetails.primaryFolio
    //       ) {
    //         this.existingFolio = iterator;
    //       }
    //     }
    //     if (
    //       this.existingFolio.ModeofHolding !== this.modeOfHoldings.SINGLE &&
    //       this.existingFolio.ModeofHolding !==
    //         this.modeOfHoldings.ANYONE_OR_SURVIVOR &&
    //       this.existingFolio.ModeofHolding !==
    //         this.modeOfHoldings.EITHER_OR_SURVIVOR
    //     ) {
    //       const folio = this.panCheck.folio.find(
    //         (f) =>
    //           f.ModeofHolding === this.modeOfHoldings.SINGLE ||
    //           f.ModeofHolding === this.modeOfHoldings.ANYONE_OR_SURVIVOR ||
    //           f.ModeofHolding === this.modeOfHoldings.EITHER_OR_SURVIVOR
    //       );
    //       if (folio) {
    //         this.existingFolio = folio;
    //       } else {
    //         this.showModeofHoldingError();
    //         return;
    //       }
    //     }

    //     if (this.panCheck.user?.pan.charAt(3).toUpperCase() !== 'P') {
    //       this.showModalNonIndividualError();
    //     } else {
    //       this.openSelectFolio(transactionType, userDetails);
    //     }
    //   },
    //   async (err) => {
    //     transactionType = '';
    //     if (err.error.statusCode === 403) {
    //       const toast = await this.toastController.create({
    //         message: 'Token Expired Please Login',
    //         duration: 3000,
    //       });
    //       toast.present();
    //       this.router.navigateByUrl('/home');
    //     }
    //   }
    // );
  }

  async gotoParentDomain() {
    localStorage.clear();
    window.location.href = this.parentLink;
  }

  async logout() {
    const loading = await this.loadingController.create({
      message: 'Logging out',
    });
    await loading.present();

    this.userService.logout().subscribe({
      next: async (res) => {
        await loading.dismiss();
        localStorage.clear();
        this.router.navigateByUrl('/');
      },
      error: async (err) => {
        await loading.dismiss();
        localStorage.clear();
        this.router.navigateByUrl('/');
      },
    });
  }

  async openSelectFolio(transactionType: string, response) {
    if (this.panCheck.folio) {
      for (const [key, folio] of this.panCheck.folio.entries()) {
        if (transactionType === 'lumpsum' || transactionType === 'sip') {
          if (folio.Folio && folio.Folio === parseInt(response.primaryFolio)) {
            this.router.navigate([
              `/transaction/${transactionType}/${response.uuid}`,
              { folio: response.primaryFolio },
            ]);
            break;
          }
        } else {
          if (
            folio.Folio &&
            folio.Folio === parseInt(response.primaryFolio) &&
            folio.AUM > 0
          ) {
            this.router.navigate([
              `/existing/transaction/${transactionType}/${response.uuid}`,
              { folio: response.primaryFolio },
            ]);
            break;
          } else {
            const maxAUMFolio = this.getMaximumFolio(this.panCheck.folio);
            if (maxAUMFolio) {
              this.router.navigate([
                `/existing/transaction/${transactionType}/${response.uuid}`,
                { folio: maxAUMFolio.Folio },
              ]);
            } else {
              this.router.navigate([
                `/existing/transaction/${transactionType}/${response.uuid}`,
                { folio: response.primaryFolio },
              ]);
            }
            break;
          }
        }
      }
    }
  }

  goToBaseUrl() {
    if (localStorage.getItem(ACCESS_TOKEN)) this.baseUrl = '/dashboard';
    else this.baseUrl = '/account';

    this.router.navigateByUrl(this.baseUrl);
  }

  isExistingInvestor() {
    const panCheckResponse = JSON.parse(
      localStorage.getItem('pancheckResponse')
    );
    if (
      panCheckResponse //&&
      // panCheckResponse.userType === InvestorTypes.EXISTING_INVESTOR
    )
      return true;
    else return false;
  }

  isLoggedIn() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const panCheckResponse = JSON.parse(
      localStorage.getItem('pancheckResponse')
    );
    if (
      token &&
      panCheckResponse
      //  &&
      // panCheckResponse.userType === InvestorTypes.EXISTING_INVESTOR
    )
      return true;
    else return false;
  }

  getMaximumFolio(folio) {
    const amounts = folio.map((a) => a.AUM);
    const highestAmount = Math.max(...amounts);
    return folio.find((f) => f.AUM === highestAmount);
  }

  // please change in pan and contact details from profile page & portfolio
  async showModeofHoldingError() {
    const modal = await this.modalController.create({
      component: OopsModalComponent,
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      componentProps: { jointHolder: true },
    });
    return await modal.present();
  }

  // please change in pan and contact details from profile page & portfolio
  async showModalNonIndividualError() {
    const modal = await this.modalController.create({
      component: OopsModalComponent,
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      componentProps: { nonIndiviual: true },
    });
    return await modal.present();
  }

  openMatMenuProgrammatically(selectedMenu: string) {
    this.selectedMobileMenu = selectedMenu;
  }

  async openLoginFlowModal(val) {
    const modal = await this.modalController.create({
      component: AddNewApplicantPage,
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      componentProps: {
        show: val,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((event) => {
      // this.router.navigateByUrl('/application/applicant-kyc-details');
    });
  }

  async openTopUpModal(val) {
    const modal = await this.modalController.create({
      component: AddNewApplicantPage,
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      componentProps: {
        show: val,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((event) => {
      // this.router.navigateByUrl('/application/applicant-kyc-details');
    });
  }


  async openDownloadOfflineCA(val) {
    const modal = await this.modalController.create({
      component: AddDownloadOfflineComponent,
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      componentProps: {
        applicationDetails: val,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((event) => {
   // this.ionViewWillEnter()
    });
  }

  async openAffiliateModal(val) {
 

    this.router.navigateByUrl('/application/add-affiliate');
  }

  async openDownload(){
    this.router.navigateByUrl('/documents');
  }
  
  async openPlegeUnpledge(){
    window.open("https://swift.edelweissalternatives.com/pledgeUnpledge", '_blank');
  }
  

  openSignUpFlowModal() {
    this.router.navigateByUrl('/application/signup/' + this.uuid);
  }
}


