import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Errors, validationType } from '../../core/model/error.model';
import { SignatoryService } from 'src/app/services/signatory.service';
@Component({
  selector: 'app-add-coparcener',
  templateUrl: './add-coparcener.component.html',
  styleUrls: ['./add-coparcener.component.scss'],
})
export class AddCoparcenerComponent implements OnInit {
  @Input() application: any;
  floatLabelControl = new FormControl('always');
  coparcener: FormGroup;
  error = Errors;
  isSignatoryYesOrNoValue = 'yes';
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  constructor(
    private modalController: ModalController,
    private signatoryService: SignatoryService
  ) {
    this.createForm();
  }
  createForm() {
    this.coparcener = new FormGroup({
      coparcenerOne: new FormControl('false'),
      coparcenerTwo: new FormControl('false'),
      coparcenerThree: new FormControl('false'),
      name: new FormControl('', validationType.alphaNumericWithSpace),
      email: new FormControl('', validationType.email),
    });
  }
  get form() {
    console.log(this.coparcener.controls);
    return this.coparcener.controls;
  }
  ngOnInit() {}

  dismissModal() {
    this.modalController.dismiss();
  }

  onchangeemail() {
    for (const iterator of this.application.coparcnerList) {
      if (this.form.email.value === iterator.email) {
        this.form.email.setErrors({ duplicate: true });
      }
    }
  }

  submit() {
    if (this.coparcener.invalid) return;
    let body = {
      applicationUuid: this.application.investmentID,
      name: this.form.name.value,
      email: this.form.email.value,
    };

    this.signatoryService.addCoparcner(body).subscribe(
      (res:any) => {
        if (res.message == 'Email Id Already exist') {
          this.form.email.setErrors({ duplicate: true });
        }
        else {
          this.dismissModal();
        }
        
      },
      (err) => {}
    );
  }
}
