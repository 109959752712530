import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { CreateApplication } from '../../core/model/application.model';
import { ApplicationService } from '../../services/application/application.service';
import { DropdownService } from '../../services/dropdown/dropdown.service';
import { Errors, validationType } from '../../core/model/error.model';
import { CommonService } from '../../services/common/common.service';
import { UserService } from '../../services/user/user.service';
import { User } from '../../core/model/user.model';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component'
import { InvestorType, PanType } from 'src/app/core/constants/enum';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
// import { parse } from 'path';
import { formatDate } from '@angular/common';

@Component({
  selector: 'pan-check',
  templateUrl: './pan-check.component.html',
  styleUrls: ['./pan-check.component.scss'],
})
export class PanCheckComponent implements OnInit {
  @Output() success: EventEmitter<any> = new EventEmitter();

  applicantForm: FormGroup;
  floatLabelControl = new FormControl('always');
  status: boolean = false;
  error = Errors;
  showLoader = false;
  headerText = ''; //HEADER_TEXT_PANCHECK;
  messageText = ''; //MESSAGE_TEXT_PANCHECK;
  parentFund: any;
  childFund: any;
  RmName: [{ name: 'Jinal' }];
  distributorDetails: any;
  todayDate = new Date();
  NonCkycPopup: boolean = false;
  investorType = InvestorType;
  panType = PanType;

  constructor(
    // private readonly userService: UserService,
    private modalController: ModalController,
    private readonly commonService: CommonService,
    private readonly dropdownService: DropdownService,
    private readonly applicationService: ApplicationService,
    private readonly loadingController: LoadingController,
    private userService: UserService,
    private toastController: ToastController,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
    this.getUserData();
    this.getDropDownValues();
    this.investorTypeListerner();
    this.getChildFund();
  }

  get form() {
    return this.applicantForm.controls;
  }

  investorTypeListerner() {
    this.form.typeOfInvestor.valueChanges.subscribe((data) => {
      if (this.form.typeOfInvestor.value === this.investorType.NonIndividual) {
        this.form.legalStatus.setValue('RI');
        this.form.legalStatus.updateValueAndValidity();
        this.removeAddValidation();
      } else {
        this.form.typeOfEntity.setValue('');
        this.form.typeOfEntity.updateValueAndValidity();
        this.removeAddValidation();
      }
    });
  }

  // get InvestorType() {
  //   if (localStorage.getItem('InvestorType') === 'Non-Individual') {
  //     this.form.typeOfEntity.setValidators(validationType.Required);
  //     this.form.legalStatus.setValidators(null);
  //     return this.investorType.Non_Individual;
  //   } else {
  //     return this.investorType.Individual;
  //   }
  // }

  getUserData() {
    this.userService.getUser().subscribe((res: User) => {
      this.distributorDetails = JSON.parse(res.distributorDetails);
    });
  }

  onChangePan(e) {
    let pan = this.form.pan.value;
    if (this.form.typeOfInvestor.value === this.investorType.Individual) return;

    if (pan.charAt(3).toUpperCase() === 'C') {
      this.form.applicationStatus.setValue(this.panType.Company);
    } else if (pan.charAt(3).toUpperCase() === 'H') {
      this.form.applicationStatus.setValue(this.panType.HUF);
    } else if (pan.charAt(3).toUpperCase() === 'A') {
      this.form.applicationStatus.setValue(this.panType.AOP);
    } else if (pan.charAt(3).toUpperCase() === 'B') {
      this.form.applicationStatus.setValue(this.panType.BOI);
    } else if (pan.charAt(3).toUpperCase() === 'G') {
      this.form.applicationStatus.setValue(this.panType.GovernmentAgency);
    } else if (pan.charAt(3).toUpperCase() === 'J') {
      this.form.applicationStatus.setValue(this.panType.ArtificialJuridicalPerson);
    } else if (pan.charAt(3).toUpperCase() === 'L') {
      this.form.applicationStatus.setValue(this.panType.LocalAuthority);
    } else if (pan.charAt(3).toUpperCase() === 'F') {
      this.form.applicationStatus.setValue(this.panType.FirmLiabilityPartnership);
    } else if (pan.charAt(3).toUpperCase() === 'T') {
      this.form.applicationStatus.setValue(this.panType.Trust);
    }
    else {
      this.form.applicationStatus.setValue('');
      this.form.pan.setErrors({ notvalid: true });
    }
  }

  selectStatus() {
    // this.form.applicationStatus.setValue(this.form.status.value);
    // this.form.applicationStatus.updateValueAndValidity();
  }

  createForm() {
    const pan = /^[a-zA-Z]{5}\d{4}[a-zA-Z]{1}$/;
    this.applicantForm = new FormGroup({
      pan: new FormControl('', [Validators.required, Validators.pattern(pan)]),
      dob: new FormControl('', [Validators.required]),
      // fundName: new FormControl('', [Validators.required]),
      aifName: new FormControl('', [Validators.required]),
      legalStatus: new FormControl('RI', Validators.required),
      rmName: new FormControl('', Validators.required),
      typeOfEntity: new FormControl('Financial Institution'),
      typeOfInvestor: new FormControl('Individual'),
      applicationStatus: new FormControl(''),
      dateOfIncorporation: new FormControl(''),
      status: new FormControl(''),
      POA: new FormControl(false),
    });
  }

  toggleToolTip() {
    this.status = !this.status;
  }

  getDropDownValues() {


    // var userInfo = this.userService.cookieDataFromOtherDomain()

    // console.log("userInfo==> ", userInfo)
    // this.parentFund = userInfo.userData.objUserFundsList

    this.dropdownService.getParentFund().subscribe(
      (res: any) => {
        this.parentFund = res;
      },
      (err) => {
        console.log(err)
      }
    );
  }

  getChildFund() {
    this.dropdownService
      .getChildFund()
     // .getChildFund(this.form.fundName.value.fundID)
      .subscribe(
        (res: any) => {
          this.childFund = res;
          this.childFund=this.childFund.filter(x=> x.fundId=="FUND241" || x.fundId=="FUND252" || x.fundId == "FUND239" || x.fundId =="FUND255" || x.fundId =="FUND261" || x.fundId =="FUND343" || x.fundId =="FUND360" );

          console.log(this.childFund);
        },
        (err) => { }
      );
  }

  // get TypeOfInvestor() {
  //   if (
  //     this.form &&
  //     this.form.typeOfInvestor.value === this.investorType.Individual
  //   ) {
  //     return this.investorType.Individual;
  //   } else {
  //     this.removeAddValidation();
  //     return this.investorType.NonIndividual;
  //   }
  // }

  removeAddValidation() {
    if (this.form.typeOfInvestor.value === this.investorType.NonIndividual) {
      this.form.dob.setValidators(null);
      this.form.dob.updateValueAndValidity();
      this.form.dateOfIncorporation.setValidators(validationType.Required);
      this.form.dateOfIncorporation.updateValueAndValidity();
    } else {
      this.form.dob.setValidators(validationType.Required);
      this.form.dob.updateValueAndValidity();
      this.form.dateOfIncorporation.setValidators(null);
      this.form.dateOfIncorporation.updateValueAndValidity();
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  charAt(abstractControl: AbstractControl) {
    if (!abstractControl.value) return;

    if (abstractControl.value.charAt(3) !== 'P') {
      return { inProper: true };
    } else return null;
  }

  isAlphaNumericKey(event) {
    if (this.commonService.isAlphaNumericKey(event)) {
      if (this.applicantForm.value.pan.length === 10) {
        return false;
      } else return true;
    } else {
      return false;
    }
  }

  changePOA(event) {
    if (event.checked) {
      this.form.POA.setValue(true);
    }
    else {
      this.form.POA.setValue(false);
    }
  }

  async checkCKYCAddApplicant() {
    this.applicantForm.markAllAsTouched();
    if (this.applicantForm.invalid) return;
    if (
      this.form.typeOfInvestor.value === this.investorType.Individual &&
      this.form.pan.value.charAt(3).toUpperCase() !== 'P'
    ) {
      this.form.pan.setErrors({ inProper: true });
      return;
    }

    // this.charAt()

    let payload = {} as CreateApplication;
    payload.pan = this.form.pan.value;

    // //Dateofbirth
    // payload.dateOfBirth = this.form.dob.value;
    // // let newDate = new Date(payload.dateOfBirth);
    // newDate.setDate(newDate.getDate())
    // console.log("fomatedDate:-", formatDate(newDate, 'dd-MM-yyyy', 'en'))
    // let formatedDate = formatDate(newDate, 'dd-MM-yyyy', 'en');
    // payload.dateOfBirth = formatedDate;

    if (this.form.typeOfInvestor.value === "Non-Individual") {
      payload.dateOfIncorporation = this.form.dateOfIncorporation.value;
      let newDate = new Date(payload.dateOfIncorporation);
      newDate.setDate(newDate.getDate())
      let formatedDate = formatDate(newDate, 'dd-MM-yyyy', 'en');
      payload.dateOfIncorporation = formatedDate;
    }
    else {
      payload.dateOfBirth = this.form.dob.value;
      let newDate = new Date(payload.dateOfBirth);
      newDate.setDate(newDate.getDate())
      let formatedDate = formatDate(newDate, 'dd-MM-yyyy', 'en');
      payload.dateOfBirth = formatedDate;
    }


    // payload.fundName = this.form.fundName.value.fundName;
    // payload.fundID = this.form.fundName.value.fundID;
    payload.fundName = this.form.aifName.value.parentFunName;
    payload.fundID = this.form.aifName.value.parentFundId;
    payload.aifName = this.form.aifName.value.fundName;
    payload.childfundID = this.form.aifName.value.fundId;
    payload.rmName = this.form.rmName.value;
    payload.legalStatus = this.form.legalStatus.value;
    payload.typeOfEntity = this.form.typeOfEntity.value;
    payload.typeOfInvestor = this.form.typeOfInvestor.value;
    payload.POA = this.form.POA.value;
    payload.applicationStatus = this.form.status.value === '' ? this.form.applicationStatus.value : this.form.status.value;
    this.showLoader = true;
    this.headerText = 'Check CKYC';
    this.messageText = 'Please wait, Verifying CKYC and AML';

    var reqBody;
    console.log(payload);
    //return;
    if (this.form.typeOfInvestor.value === "Non-Individual") {
      payload.dateOfIncorporation = this.form.dateOfIncorporation.value;
      let newDate = new Date(payload.dateOfIncorporation);
      newDate.setDate(newDate.getDate())
      let formatedDate = formatDate(newDate, 'dd-MM-yyyy', 'en');
      payload.dateOfIncorporation = formatedDate;

      reqBody = {
        PAN: payload.pan,
        DOB: formatedDate
      }
    }
    else {
      payload.dateOfBirth = this.form.dob.value;
      let newDate = new Date(payload.dateOfBirth);
      newDate.setDate(newDate.getDate())
      let formatedDate = formatDate(newDate, 'dd-MM-yyyy', 'en');
      payload.dateOfBirth = formatedDate;

      reqBody = {
        PAN: payload.pan,
        DOB: formatedDate
      }
    }

    this.applicationService.getCKYC(reqBody).subscribe(
      async (res: any) => {

        //console.log("res.value.status",res.value.status);
        //console.log("res.value",res.value);
        //payload.ckycStatus = res.value.status;
       var datares=res.value.data;
        if (res.error == 1) {
          payload.ckycStatus = "error";
          payload.ckycDetail = JSON.stringify(res);
        }
        else {
          if (res.value.status == "SUCCESS") {
            payload.ckycStatus = "SUCCESS";
           // payload.ckycDetail = JSON.stringify(res.value.data);

            if (datares.imagE_DETAILS && datares.imagE_DETAILS.image) {
              datares.imagE_DETAILS.image.forEach(image => {
                image.imagE_DATA = ''; // Set imagE_DATA to an empty string
              });
            }
            payload.ckycDetail = JSON.stringify(datares);
          }
          else {
            payload.ckycStatus = "error";
            payload.ckycDetail = JSON.stringify(res.value);
          }
        }
        this.applicationService.checkCKYCAndSaveApplication(payload).subscribe(
          async (res: any) => {
            this.showLoader = false;

            var response = JSON.parse(res.data);

            console.log("Pan Check Response ", response)


            if (response.typeOfInvestor === "Non-Individual") {

              if (response.ckycCheck === 1) {
                this.dismissModal();

                this.commonService.encrypt(response.investorId).subscribe((res: any) => {
                  var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');
                  console.log('investorId', investorId);
                  console.log('After first')

                  this.commonService.encrypt(response.investmentId).subscribe((res: any) => {
                    console.log('After Second')
                    var investmentId =  res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');
                    this.router.navigateByUrl(
                      'application/applicant-kyc-details/' + investorId + `/` + investmentId
                    );

                    // this.router.navigateByUrl(
                    //   'application/applicant-kyc-details/' + response.investorId + `/${response.investmentId}`
                    // );
                    return;

                  });
                });



              }
              else {
                this.dismissModal();
                const modal = await this.modalController.create({
                  component: nonkycModelComponent,
                  backdropDismiss: false,
                  cssClass: 'my-custom-class',
                  componentProps: {
                    holder: response,
                    onPanCheck: true
                  }
                });
                await modal.present();
                modal.onDidDismiss().then((event) => { });
                return;
              }

            }
            else {

              // if (response.ckycCheck === 1) {
              //   this.dismissModal();
              //   this.router.navigateByUrl(
              //     'application/applicant-kyc-details/' + response.investorId + `/${response.investmentId}`
              //   );
              //   return;
              // }

              if (response.ckycCheck === 0) {
                this.dismissModal();
                const modal = await this.modalController.create({
                  component: nonkycModelComponent,
                  backdropDismiss: false,
                  cssClass: 'my-custom-class',
                  componentProps: {
                    holder: response,
                    onPanCheck: true
                  }
                });
                await modal.present();
                modal.onDidDismiss().then((event) => { });
                return;
              }
              else {
                this.commonService.updateHolder(res);
                this.success.emit();
              }

            }

            // if (res.success == false) {
            //   this.dismissModal();
            //   const modal = await this.modalController.create({
            //     component: OopsModalComponent,
            //     backdropDismiss: false,
            //     cssClass: 'my-custom-class',
            //   });
            //   await modal.present();
            //   modal.onDidDismiss().then((event) => { });
            //   return;
            // } else {
            //   this.commonService.updateHolder(res);
            //   this.success.emit();
            // }
          },
          async (err) => {
            this.showLoader = false;
            this.dismissModal();
            // const modal = await this.modalController.create({
            //   component: OopsModalComponent,
            //   backdropDismiss: false,
            //   cssClass: 'my-custom-class',
            // });
            // await modal.present();
            // modal.onDidDismiss().then((event) => { });
            // return;

            const toast = await this.toastController.create({
              message: err.error.message,
              duration: 2000,
            });
            toast.present();
          }
        );
        // }
        // else {
        //   console.log("CKCY Erro")
        //   console.log(JSON.stringify(res.value));
        // }
      });

  }
}
