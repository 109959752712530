import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ACCESS_TOKEN } from '../../core/constants/app-strings';

@Component({
  selector: 'success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent implements OnInit {
  @Input() NctType: string = '';
  @Input() user;
  @Input() NctResponse;
  @Output() next: EventEmitter<any> = new EventEmitter();
  show: any = 'loginNow';
  @Input() buttonName: string;
  @Input() primaryFolio: string;
  @Input() oldFolio: string;
  @Input() showDiv: string;
  @Input()
  addBankNctResponse: any;
  @Input()
  addNomineeNctResponse: any;
  @Input()
  addFatcaNctResponse: any;
  @Input() holder: any;

  constructor(
    public modalController: ModalController,
    private router: Router
  ) { }

  ngOnInit() {

    console.log("Success Model   ", this.holder)
    this.holder = JSON.parse(this.holder.data)
    if (localStorage.getItem(ACCESS_TOKEN)) {
      this.show = 'proceed';
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.buttonName.currentValue) {
  //     this.show =
  //       changes.buttonName.currentValue === 'loginNow' ||
  //       changes.buttonName.currentValue === 'proceed'
  //         ? changes.buttonName.currentValue
  //         : 'loginNow';
  //   }
  // }

  async dismiss() {
    const modal = await this.modalController.dismiss({
      dismissed: true,
    });
  }

  login() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      this.next.emit('login');
    } else {
      this.dismiss();
    }
  }

  async dismissAfterFolioChanged() {
    const modal = await this.modalController.dismiss({
      dismissed: true,
    });
    location.reload();
  }
}
