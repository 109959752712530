export const ACCESS_TOKEN = 'access_token';
export const ACCESS_ROLE = 'access_role';
export const NON_INDIVIDUAL_PAN_ERROR =
  'Cannot proceed, you have entered a Non-Individual PAN';
export const HEADER_TEXT_PANCHECK = 'Just a moment';
export const MESSAGE_TEXT_PANCHECK = 'Checking our records...';
export const AUTHORIZATION = 'Authorization';
export const BEARER_TOKEN_PREFIX = 'Bearer ';
export const BANK_VALIDATE_POPUP_SUCCESS_MESSAGE =
  'Bank Account Verified Successfully';
export const BANK_VALIDATE_POPUP_SUCCESS_SMALL_MESSAGE = '';
export const BANK_VALIDATE_POPUP_ERROR_MESSAGE = 'Verification Pending';
export const BANK_VALIDATE_POPUP_ERROR_SMALL_MESSAGE =
  'Will be done later, after payment';
export const COMMITMENT_MINIMUM_AMOUNT = 10000000; // 1crore
export const F2_COMMITMENT_MINIMUM_AMOUNT_NI = 10000000; // 1crore
export const F2_COMMITMENT_MINIMUM_AMOUNT_IN = 2500000; // 25 Lakhs
export const MINIMUM_E1_CLASS_AMOUNT = 50000000;
export const SPONSOR_ENTITY_PAN = 'AABCE9810G'
export const SITE_KEY = '6Lc_rQAqAAAAALb5Ext1qCksTEwSkD4f6iTAsC4C';
export const SITE_KEY_LIVE = '6Lc_rQAqAAAAALb5Ext1qCksTEwSkD4f6iTAsC4C';
// export const SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
// export const SITE_KEY_LIVE = '6LdyNiMaAAAAAOrTPPKz88lrqG4kIAomYwoH3hrf'; //'6LfdGq4ZAAAAAGfy4ottlylNHSTnHhcTjtJJwZUx'; // Live
//export const F2 = 'FUND503';
// export const F2 = 'FUND240';
// export const F1 = 'FUND240';

// export const F1 = 'FUND501';
// export const F2 = 'FUND503';
// export const F3 = 'FUND505';

export const F1 = 'FUND229';
export const F2 = 'FUND240';
export const F3 = 'FUND242';
export const F4 = 'FUND242';
//export const F4 = 'FUND252';
export const F5 = 'FUND256';
export const F6 = 'FUND260';
export const F7 = 'FUND342';
export const F8 = 'FUND359';

export const CF1 = 'FUND232';
export const CF2 = 'FUND239';
export const CF3 = 'FUND241';
export const CF4 = 'FUND252';
export const CF5 = 'FUND255';
export const CF6 = 'FUND261';
export const CF7 = 'FUND343';
export const CF8 = 'FUND360';

export const AdminRole = '1';
export const userRole = '2';
export const ExoRole = '3';

// export const ParentURL = "http://localhost:4200/dashboard";
// export const Domain = "http://localhost:4200";
// export const CurrentURL = "http://localhost:3000/dashboard"

export const ParentURL = "https://dwaruat.edelweissalternatives.com/dashboard";
export const Domain = "https://dwaruat.edelweissalternatives.com";
export const CurrentURL = "https://pdswiftuat.edelweissalternatives.com/v2/dashboard";

// Development and Uat
