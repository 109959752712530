export const SIGNUP = '/api/v1/user/signup';
export const SIGNUP_UPDATE = '/api/v1/user/signup';
//export const SIGNUP_UPDATE = '/api/v1/user/update';
export const DELETE_DISTRIBUTOR = '/api/v1/user/deleteDistributor';
export const GET_PARENT_FUND_DROPDOWN_VALUES =
  '/api/v1/external/GetParentFundInfo';
export const GET_CHILD_FUND_DROPDOWN_VALUES = '/api/v1/external/getChildFund';
export const GET_ALLCHILD_FUND_DROPDOWN_VALUES = '/api/v1/external/getAllChildFund';
export const CREATE_APPLICATION = '/api/v1/application';
export const TRIGGER_OTP = '/api/v1/auth/generate-otp';
export const VERIFY_OTP = '/api/v1/auth/verify-otp';
export const VERIFY_OTP_CKYC = '/api/v1/auth/verify-otp-application';
export const GET_APPLICATION_DATA = '/api/v1/application/';
export const ADD_HOLDER = '/api/v1/holder/create-holder';
export const UPDATE_HOLDER = '/api/v1/holder/update-holder';
export const UPDATE_HOLDER_AML = '/api/v1/holder/update-holder-AMLComments';
export const UPDATE_HOLDER_OTP = '/api/v1/holder/update-holder/OTP';
export const GET_IFSC_DETAILS_ENDPOINT = '/api/v1/bank/getIfscDetails/';
export const BANK_ENDPOINT_ENDPOINT = '/api/v1/bank';
export const VERIFY_BANK_ACCOUNT_ENDPOINT = '/api/v1/bank/verifyBankAccount'; // pennydrop
export const SAVE_PAYMENT_DETAILS_ENDPOINT = '/api/v1/bank/payment';
export const UPDATE_PAYMENT_DETAILS_ENDPOINT = '/api/v1/bank/payment';
export const INVESTMENT_ENDPOINT = '/api/v1/investment';
export const GET_HOLDER = '/api/v1/holder/';
export const LOGIN_USER = '/api/v1/user/login';
export const LOGOUT = '/api/v1/user/logout';
export const GET_MASTER_DATA = '/api/v1/external/masterData/';
export const GET_USER = '/api/v1/user';
export const DELETE_FATCA_ENDPOINT = '/api/v1/fatca';
export const UPLOAD_DOCUMENT_ENDPOINT = '/api/v1/document';
export const UPDATE_AML_COMMENTS = '/api/v1/application/updateInvestmentAMLComments';
export const CKYC_CHECK = '/api/v1/application/ckyc';
export const ADD_NOMINEE = '/api/v1/nominee';
export const UPDATE_NOMINEE = '/api/v1/nominee';
export const DELETE_NOMINEE = '/api/v1/nominee';
export const ADD_POA = '/api/v1/POA/createPOA';
export const UPDATE_POA = '/api/v1/POA/updatePOA';
export const DELETE_POA = '/api/v1/POA';
export const DOWNLOAD_PDF = '/api/v1/esign/generateDocument';
export const DOWNLOAD_PDF_DIGIO = '/api/v1/esign/DownloadDigioPDF';
// export const DOWNLOAD_PDF = '/api/v1/esign/Download';
export const DOWNLOAD_Uploaded_File = '/api/v1/esign/Download';
export const ESIGN_ENDPOINT = '/api/v1/esign/createSignRequest';
export const SUBMIT_APPLICATION = '/api/v1/application/submit';
export const SOFTDELETE_INVESTMENT = '/api/v1/application/softdeleteApplication';
export const DELETE_UPLOADED_DOCUMENT = '/api/v1/document/delete';
export const DELETE_HOLDER_ENDPOINT = '/api/v1/holder';
export const GET_INVESTMENT_CLASS = '/api/v1/external/fetchClass/';
export const GET_COMPENSATORY_PERCENTAGE = '/api/v1/external/fetchPercentage/';
export const GET_COMPENSATORY_CONTRIBUTION =
  '/api/v1/external/GetCompensatoryContribution/';
export const DOWNLOAD_PDF_AFTER_ESIGN = '/api/v1/esign/downloadAfterSigning';
export const UPDATE_BENEFICIARY = '/api/v1/beneficiary/update-beneficiary';
export const CREATE_BENEFICIARY = '/api/v1/beneficiary/create-beneficiary';
export const GET_BENEFICIARY = '/api/v1/beneficiary/';

export const DELETE_BENEFICIARY = '/api/v1/beneficiary';
export const CREATE_CONTACT_PERSON =
  '/api/v1/contact_person/createContactPerson';
export const UPDATE_CONTACT_PERSON =
  '/api/v1/contact-person/updateContactPerson';
export const ADD_FATCA = '/api/v1/fatca/addfatca';
export const UPDATE_FATCA = '/api/v1/fatca/updatefatca';
export const ADD_CONTROLLING_PERSON = '/api/v1/fatca/addControllingPerson';
export const UPDATE_CONTROLLING_PERSON =
  '/api/v1/fatca/updateControllingPerson';
export const DOWNLOAD_NOnIndividual_PDF =
  '/api/v1/esign/generateNonIndDocument';
export const GET_SOA_FILE_BY_PAN = '/api/v1/external/getSOAFolderWithPan/';
export const DOWNLOAD_OSV_FILE = '/api/v1/external/OSVFileData';
export const DELETE_CONTACT_PERSON = '/api/v1/contact_person/';
export const DELETE_CONTROLLING_PERSON = '/api/v1/fatca/controllingPerson';
export const ADD_SIGNATORY = '/api/v1/signatory';
export const ADD_COPARCNER = '/api/v1/signatory/coparcner';
export const DELETE_SIGNATORY = '/api/v1/signatory';
export const DELETE_COPARCNER = '/api/v1/signatory/coparcner';
export const ADD_UBO = '/api/v1/ubo';
export const DELETE_UBO = '/api/v1/ubo';
export const UPDATE_UBO = '/api/v1/ubo/update';

export const GET_Applications = '/api/v1/microfrontend/getApplications';
export const ADD_TOPUP = '/api/v1/topUp/addTopUp';
export const SAVE_TOPUP = '/api/v1/topUp/save';
export const DELETE_INVESTMENT = '/api/v1/application/delete';
export const GET_ARN_DISTRIBUTOR_DATA = '/api/v1/user/getArnDistributor/';

export const GET_AIF_DATA = '/api/v1/affiliate/getAIFNAme';
export const GET_PAN_APPLICATION = '/api/v1/affiliate/getAllData';
export const ADD_AFFILIATE_ENDPOINT = '/api/v1/affiliate/addaffiliateNew';
export const GET_AFFILIATE_DOC = '/api/v1/affiliate/getAffiliateDocument';
export const AFFILIATE_APPROVAL = '/api/v1/affiliate/approvalAffiliate';
export const SUBMIT_AFFILIATE = '/api/v1/affiliate/submitAffiliate';
export const GET_AFFILIATE_APPLICATION = '/api/v1/affiliate/getAffiliateApplicationn';
export const GET_AFFILIATE_INVESTOR = '/api/v1/affiliate/getAffiliateInvestor';
export const GET_FILETYPEMASTER = '/api/v1/application/getFileTypeMaster';
export const GET_DISTRIBUTORSOALIST = '/api/v1/distributor/GetFundwiseSOAList';
export const CREATE_DISTRIBUTORLIST = '/api/v1/distributor';
export const DOWNLOAD_DISTRIBUTOR = '/api/files/DownloadFile';
export const DOWNLOAD_DISTRIBUTORPASS = '/api/files/DownloadFilewithPassword';
export const GET_SOALISTMONTH = '/api/v1/distributor/GetFundwiseSoaListMonth';
export const GET_TOKEN = '/api/authentication/GenerateToken';
export const GET_SOAINVESTORLIST = '/api/v1/distributor/listInvestor';
export const DOWNLOAD_MULTIPLEFILES = '/api/v1/esign/downloadMultipleFiles';
export const UPLOAD_DOCUMENT_ENDPOINTLINUX = '/api/files/UploadS3Bucket';
export const UPLOAD_DOCUMENT_ENDPOINTSERVER = '/api/v1/document/uploadServer';
export const ADD_GUARDIAN = '/api/v1/guardian/createguardian';
export const DELETE_GUARDIAN = '/api/v1/guardian';
export const PHYSICAL_SIGN = '/api/v1/Physicalsign/physicalsign'
export const GET_PAYMENT_MODE = '/api/v1/Physicalsign/getPaymentMode';
export const GET_PAYMENT_TYPE ='/api/v1/Physicalsign/getPaymentType';
export const GET_ACCOUNT_TYPE='/api/v1/Physicalsign/getAccountType'
export const ADD_PAYMENT_DETAILS = '/api/v1/Physicalsign/paymentdetails';
export const GET_INVESTOR_BANK_DETAILS = '/api/v1/Physicalsign/getBankDetails';
export const DOWNLOAD_PHYSICAL_SIGN_MULTIPLEFILES = '/api/v1/Physicalsign/getDocuments';
export const GET_PAYMENT_DETAILS ='/api/v1/Physicalsign/getPaymentDetails';
export const APPLICATION_RECHECK='/api/v1/Physicalsign/applicationRecheck';
export const DELETE_APPLICATION='/api/v1/Physicalsign/applicationDelete';

export const DOWNLOAD_OFFLINE = '/api/v1/application/offline';
export const DOWNLOAD_OFFLINECA = '/api/v1/application/downloadCurrentCA';
export const GET_CA_MASTERDETAILS = '/api/v1/application/getCAMaster';
export const APPROVE_CLASS = '/api/v1/application/classApproval';
export const APPROVE_AGE = '/api/v1/application/ageApproval';

export const ALTSDOWNLOAD_DISTRIBUTORPASSD = '/api/v1/Digio/DownloadDistrubutor';

 
