import { Injectable } from '@angular/core';
import { Beneficiary } from 'src/app/core/model/beneficiary.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  CREATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  GET_BENEFICIARY,
  UPDATE_BENEFICIARY,
} from 'src/app/core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from 'src/app/core/constants/app-strings';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryService {
  constructor(private http: HttpClient) { }

  updateBeneficiary(body: Beneficiary) {
    const url = environment.APIURL + UPDATE_BENEFICIARY;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  createBeneficiary(body) {
    const url = environment.APIURL + CREATE_BENEFICIARY;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  getBeneficiaryById(uuid: string, applicationuuid: string) {
    const url = environment.APIURL + GET_BENEFICIARY + uuid + `/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  deleteBeneficiary(uuid: string, applicationuuid: string) {
    const url = environment.APIURL + DELETE_BENEFICIARY + `/${uuid}/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }
}
