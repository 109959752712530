import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ADD_HOLDER,
  DELETE_HOLDER_ENDPOINT,
  GET_APPLICATION_DATA,
  GET_HOLDER,
  UPDATE_HOLDER,
  UPDATE_HOLDER_OTP,
  UPDATE_HOLDER_AML,
} from '../../core/constants/api-endpoints';
import { AddHolder, Holder, HolderAMLComments } from '../../core/model/holder.model';
import { environment } from '../../../environments/environment';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';

@Injectable({
  providedIn: 'root',
})
export class HolderService {
  constructor(private http: HttpClient) { }

  addHolder(body: AddHolder) {
    const url = environment.APIURL + ADD_HOLDER;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }
  getHolder(uuid: string) {
    const url = environment.APIURL + GET_HOLDER + uuid;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }
  updateHolder(body: Holder) {
    const url = environment.APIURL + UPDATE_HOLDER;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  updateHolderAMLComments(body: HolderAMLComments) {
    const url = environment.APIURL + UPDATE_HOLDER_AML;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  updateHolderOTP(body: Holder) {
    const url = environment.APIURL + UPDATE_HOLDER_OTP;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  deleteHolder(uuid: string, applicationuuid: string) {
    const url = environment.APIURL + DELETE_HOLDER_ENDPOINT + `/${uuid}/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }
}
