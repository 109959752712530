import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CKYC_CHECK,
  CREATE_APPLICATION,
  DELETE_UPLOADED_DOCUMENT,
  DOWNLOAD_OSV_FILE,
  DOWNLOAD_Uploaded_File,
  ESIGN_ENDPOINT,
  GET_APPLICATION_DATA,
  GET_SOA_FILE_BY_PAN,
  SUBMIT_APPLICATION,
  UPLOAD_DOCUMENT_ENDPOINT,
  SOFTDELETE_INVESTMENT,
  UPLOAD_DOCUMENT_ENDPOINTLINUX,
  UPLOAD_DOCUMENT_ENDPOINTSERVER,
  PHYSICAL_SIGN,
  UPDATE_AML_COMMENTS,
  DOWNLOAD_OFFLINECA,
  GET_CA_MASTERDETAILS,
  APPROVE_CLASS,
  APPROVE_AGE,
} from '../../core/constants/api-endpoints';
import { environment } from '../../../environments/environment';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';
import { UploadDocument, updateInvestmentAML, classApprovalbody,ageApprovalbody } from '../../core/model/application.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(private http: HttpClient) {}

  checkCKYCAndSaveApplication(body) {
    const url = environment.APIURL + CREATE_APPLICATION;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  getCKYC(body) {
    const url = 'https://swift.edelweissalternatives.com/api/CKYC/CkycDownloadAPI';
    let headers = {} as any;
    return this.http.post(url, body);
    // return this.http.post(url, body, { headers });
  }

  getApplicationByUuid(uuid: string) {
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    const url = environment.APIURL + GET_APPLICATION_DATA + uuid;

    return this.http.get(url, { headers });
  }

  getAMLApplicationByUuid(uuid: string) {
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    const url = environment.APIURL + GET_APPLICATION_DATA + 'AML/' + uuid;
    return this.http.get(url, { headers });
  }

  sendForEsignRequest(applicationId: string) {
    const url = environment.APIURL + ESIGN_ENDPOINT + `/${applicationId}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  updateHolderAMLComments(body: updateInvestmentAML) {
    const url = environment.APIURL + UPDATE_AML_COMMENTS;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  // rahin

  softdeleteApplication(applicationId: string) {
    const url =
      environment.APIURL + SOFTDELETE_INVESTMENT + `/${applicationId}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    const body = {} as any;
    return this.http.post(url, body, { headers });
  }

  uploadDocument(file, body: UploadDocument) {
    const url = environment.APIURL + UPLOAD_DOCUMENT_ENDPOINT;
    const uploadData = new FormData();
    uploadData.append('documentType', body.documentType);

    if (body.holderUuid) {
      uploadData.append('holderUuid', body.holderUuid);
      uploadData.append('correspondanceSameAsPermanentAddress', body.correspondanceSameAsPermanentAddress);
      uploadData.append('nomineeUuid', '');
      uploadData.append('signatoryUuid', '');
    }
    if (body.nomineeUuid) {
      uploadData.append('nomineeUuid', body.nomineeUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('signatoryUuid', '');
    }
    if (body.beneficiaryUuid) {
      uploadData.append('beneficiaryUuid', body.beneficiaryUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('poaUuid', '');
    }
    if (body.signatoryUuid) {
      uploadData.append('signatoryUuid', body.signatoryUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
      uploadData.append('poaUuid', '');
    }
    if (body.poaUuid) {
      uploadData.append('poaUuid', body.poaUuid);
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }

    if (body.uboUuid) {
      uploadData.append('uboUuid', body.uboUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.contactPersonUuid) {
      uploadData.append('contactPersonUuid', body.contactPersonUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.controllingPersonUuid) {
      uploadData.append('controllingPersonUuid', body.controllingPersonUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.groupid) {
      uploadData.append('groupid', body.groupid);
      uploadData.append('relation', body.relation);
    }

    uploadData.append('applicationUuid', body.applicationUuid);
    uploadData.append('file', file, file?.name);

    let headers = {} as any;
    // headers[AUTHORIZATION] =
    //   BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, uploadData);
  }

  
  getSOAFileWithPan(body) {
    const url = environment.APIURL + GET_SOA_FILE_BY_PAN;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  downloadDocument(docName, investorId, applicationUuid, fileName) {
    const url = environment.APIURL + DOWNLOAD_Uploaded_File;

    let body = {} as any;
    body.investorId = investorId.toString();
    body.applicationUuid = applicationUuid.toString();
    body.documentName = docName;
    body.fileName = fileName;

    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  ckeckCKYC(body: { pan: string; dateOfBirth: string }) {
    const url = environment.APIURL + CKYC_CHECK;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  submitApplication(uuid: string, physicalSign = false) {
    const url =
      environment.APIURL + SUBMIT_APPLICATION + `/${uuid}/${physicalSign}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    const body = {} as any;
    return this.http.post(url, body, { headers });
  }

  deleteDocument(documentid: number, fileName: string) {
    const url =
      environment.APIURL + DELETE_UPLOADED_DOCUMENT + `/${documentid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }

  getApllicationById(applicationuuId) {
    const url = environment.APIURL + GET_APPLICATION_DATA + applicationuuId;
    let headers = {} as any;
    const body = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  
  uploadDocumentServer(file, body: UploadDocument) {
    const url = environment.APIURL + UPLOAD_DOCUMENT_ENDPOINTSERVER;
   // const url = environment.LINUX + UPLOAD_DOCUMENT_ENDPOINTLINUX;
    
    const uploadData = new FormData();
    uploadData.append('documentType', body.documentType);
    if (body.holderUuid) {
      uploadData.append('holderUuid', body.holderUuid);
      uploadData.append('nomineeUuid', '');
      uploadData.append('signatoryUuid', '');
    }
    if (body.nomineeUuid) {
      uploadData.append('nomineeUuid', body.nomineeUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('signatoryUuid', '');
    }
    if (body.beneficiaryUuid) {
      uploadData.append('beneficiaryUuid', body.beneficiaryUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('poaUuid', '');
    }
    if (body.signatoryUuid) {
      uploadData.append('signatoryUuid', body.signatoryUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
      uploadData.append('poaUuid', '');
    }
    if (body.poaUuid) {
      uploadData.append('poaUuid', body.poaUuid);
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }

    if (body.uboUuid) {
      uploadData.append('uboUuid', body.uboUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.contactPersonUuid) {
      uploadData.append('contactPersonUuid', body.contactPersonUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.controllingPersonUuid) {
      uploadData.append('controllingPersonUuid', body.controllingPersonUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.groupid) {
      uploadData.append('groupid', body.groupid);
      uploadData.append('relation', body.relation);
    }

    uploadData.append('applicationUuid', body.applicationUuid);
    uploadData.append('filename', file?.name);
    //uploadData.append('file', file, file?.name);

    let headers = {} as any;
    // headers[AUTHORIZATION] =
    //   BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, uploadData);
  }

  uploadDocumentLinux(file, body: UploadDocument) {
    // const url = environment.APIURL + UPLOAD_DOCUMENT_ENDPOINT;
    const url = environment.LINUX + UPLOAD_DOCUMENT_ENDPOINTLINUX;

    const uploadData = new FormData();
    uploadData.append('documentType', body.documentType);
    if (body.holderUuid) {
      uploadData.append('holderUuid', body.holderUuid);
      uploadData.append('nomineeUuid', '');
      uploadData.append('signatoryUuid', '');
    }
    if (body.nomineeUuid) {
      uploadData.append('nomineeUuid', body.nomineeUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('signatoryUuid', '');
    }
    if (body.beneficiaryUuid) {
      uploadData.append('beneficiaryUuid', body.beneficiaryUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('poaUuid', '');
    }
    if (body.signatoryUuid) {
      uploadData.append('signatoryUuid', body.signatoryUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
      uploadData.append('poaUuid', '');
    }
    if (body.poaUuid) {
      uploadData.append('poaUuid', body.poaUuid);
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }

    if (body.uboUuid) {
      uploadData.append('uboUuid', body.uboUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.contactPersonUuid) {
      uploadData.append('contactPersonUuid', body.contactPersonUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.controllingPersonUuid) {
      uploadData.append('controllingPersonUuid', body.controllingPersonUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.groupid) {
      uploadData.append('groupid', body.groupid);
      uploadData.append('relation', body.relation);
    }

    uploadData.append('applicationUuid', body.applicationUuid);
    uploadData.append('file', file, file?.name);

    let headers = {} as any;
    // headers[AUTHORIZATION] =
    //   BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, uploadData);
  }


  //to upload 2 physical sign documents
  async uploadFile(file: File, body: UploadDocument) {
    const url = 'https://swift.edelweissalternatives.com/prod/api/files/UploadS3Bucket';
    const uploadData = new FormData();
    uploadData.append('documentType', body.documentType);
    if (body.holderUuid) {
      uploadData.append('holderUuid', body.holderUuid);
      uploadData.append('nomineeUuid', '');
      uploadData.append('signatoryUuid', '');
    }
    if (body.nomineeUuid) {
      uploadData.append('nomineeUuid', body.nomineeUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('signatoryUuid', '');
    }
    if (body.beneficiaryUuid) {
      uploadData.append('beneficiaryUuid', body.beneficiaryUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('poaUuid', '');
    }
    if (body.signatoryUuid) {
      uploadData.append('signatoryUuid', body.signatoryUuid);
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
      uploadData.append('poaUuid', '');
    }
    if (body.poaUuid) {
      uploadData.append('poaUuid', body.poaUuid);
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }

    if (body.uboUuid) {
      uploadData.append('uboUuid', body.uboUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.contactPersonUuid) {
      uploadData.append('contactPersonUuid', body.contactPersonUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.controllingPersonUuid) {
      uploadData.append('controllingPersonUuid', body.controllingPersonUuid);
      uploadData.append('poaUuid', '');
      uploadData.append('signatoryUuid', '');
      uploadData.append('holderUuid', '');
      uploadData.append('nomineeUuid', '');
      uploadData.append('beneficiaryUuid', '');
    }
    if (body.groupid) {
      uploadData.append('groupid', body.groupid);
      uploadData.append('relation', body.relation);
    }

    uploadData.append('applicationUuid', body.applicationUuid);
    uploadData.append('file', file, file?.name);

    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
   return await  this.http.post(url, uploadData, { headers }).toPromise();
  }

  // save physical upload details
  savePhysicalUploadDetails(
    InvestmentId,
    InvestorId,
    userId,
    Document1,
    Document2,
    SignedDate,

 
  ) {
    const url = environment.APIURL + PHYSICAL_SIGN;

    let body = {} as any;
    body.InvestmentId = InvestmentId;
    body.InvestorId = InvestorId;
    body.userId= userId;
    body.Document1 = Document1;
    body.Document2 = Document2;
    body.SignedDate = SignedDate;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);

     return this.http.post(url, body, { headers });
  }


  downloadOfflineCA(childfundid :any, typeOfInvestor: any) {
    //const url = environment.APIURL + DOWNLOAD_OFFLINECA;
    const url = environment.APIURL + DOWNLOAD_OFFLINECA + `/${childfundid}` + `/${typeOfInvestor}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    const body = {} as any;
    return this.http.post(url, body, { headers });
   }


   getCA_Master() {
    //const url = environment.APIURL + DOWNLOAD_OFFLINECA;
    const url = environment.APIURL + GET_CA_MASTERDETAILS ;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    const body = {} as any;
   // return this.http.get(url, body, { headers });
    return this.http.get(url, { headers });
    
   }

   setAgeApproval(body: ageApprovalbody) {
    const url =
      environment.APIURL + APPROVE_AGE;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  setClassApproval(body: classApprovalbody) {
    const url =
      environment.APIURL + APPROVE_CLASS;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }
  }
