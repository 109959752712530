import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CommonService } from '../../services/common/common.service';
import { GenerateOTP } from '../../core/model/auth.model';
import { OtpService } from '../../services/otp/otp.service';
import { User } from '../../core/model/user.model';
import { OtpTriggerTypes } from '../../core/constants/enum';
import { UserService } from '../../services/user/user.service';
import { Holder } from '../../core/model/holder.model';
import { HolderService } from '../../services/holder/holder.service';


@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent implements OnInit {
  @Output() confirm: EventEmitter<any> = new EventEmitter();
  holder: any;
  user: User;
  showKYCLoader: boolean = false;
  kycComp = 'kycComp';
  application: any;
  applicantUuid;

  constructor(
    private otpService: OtpService,
    private router: Router,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private readonly commonService: CommonService,
    private readonly userService: UserService,
    private holderService: HolderService,
  ) { }

  ngOnInit() {
    this.commonService.holder$.subscribe((res) => {

      if (res.data !== null && res.data !== undefined) {
        this.holder = JSON.parse(res.data);
      }
      else {
        this.holder = res;
      }
    });
    this.getUserDetails();
  }

  getUserDetails() {
    this.commonService.user$.subscribe((res: User) => {
      this.user = res;
    });
    this.userService.getUser().subscribe((res: User) => {
      this.user = res;
    });
  }


  submitWithOTP() {
    // const objInvestor = JSON.parse(this.holder.data);

    // let application_rmName = this.user.distributorEntityName;
    let application_rmName = this.holder.rmName;

    let rmDetails = JSON.parse(this.user.distributorDetails).filter(function (item) {
      return item.RmName == application_rmName;
    });

    //console.log(rmDetails[0].RmEmail);


    const payload = {} as any;

    payload.holderUuid = this.holder.investorId; //this.holder.uuid;
    //payload.phone =objInvestor.mobile // this.holder.mobile;
    //payload.email = this.holder.email;
    payload.email = rmDetails[0].RmEmail;
    payload.rmName = rmDetails[0].RmName;
    payload.action = OtpTriggerTypes.ADD_NEW_APPLICATION_OTP;
    payload.user = this.user;
    payload.welcomeMail = false;
    // payload.holder = this.holder;

    console.log("this.holder1111111------", this.holder)
    this.otpService.generateOTP(payload).subscribe(
      (res) => {
        this.confirm.emit({
          show: 'otp',
          value: this.user,
          holder: this.holder,
        });
      },
      (err) => { }
    );
  }

  submitWithOutOTP() {

    let body = {} as Holder;
    body.otpTriggered = false;
    body.uuid = this.holder.investorId;
    // this.showKYCLoader = true;
    setTimeout(() => {

      this.modalController.dismiss();
      // this.showKYCLoader = false;
      // this.router.navigateByUrl(
      //   '/application/applicant-kyc-details/' + this.holder.investorId + "/" + this.holder.investmentId
      // );

      this.commonService.encrypt(this.holder.investorId).subscribe((res: any) => {
        var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==','--').replaceAll('=','-');
        console.log('After first')
        this.commonService.encrypt( this.holder.investmentId).subscribe((res: any) => {
          var investmentId = investorId + '/' + res.encryptedText.replaceAll('/', 'slh').replaceAll('==','--').replaceAll('=','-');
          console.log('After Second')
          console.log('/application/applicant-kyc-details/' + investmentId);
          this.router.navigateByUrl(
            '/application/applicant-kyc-details/' + investmentId
          );
  
          //window.location.href = '/v2/application/applicant-kyc-details/' + this.holder.investorId + "/" + this.holder.investmentId;
        });
      });

      
    }, 1000);


    // this.holderService.updateHolderOTP(body).subscribe(
    //   (res) => {

    //   },
    //   (err) => {
    //     this.showKYCLoader = false;
    //   }
    // );
  }
}
