import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GET_CHILD_FUND_DROPDOWN_VALUES,
  GET_PARENT_FUND_DROPDOWN_VALUES,
  GET_FILETYPEMASTER,
  GET_ALLCHILD_FUND_DROPDOWN_VALUES,
} from '../../core/constants/api-endpoints';
import { environment } from '../../../environments/environment';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(private http: HttpClient) { }

  getParentFund() {
    const url = environment.APIURL + GET_PARENT_FUND_DROPDOWN_VALUES;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url,{headers});
  }

  getChildFundold(parentFundId) {
    const url =
      environment.APIURL + GET_CHILD_FUND_DROPDOWN_VALUES + '/' + parentFundId;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url,{headers});
  }

  getChildFund() {
    const url =
      environment.APIURL + GET_ALLCHILD_FUND_DROPDOWN_VALUES ;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url,{headers});
  }

  getAIFFund() {
    const url = environment.APIURL + GET_PARENT_FUND_DROPDOWN_VALUES;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url,{headers});
  }

  getFileTypeMaster() {
    const url = environment.APIURL + GET_FILETYPEMASTER;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url,{headers});
  }
}
