import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  ADD_COPARCNER,
  ADD_SIGNATORY,
  DELETE_COPARCNER,
  DELETE_SIGNATORY,
} from '../core/constants/api-endpoints';

import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../core/constants/app-strings';

@Injectable({
  providedIn: 'root',
})
export class SignatoryService {
  constructor(private http: HttpClient) { }

  addSignatory(body) {
    const url = environment.APIURL + ADD_SIGNATORY;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  addCoparcner(body) {
    const url = environment.APIURL + ADD_COPARCNER;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  deleteSignatory(uuid: string, applicationuuid: string) {
    const url = environment.APIURL + DELETE_SIGNATORY + `/${uuid}/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }

  deleteCoparcner(uuid: string, applicationuuid: string) {
    const url = environment.APIURL + DELETE_COPARCNER +  `/${uuid}/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }
}
