export enum PaymentModes {
  NEFT_RTGS = 'NEFT_RTGS',
  CHEQUE_DD = 'CHEQUE_DD',
}
export enum UserTypes {
  DISTRIBUTOR = 'DISTRIBUTOR',
  INVESTOR = 'INVESTOR',
  CLIENT = 'CLIENT',
}

export enum OtpTriggerTypes {
  LOGIN = 'Login',
  RESEND = 'Resend',
  ADD_NEW_APPLICATION_OTP = 'ADD_NEW_APPLICATION_OTP',
}

export enum MasterTypeValues {
  BANK_ACC_TYPE = 'accounttype',
  CLASSMASTER = 'classmaster',
  CC = 'cc',
}

export enum ApplicationStatus {
  DRAFT = 'Draft',
  PENDING = 'PENDING',
  SUBMITTED = 'Submitted to RTA',
  RECHECK = 'Recheck',
  CAMS_PENDING = 'CAMS_PENDING',
  CAMS_APPROVED = 'CAMS_APPROVED',
  ESIGN_PENDING = 'Esign Pending',
  ESIGN_DONE = 'Esign Done',
  READY_FOR_ESIGN = 'Ready for Esign',
  PAYMENT_INITIATED = 'Payment Initiated',
  PAYMENT_SUCCESS = 'Payment Success',
  UNIT_ALLOTED = 'Units Alloted',
  SOA_PENDING = 'SOA Pending',
  SOA_PROCESSED = 'SOA Processed',
  TRANSACTION_COMPLETED = 'Transaction Completed',
  APPROVAL_PENDING = 'Approval Pending',
  AFFILIATE_PENDING = 'Aff. Esign Pending',
  AFFILIATE_ESIGNPENDING = 'Aff. Esign Pending',
  AFFILIATE_ESIGNDONE = 'Aff. Esign Done',
  PHYSICAL_SIGN_DONE='Physical Sign Done',
  SIGN_VERIFICATION_PENDING ='Sign Verification Pending',
  PARTIAL_PAYMENT_PENDING='Partial Payment Pending',
  PAYMENT_APPROVAL_PENDING='Payment Approval Pending'
  
}

export enum DashboradStatus {
  INPROGRESS = 'In Progress',
  TRANSACTION = 'Transaction',
  ONBOARDED = 'Onboarded',
}
export enum DocumentTypesEnum {
  AML_DOCUMENT = 'AML Document',
  PHOTO_PASSPORT_SIZE = 'Photo (Passport size)',
  AADHAAR_CARD = 'Aadhaar Card',
  DRIVING_LICENSE = 'Driving License(POA)',
  PASSPORT = 'Passport(POA)',
  VOTER_IDENTITY_CARD = 'Voter Identity Card(POA)',
  NREGA_JOB_CARD = 'NREGA Job Card(POA)',
  NATIONAL_POPULATION_REGISTER_LETTER = 'National Population Register Letter(POA)',
  CANCELLED_CHEQUE = 'Cancelled Cheque',
  PAN = 'PAN Card',
  Topup_Declaration_option = 'Top up Declaration',
  POADocument1='Document 01',
  POADocument2='Document 02',
  POADocument3='Document 03',
  POADocument4='Document 04',
  POADocument5='Document 05',
}

export enum DocumentTypesforDeemed {
 DEEMED_UTILITY ='Utility Bill(Deemed POA)' ,
 DEEMED_PROPERTTAX ='Property Tax Receipt(Deemed POA)' ,
 DEEMED_BANK='Bank Account Statement' ,
 DEEMED_PPO='PPOs(Deemed POA)' ,
 DEEMED_ACCOLETTER ='Accommodation letter(Deemed POA)' ,

}

export enum POADocumentTypesEnum {
  REQUEST_LETTER = 'Request letter from client stating to map POA',
  NOTARIZED_COPY_POA = 'Notarized copy of POA',
  BANK_PROOF_POA = 'Bank Proof of the POA',
  PAN_COPY_POA = 'PAN copy of the POA',
}

export enum POADocumentTypesEnum_Non_Indi {
  REQUEST_LETTER = 'Request letter from client stating to map POA',
  NOTARIZED_COPY_POA = 'Notarized copy of POA',
  BANK_PROOF_POA = 'Bank Proof of the POA',
  PAN_COPY_POA = 'PAN copy of the POA',
  BOARD_RESOLUTION_POA = 'Board resolutions from POA holder',
  AUTHORIZED_SIGNATURE_LIST_OF_POA_HOLDER = 'Authorized signature list of the POA holder'
}

export enum DocumentTypesEnum_NonIndividual_company {
  AML_DOCUMENT = 'AML Document',
  PAN = 'PAN Card',
  TELEPHONE = 'Latest Telephone Bill (only land line)',
  ELECTRICITY = 'Latest Electricity Bill',
  BANK_STATEMENT = 'Latest Bank Account Statement',
  LEASE_SALE_AGGREMENT = 'Registered Lease/Sale Agreement of Office Premises',
  CANCELLED_CHEQUE = 'Cancelled cheque with contributors/company name',
  BOARD_INVESTMENTS = 'Board Resolution authorizing investments (duly sign & stamp)',
  SHARE_HOLDING_PATTERN = 'Latest Share Holding Pattern',
  MOA = 'MOA & AOA',
  SIGNATORY = 'List of Authorised signatory / List of Directors',
  CERTIFICATE_OF_INCORPORATION = 'Certificate of Incorporation',
  REGISTRATION_CERTIFICATE = 'Registration Certificate',
  Resolution_Managing_Committee = 'Resolution of Board/Managing Committee',
}

export enum DocumentTypesEnum_NonIndividual_LPP {
  AML_DOCUMENT = 'AML Document',
  PAN = 'PAN Card',
  TELEPHONE = 'Latest Telephone Bill (only land line)',
  ELECTRICITY = 'Latest Electricity Bill',
  BANK_STATEMENT = 'Latest Bank Account Statement',
  LEASE_SALE_AGGREMENT = 'Registered Lease/Sale Agreement of Office Premises',
  CANCELLED_CHEQUE = 'Cancelled cheque with contributors/LLP name',
  LETTER_AUTHORITY = 'Letter of authority for investment (duly sign & stamp)',
  LLP_Partnership_Deep = 'LLP Partnership Deed',
  REGISTRATION_CERTIFICATE = 'Registration certificate',
  LIST_OF_PARTNERS = 'List of partners',
  CERTIFICATE_OF_INCORPORATION = 'Certificate of Incorporation/Formation',
  RESOLUTION_OF_BOARD = 'Resolution of Board/Managing Committee'
}

export enum DocumentTypesEnum_NonIndividual_HUF {
  AML_DOCUMENT = 'AML Document',
  PAN = 'PAN Card',
  TELEPHONE = 'Latest Telephone Bill (only land line)',
  ELECTRICITY = 'Latest Electricity Bill',
  BANK_STATEMENT = 'Latest Bank Account Statement',
  LEASE_SALE_AGGREMENT = 'Registered Lease/Sale Agreement of Office Premises',
  CANCELLED_CHEQUE = 'Cancelled cheque with contributors/HUF name',
  HUF_DECLARATION = 'HUF Declaration',
  REGISTRATION_CERTIFICATE = 'Registration Certificate',
  CERTIFICATE_OF_INCORPORATION = 'Certificate of Incorporation/Formation',
  RESOLUTION_OF_BOARD = 'Resolution of Board/Managing Committee',
  Topup_Declaration_option = 'Top up Declaration'
}

export enum DocumentTypesEnum_NonIndividual_PARTNERSHIP {
  AML_DOCUMENT = 'AML Document',
  PAN = 'PAN Card',
  TELEPHONE = 'Latest Telephone Bill (only land line)',
  ELECTRICITY = 'Latest Electricity Bill',
  BANK_STATEMENT = 'Latest Bank Account Statement',
  LEASE_SALE_AGGREMENT = 'Registered Lease/Sale Agreement of Office Premises',
  CANCELLED_CHEQUE = 'Cancelled cheque with contributors/ Partnership Firm name',
  PARTNERSHIP_DEED = 'Partnership Deed',
  REGISTRATION_PARTNERSHIP_FIRM = 'Registration (for registered partnership firm)',
  LIST_OF_PARTNERS = 'List of partners',
  Letter_of_authority_for_investment = 'Letter of authority for investment (duly signed & stamp)',
  CERTIFICATE_OF_INCORPORATION = 'Certificate of Incorporation/Formation',
  RESOLUTION_OF_BOARD = 'Resolution of Board/Managing Committee'
}

export enum DocumentTypesEnum_NonIndividual_TRUST {
  AML_DOCUMENT = 'AML Document',
  PAN = 'PAN Card',
  TELEPHONE = 'Latest Telephone Bill (only land line)',
  ELECTRICITY = 'Latest Electricity Bill',
  BANK_STATEMENT = 'Latest Bank Account Statement',
  LEASE_SALE_AGGREMENT = 'Registered Lease/Sale Agreement of Office Premises',
  CANCELLED_CHEQUE = 'Cancelled cheque with contributors/ trust name',
  TRUST_DEED = 'Trust Deed',
  Certificate_of_registration = 'Certificate of registration (for registered trust only).',
  LIST_OF_TRUSTEE = 'List of signatory / List of Trustee',
  CERTIFICATE_OF_INCORPORATION = 'Certificate of Incorporation/Formation',
  RESOLUTION_OF_BOARD = 'Resolution of Board/Managing Committee'
}

export enum DocumentTypesEnum_NonIndividual_Common {
  PAN = 'Pan Card',
}

export enum InvestorType {
  Individual = 'Individual',
  NonIndividual = 'Non-Individual',
}

export enum PanType {
  Individual = 'Individual',
  HUF = 'Hindu Undivided Family',
  AOP = 'Association of Persons',
  BOI = 'Body of Individuals',
  GovernmentAgency = 'Government Agency',
  ArtificialJuridicalPerson = 'Artificial Juridical Person',
  LocalAuthority = 'Local Authority',
  FirmLiabilityPartnership = 'Firm/ Limited Liability Partnership',
  Trust = 'Trust',
  Company = 'Company',
  LLP = 'LLP',
  Partnership = 'Partnership',
}

export enum EntityType {
  FI = 'Financial Institution',
  DNFE = 'Direct Reporting NFE',
  NFE = 'NFE',
}

export enum BeneficiaryType {
  Director = 'Director',
  Trustee = 'Trustee',
  Partners = 'Partner',
  Karta = 'Karta',
  Company = 'Company',
  Director_Promoter = 'Director/Promoter',
}

export enum BeneficiaryTypeNo {
  Nominee = 1,
  Holder = 2,
  POA = 3,
  Beneficiary = 4,
  ContactPerson = 5,
  Signers = 6,
  UBO = 7,
  ControllingPerson = 8,
  Coparcener = 9
}

export enum ErrorMessage {
  IndividualError = 'Sorry! Only Individual applicant is allowed.',
  NonKYCError = 'Sorry! We are currently not servicing non-individual non-KYC compliant applicant online.Please take this application offline and submit to the nearest CAMS branch.'
}

export enum NomineeType {
  Minor = 'minor',
  Major = 'major',
}
