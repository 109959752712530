import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ADD_CONTROLLING_PERSON,
  ADD_FATCA,
  DELETE_CONTROLLING_PERSON,
  DELETE_FATCA_ENDPOINT,
  UPDATE_CONTROLLING_PERSON,
  UPDATE_FATCA,
} from '../../core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FatcaService {
  subCategory = new Subject();
  entityExceptionCode = new Subject();
  activesubCategory = new Subject();
  constructor(private http: HttpClient) { }

  deleteFatca(fatctaDetailId: string) {
    const url = environment.APIURL + DELETE_FATCA_ENDPOINT + `/${fatctaDetailId}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }

  addFatca(body) {
    const url = environment.APIURL + ADD_FATCA;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  updateFatca(body) {
    const url = environment.APIURL + UPDATE_FATCA;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  addControllingPerson(body) {
    const url = environment.APIURL + ADD_CONTROLLING_PERSON;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  updateControllingPerson(body) {
    const url = environment.APIURL + UPDATE_CONTROLLING_PERSON;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    body.uuid = body.uuid.toString();
    return this.http.post(url, body, { headers });
  }

  deleteControllingPerson(uuid: string, applicationuuid: string) {
    const url = environment.APIURL + DELETE_CONTROLLING_PERSON + `/${uuid}/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }
}
