import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BankService } from 'src/app/services/bank/bank.service';
import { UploadDocument } from 'src/app/core/model/application.model';
import { CommonService } from 'src/app/services/common/common.service';
import { ApplicationService } from 'src/app/services/application/application.service';

@Component({
  selector: 'app-amlStatus-modal',
  templateUrl: './amlStatus-modal.component.html',
  styleUrls: ['./amlStatus-modal.component.scss'],
})
export class amlStatusModalComponent implements OnInit {
  paymentStatus: boolean = false;
  @ViewChildren('fileInput') fileInputs: QueryList<
    ElementRef<HTMLInputElement>
  >;
  @Input() applicationDetails: any;
  fileAttr: string = 'Choose file';
  submitted = false;
  paymentModes: any;
  paymentTypes: any;
  accountTypes: any;
  bankDetails: any;
  partialPaymentDetails: any;
  selectedFileBLOB: any;
  fileformatError: boolean = false;
  maxSizError: boolean = false;
  fileEmptyErr: boolean = false;
  roleId: any;
  maxDate = new Date();
  selectedFiles: File[][] = [];
  urlArray: any[][] = [];
  choosedFile: any[];
  PaymentDetails: any;
  fileUploaded: boolean = false;
  paymentFile: File;
  paymentForm = this.fb.group({
    payments: this.fb.array([]),
  });
  showLoader = false;
  headerText = '';
  messageText = '';
  note = 'No';
  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private bankService: BankService,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private applicationService: ApplicationService,
    private readonly toastController: ToastController
  ) { }

  ngOnInit() {
    this.commonService.updateLoader(true);
    this.commonService.encrypt(this.applicationDetails.investmentId).subscribe((res: any) => {
      var encryptedText = res.encryptedText.replaceAll('/', 'slh')
      .replaceAll('--', '==')
      .replaceAll('-', '=');
      this.applicationService
        .getApplicationByUuid(encryptedText)
        .subscribe({
          next: (res: any) => {
            this.applicationDetails = JSON.parse(res.data);
            console.log('this.applicationDetails', this.applicationDetails);
            for (var i = 0; i <= this.applicationDetails.holdersList.length -1; i++) {
              //console.log('Inside Holder');
              if (this.applicationDetails.holdersList[i]?.amlApproved == 'Approved') {
                this.note = 'yes';
              }
              //console.log('Inside holdersList Note', this.note);
            }

            for (var i = 0; i <= this.applicationDetails.beneficiaryList.length -1; i++) {
              //console.log('Inside beneficiaryList');
              //console.log('this.applicationDetails.beneficiaryList[i]?.amlApproved',this.applicationDetails.beneficiaryList[i]?.amlApproved);
              if (this.applicationDetails.beneficiaryList[i]?.amlApproved == 'Approved') {
                console.log('Inside If condition beneficiary');  
                this.note = 'yes';
              }
              //console.log('Inside beneficiaryList Note', this.note);
            }

            for (var i = 0; i <= this.applicationDetails.controllingPersonList.length -1; i++) {
              //console.log('Inside controllingPersonList');
              if (this.applicationDetails.controllingPersonList[i]?.amlApproved == 'Approved') {
                this.note = 'yes';
              }
              ///console.log('Inside controllingPersonList Note', this.note);
            }

            for (var i = 0; i <= this.applicationDetails.uboList.length - 1; i++) {
              //console.log('Inside uboList');
              if (this.applicationDetails.uboList[i]?.amlApproved == 'Approved') {
                this.note = 'yes';
              }
              //console.log('Inside uboList Note', this.note);
            }

            //console.log('Note', this.note);
            this.commonService.updateLoader(false);
          }
        });
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
