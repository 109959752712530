import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, validationType } from '../../core/model/error.model';
import { AddHolder } from '../../core/model/holder.model';
import { CommonService } from '../../services/common/common.service';
import { HolderService } from '../../services/holder/holder.service';
import { ApplicationService } from '../../services/application/application.service';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component';
import { GuardianService } from '../../services/guardian/guardian.service';

@Component({
  selector: 'add-new-guardian',
  templateUrl: './add-new-guardian.component.html',
  styleUrls: ['./add-new-guardian.component.scss'],
})
export class AddNewGuardianComponent implements OnInit {
  holderForm: FormGroup;
  error = Errors;
  showLoader = false;
  headerText = ''; //HEADER_TEXT_PANCHECK;
  messageText = ''; //MESSAGE_TEXT_PANCHECK;
  todayDate = new Date();
  application: any;
  applicantUuid: string;
  holderType:string;
  @Input() applicationUuid: string;
  @Output() add: EventEmitter<any> = new EventEmitter();
  constructor(
    private modalController: ModalController,
    private holderService: HolderService,
    private loadingController: LoadingController,
    private commonService: CommonService,
    private toastController: ToastController,
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private guardianService: GuardianService
  ) { }

  ngOnInit() {
    this.createForm();
    console.log('Hii Inside add applicant');
    this.getApplication();
    //console.log('Hii',this.applicationUuid);
  }

  get form() {
    return this.holderForm.controls;
  }

  createForm() {
    this.holderForm = new FormGroup({
      pan: new FormControl('', validationType.PAN),
      dob: new FormControl('', Validators.required),
      POA: new FormControl(false),
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  // async submit() {
    
  //   this.holderForm.markAllAsTouched();
  //   if (this.holderForm.invalid) return;
  //   if (this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
  //     this.form.pan.setErrors({ inProper: true });
  //     return;
  //   }


  //   const currentDate = new Date();
  //   const dobT = moment(this.form.dob.value);
  //   const age = dobT.diff(currentDate, 'years');
  //   if (-age < 18) {
  //     this.form.dob.setErrors({ invalidError: true });
  //     return;
  //   }
  //  // this.ageCheck();
  //  // return;
  //   let payload = {} as AddHolder;
  //   payload.applicationUuid = this.applicationUuid;
  //   payload.pan = this.form.pan.value;

  //   //Dateofbirth
  //   payload.dob = this.form.dob.value;
  //   let newDate = new Date(payload.dob);
  //   newDate.setDate(newDate.getDate())
  //   let formatedDate = formatDate(newDate, 'dd-MM-yyyy', 'en');
  //   payload.dob = formatedDate;

  //   payload.POA = this.form.POA.value;
  //   this.showLoader = true;
  //   this.headerText = 'Check CKYC';
  //   this.messageText = 'Please wait, Verifying CKYC and AML';

  //   var reqBody;
  //   reqBody = {
  //     PAN: this.form.pan.value,
  //     DOB: payload.dob
  //   }


  //   this.applicationService.getCKYC(reqBody).subscribe(
  //     async (res: any) => {
  //       this.showLoader = false;

  //       if (res.code == 200) {
  //         this.dismissModal();
  //         // window.location.reload();

  //         this.showLoader = false;
  //         console.log('CKYC Response',res);
  //         this.showLoader = false;
  //         let data = {} as any;
  //         var datares=res.value;
  //         data.applicationUuId = this.applicationUuid.toString();
  //         data.dob=payload.dob;
  //         data.pan=this.form.pan.value;

  //         if (res.code == 400) {
  //           var ckycDetail = {
  //             "PAN":this.form.pan.value,
  //             "DateOfBirth":payload.dob
  //           }
  
  //           data.ckycStatus = "error";
  //           data.ckycDetail = JSON.stringify(ckycDetail);
  //         }
  //         else {
           
  //           if(datares.status == "ERROR"){
  //             data.ckycStatus = "error";
  //             data.ckycDetail = JSON.stringify(ckycDetail);
  //           }
  //           else{
  //             data.ckycStatus = "SUCCESS";
  //             if (datares.data.imagE_DETAILS && datares.data.imagE_DETAILS.image) {
  //               datares.data.imagE_DETAILS.image.forEach(image => {
  //                 image.imagE_DATA = ''; // Set imagE_DATA to an empty string
  //               });
  //             }
  //             data.ckycDetail = JSON.stringify(datares.data);
  //           }     
  //         }
  
  //         console.log('Request Before creation call',data);

  //       //  return;
  //       await  this.guardianService.addGuardian(data).subscribe(
  //         //  this.holderService.addHolder(payload).subscribe(
  //     async (res: any) => {

  //       console.log("Second Holder Response", res)
  //       this.showLoader = false;
  //       var response = JSON.parse(res.data);
  //       if (response.ckycCheck === 0) {
  //         this.dismissModal();
  //         const modal = await this.modalController.create({
  //           component: nonkycModelComponent,
  //           backdropDismiss: false,
  //           cssClass: 'my-custom-class',
  //           componentProps: {
  //             holder: response,
  //             onPanCheck:false
  //           }
  //         });
  //         await modal.present();
  //         modal.onDidDismiss().then((event) => { });
  //         return;
  //       }
  //       else {
  //         this.commonService.updateHolder(response);
  //         this.add.emit();
  //       }
       

  //     },
  //     async (err) => {
  //       this.showLoader = false;
  //       const toast = await this.toastController.create({
  //         message: err.error.message,
  //         duration: 2000,
  //       });
  //       toast.present();
  //     }
  //   );
  //       }
  //       else {
  //         this.dismissModal();
       
  //       }
  //       this.commonService.updateLoader(false);
  //     },
  //     async (err) => {
  //       this.showLoader = false;
  //       const toast = await this.toastController.create({
  //         message: err.error.message,
  //         duration: 2000,
  //       });
  //       toast.present();
  //     }

  //   );

  //   // this.holderService.addHolder(payload).subscribe(
  //   //   async (res: any) => {

  //   //     console.log("Second Holder Response", res)
  //   //     this.showLoader = false;
  //   //     var response = JSON.parse(res.data);
  //   //     if (response.ckycCheck === 0) {
  //   //       this.dismissModal();
  //   //       const modal = await this.modalController.create({
  //   //         component: nonkycModelComponent,
  //   //         backdropDismiss: false,
  //   //         cssClass: 'my-custom-class',
  //   //         componentProps: {
  //   //           holder: response,
  //   //           onPanCheck:false
  //   //         }
  //   //       });
  //   //       await modal.present();
  //   //       modal.onDidDismiss().then((event) => { });
  //   //       return;
  //   //     }
  //   //     else {
  //   //       this.commonService.updateHolder(response);
  //   //       this.add.emit();
  //   //     }
       

  //   //   },
  //   //   async (err) => {
  //   //     this.showLoader = false;
  //   //     const toast = await this.toastController.create({
  //   //       message: err.error.message,
  //   //       duration: 2000,
  //   //     });
  //   //     toast.present();
  //   //   }
  //   // );
  // }



  async submit() {
    
    this.holderForm.markAllAsTouched();
    if (this.holderForm.invalid) return;
    if (this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
      this.form.pan.setErrors({ inProper: true });
      return;
    }

    const currentDate = new Date();
    const dobT = moment(this.form.dob.value);
    const age = dobT.diff(currentDate, 'years');
    if (-age < 18) {
      this.form.dob.setErrors({ invalidError: true });
      return;
    }
   // this.ageCheck();
   // return;
    let payload = {} as AddHolder;
    payload.applicationUuid = this.applicationUuid;
    payload.pan = this.form.pan.value;

    //Dateofbirth
    payload.dob = this.form.dob.value;
    let newDate = new Date(payload.dob);
    newDate.setDate(newDate.getDate())
    let formatedDate = formatDate(newDate, 'yyyy-MM-dd', 'en');
    payload.dob = formatedDate;

    payload.POA = this.form.POA.value;
    this.showLoader = true;
    this.headerText = 'Check CKYC';
    this.messageText = 'Please wait, Verifying CKYC and AML';
    this.guardianService.addGuardian(payload).subscribe(
      async (res: any) => {

        console.log("Second Holder Response", res)
        this.showLoader = false;
        var response = JSON.parse(res.data);
        if (response.ckycCheck === 0) {
          this.dismissModal();
          const modal = await this.modalController.create({
            component: nonkycModelComponent,
            backdropDismiss: false,
            cssClass: 'my-custom-class',
            componentProps: {
              holder: response,
              onPanCheck:false
            }
          });
          await modal.present();
          modal.onDidDismiss().then((event) => { });
          return;
        }
        else {
          this.commonService.updateHolder(response);
          this.add.emit();
        }
       
        // if (
        //   res.Message === 'AML not verified' ||
        //   res.Message === 'Something went wrong at CKYC'
        // ) {
        //   this.dismissModal();
        //   const modal = await this.modalController.create({
        //     component: OopsModalComponent,
        //     backdropDismiss: false,
        //     cssClass: 'my-custom-class',
        //   });
        //   await modal.present();
        //   modal.onDidDismiss().then((event) => { });

        //   return;
        // } else {
        //   this.commonService.updateHolder(res);

        //   this.add.emit();
        // }

      },
      async (err) => {
        this.showLoader = false;
        const toast = await this.toastController.create({
          message: err.error.message,
          duration: 2000,
        });
        toast.present();
      }
    );
  }


  getApplication() {
    this.applicationService.getApplicationByUuid(this.applicationUuid).subscribe(
      (res: any) => {
        this.application = JSON.parse(res.data);
        console.log('Type Of Investor 111', this.application);
        console.log('Type Of Investor ', this.application.typeOfInvestor);
        this.form.POA.setValue(false);
      },
      (err) => {
        //this.commonService.updateLoader(false);
      }
    );
  }


  ageCheck() {
    const currentDate = moment(new Date(), 'DD-MM-YYYY');
    const dob = moment(this.form.dob.value, 'DD-MM-YYYY');
    const age = dob.diff(currentDate, 'years');
    if (-age < 18) {
      this.holderType = 'minor';
    } else {
      this.holderType = 'major';
    }
    if (this.application.typeOfInvestor !== 'Individual') {
      this.holderType = 'major';
    }
  }

}
