import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GET_COMPENSATORY_CONTRIBUTION,
  GET_COMPENSATORY_PERCENTAGE,
  GET_INVESTMENT_CLASS,
  INVESTMENT_ENDPOINT,
  DELETE_INVESTMENT,
} from '../../core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';
import { Investment } from '../../core/model/investment.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvestmentService {
  constructor(private readonly http: HttpClient) { }

  createInvestment(body: Investment) {
    const url = environment.APIURL + INVESTMENT_ENDPOINT;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  updateInvestment(body: Investment) {
    const url = environment.APIURL + INVESTMENT_ENDPOINT;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.put(url, body, { headers });
  }

  fetchCompensatoryPercentage(fundId) {
    const url = environment.APIURL + GET_COMPENSATORY_PERCENTAGE + fundId;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  getInvestmentClass(FundId, InvestmentAmount, setupfee) {
    const url = environment.APIURL + GET_INVESTMENT_CLASS + FundId + '/' + InvestmentAmount + '/' + setupfee;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);

    return this.http.get(url, { headers });
  }

  getCompensatoryContribution(fundId) {
    const url = environment.APIURL + GET_COMPENSATORY_CONTRIBUTION + fundId;
    let headers = {} as any;

    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  deleteInvestment(applicantUuid, uuid, panchecktime = false, isBeneficiary = false, BeneficiaryTypeNo = 0) {
    const url = environment.APIURL + DELETE_INVESTMENT + `/${applicantUuid}/${uuid}/${panchecktime}/${isBeneficiary}/${BeneficiaryTypeNo}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }
}
