import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { ManageTransactionsErrorMessage } from '../../core/model/dashboard.model';
// import { OopsModalDisplayTypes } from '../../core/constants/enum';
// import { BankNctError } from '../../core/model/bank.model';

@Component({
  selector: 'app-oops-modal',
  templateUrl: './oops-modal.component.html',
  styleUrls: ['./oops-modal.component.scss'],
})
export class OopsModalComponent implements OnInit {
  @Input() soaDocsNotFound: boolean;
  constructor(public modalController: ModalController) {}

  formLink =
    'https://www.edelweissmf.com/Files/downloads/Forms/Service-Request-forms/Edelweiss_Annexure_1_to_AMFI_BP_Cir_no.68-CKYC_KRA_KYC_Form.pdf';
  nonIndividualFormLink =
    'https://www.edelweissmf.com/Files/downloads/Forms/Service-Request-forms/Change%20in%20Personal%20Details/Edelweiss_Registering_Adding_Multiple_Bank_Accounts_Form_Editable_Oct.2019.pdf';
  locateUsFormLink = 'https://www.edelweissmf.com/reach-us/locate-us';

  ngOnInit() {}

  async dismissModal() {
    const modal = await this.modalController.dismiss({
      dismissed: true,
    });
  }
}
