import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  GET_Applications,
} from '../../core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';

@Injectable({
  providedIn: 'root',
})
export class microfrontendService {
  constructor(private readonly http: HttpClient) { }

  getApplications(encstring) {
    const url = environment.APIURL + GET_Applications + `/${encstring}`;
    const body = {};
    const headers = {} as any;
    return this.http.get(url);
  }


}
