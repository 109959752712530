import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  TRIGGER_OTP,
  VERIFY_OTP,
  VERIFY_OTP_CKYC,
} from '../../core/constants/api-endpoints';
import { environment } from '../../../environments/environment';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';

@Injectable({
  providedIn: 'root',
})
export class OtpService {
  constructor(private http: HttpClient) {}

  generateOTP(body) {
    const url = environment.APIURL + TRIGGER_OTP;
    let headers = {} as any;
    return this.http.post(url, body);
  }

  verifyOTP(body) {
    const url = environment.APIURL + VERIFY_OTP;
    let headers = {} as any;
    return this.http.post(url, body);
  }

  verifyOTPCKYC(body) {
    const url = environment.APIURL + VERIFY_OTP_CKYC; 
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }
}
