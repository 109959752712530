import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ADD_UBO, DELETE_UBO, UPDATE_UBO } from '../core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../core/constants/app-strings';


@Injectable({
  providedIn: 'root',
})
export class UboService {
  constructor(private http: HttpClient) { }

  AddUBO(body) {
    const url = environment.APIURL + ADD_UBO;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  editUBO(body) {
    const url = environment.APIURL + UPDATE_UBO;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  deleteUBO(uuid: string, applicationuuid: string) {
    const url = environment.APIURL + DELETE_UBO + `/${uuid}/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }
}
