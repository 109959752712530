import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { BeneficiaryType, BeneficiaryTypeNo, ErrorMessage } from 'src/app/core/constants/enum';
import { Errors, validationType } from 'src/app/core/model/error.model';
import { BeneficiaryService } from 'src/app/services/beneficiary/beneficiary.service';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { Router } from '@angular/router';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component';
import { OfflinepopupComponent } from '../offline-popup/offline-popup.component'
import { InvestmentService } from 'src/app/services/investment/investment.service';
import { ApplicationService } from 'src/app/services/application/application.service';
import { HolderService } from 'src/app/services/holder/holder.service';

@Component({
  selector: 'app-add-AMLComments',
  templateUrl: './add-AMLComments.component.html',
  styleUrls: ['./add-AMLComments.component.scss'],
})
export class AddAMLCommentsComponent implements OnInit {
  AMLForm: FormGroup;
  error = Errors;
  todayDate = new Date();
  showLoader: boolean = false;
  @Input() application: any;
  @Input() beneficiaryType: string;
  BeneficiaryType = BeneficiaryType;
  clipboardValue = 'Angular copy text demo';
  constructor(
    private modalController: ModalController,
    private readonly beneficiaryService: BeneficiaryService,
    private toastController: ToastController,
    private router: Router,
    private readonly applicationService: ApplicationService,
    private investmentService: InvestmentService,
    private readonly holderService: HolderService,
  ) { }

  ngOnInit() {
    this.createForm();
    console.log("application details", this.application);
    for (var i = 0; i <= this.application.uboList.length; i++) {
      console.log("uboList list", this.application.uboList[i]);
    }
  }

  get form() {
    return this.AMLForm.controls;
  }

  createForm() {
    this.AMLForm = new FormGroup({
      applicant1Comment: new FormControl('', Validators.required),
      applicant2Comment: new FormControl('', Validators.required),
      beneficiary1Comment: new FormControl('', Validators.required),
      beneficiary2Comment: new FormControl('', Validators.required),
      beneficiary3Comment: new FormControl('', Validators.required),
      beneficiary4Comment: new FormControl('', Validators.required),
      beneficiary5Comment: new FormControl('', Validators.required),
      beneficiary6Comment: new FormControl('', Validators.required),
      controllingPerson1Comment: new FormControl('', Validators.required),
      controllingPerson2Comment: new FormControl('', Validators.required),
      controllingPerson3Comment: new FormControl('', Validators.required),
      controllingPerson4Comment: new FormControl('', Validators.required),
      controllingPerson5Comment: new FormControl('', Validators.required),
      controllingPerson6Comment: new FormControl('', Validators.required),
      uboList1Comment: new FormControl('', Validators.required),
      uboList2Comment: new FormControl('', Validators.required),
      uboList3Comment: new FormControl('', Validators.required),
      uboList4Comment: new FormControl('', Validators.required)
    });
  }

  async submit() {
    //console.log('this.application.controllingPersonList[0].sameAsBeneficiary',this.application.controllingPersonList[0].isControllingPersonSameAsBeneficiary);
    // console.log('this.application.holdersList.length', this.application.holdersList.length);
    if (this.application.typeOfInvestor == 'Individual') {

      this.form.beneficiary1Comment.setValidators(null);
      this.form.beneficiary1Comment.updateValueAndValidity();
      this.form.beneficiary2Comment.setValidators(null);
      this.form.beneficiary2Comment.updateValueAndValidity();
      this.form.beneficiary3Comment.setValidators(null);
      this.form.beneficiary3Comment.updateValueAndValidity();
      this.form.beneficiary4Comment.setValidators(null);
      this.form.beneficiary4Comment.updateValueAndValidity();
      this.form.beneficiary5Comment.setValidators(null);
      this.form.beneficiary5Comment.updateValueAndValidity();
      this.form.beneficiary6Comment.setValidators(null);
      this.form.beneficiary6Comment.updateValueAndValidity();

      this.form.controllingPerson1Comment.setValidators(null);
      this.form.controllingPerson1Comment.updateValueAndValidity();
      this.form.controllingPerson2Comment.setValidators(null);
      this.form.controllingPerson2Comment.updateValueAndValidity();
      this.form.controllingPerson3Comment.setValidators(null);
      this.form.controllingPerson3Comment.updateValueAndValidity();
      this.form.controllingPerson4Comment.setValidators(null);
      this.form.controllingPerson4Comment.updateValueAndValidity();
      this.form.controllingPerson5Comment.setValidators(null);
      this.form.controllingPerson5Comment.updateValueAndValidity();
      this.form.controllingPerson6Comment.setValidators(null);
      this.form.controllingPerson6Comment.updateValueAndValidity();

      this.form.uboList1Comment.setValidators(null);
      this.form.uboList1Comment.updateValueAndValidity();
      this.form.uboList2Comment.setValidators(null);
      this.form.uboList2Comment.updateValueAndValidity();
      this.form.uboList3Comment.setValidators(null);
      this.form.uboList3Comment.updateValueAndValidity();
      this.form.uboList4Comment.setValidators(null);
      this.form.uboList4Comment.updateValueAndValidity();



      if (this.application.holdersList.length == 1) {
        this.form.applicant2Comment.setValidators(null);
        this.form.applicant2Comment.updateValueAndValidity();
      }

      if (this.application.holdersList.length == 2) {
        if (this.application.holdersList[0].amlCheck != true) {
          this.form.applicant1Comment.setValidators(null);
          this.form.applicant1Comment.updateValueAndValidity();
        }
        if (this.application.holdersList[1].amlCheck != true) {
          this.form.applicant2Comment.setValidators(null);
          this.form.applicant2Comment.updateValueAndValidity();
        }

      }

    }

    if (this.application.typeOfInvestor == 'Non-Individual') {
      this.form.applicant2Comment.setValidators(null);
      this.form.applicant2Comment.updateValueAndValidity();

      if (this.application.holdersList[0].amlCheck != true) {
        this.form.applicant1Comment.setValidators(null);
        this.form.applicant1Comment.updateValueAndValidity();
      }

      if (this.application.holdersList[0].amlCheck == true && this.application.holdersList[0].amlApprovedBy) {
        this.form.applicant1Comment.setValidators(null);
        this.form.applicant1Comment.updateValueAndValidity();
      }

      for (var i = 0; i <= this.application.beneficiaryList.length - 1; i++) {
        if (this.application.beneficiaryList[i].amlCheck == false) {
          if (i == 0) {
            this.form.beneficiary1Comment.setValidators(null);
            this.form.beneficiary1Comment.updateValueAndValidity();
          }
          if (i == 1) {
            this.form.beneficiary2Comment.setValidators(null);
            this.form.beneficiary2Comment.updateValueAndValidity();
          }
          if (i == 2) {
            this.form.beneficiary3Comment.setValidators(null);
            this.form.beneficiary3Comment.updateValueAndValidity();
          }
          if (i == 3) {
            this.form.beneficiary4Comment.setValidators(null);
            this.form.beneficiary4Comment.updateValueAndValidity();
          }
          if (i == 4) {
            this.form.beneficiary5Comment.setValidators(null);
            this.form.beneficiary5Comment.updateValueAndValidity();
          }
          if (i == 5) {
            this.form.beneficiary6Comment.setValidators(null);
            this.form.beneficiary6Comment.updateValueAndValidity();
          }
        }
      }

      if (this.application.beneficiaryList.length == 0) {
        this.form.beneficiary1Comment.setValidators(null);
        this.form.beneficiary1Comment.updateValueAndValidity();
        this.form.beneficiary2Comment.setValidators(null);
        this.form.beneficiary2Comment.updateValueAndValidity();
        this.form.beneficiary3Comment.setValidators(null);
        this.form.beneficiary3Comment.updateValueAndValidity();
        this.form.beneficiary4Comment.setValidators(null);
        this.form.beneficiary4Comment.updateValueAndValidity();
        this.form.beneficiary5Comment.setValidators(null);
        this.form.beneficiary5Comment.updateValueAndValidity();
        this.form.beneficiary6Comment.setValidators(null);
        this.form.beneficiary6Comment.updateValueAndValidity();
      }

      if (this.application.beneficiaryList.length == 1) {
        this.form.beneficiary2Comment.setValidators(null);
        this.form.beneficiary2Comment.updateValueAndValidity();
        this.form.beneficiary3Comment.setValidators(null);
        this.form.beneficiary3Comment.updateValueAndValidity();
        this.form.beneficiary4Comment.setValidators(null);
        this.form.beneficiary4Comment.updateValueAndValidity();
        this.form.beneficiary5Comment.setValidators(null);
        this.form.beneficiary5Comment.updateValueAndValidity();
        this.form.beneficiary6Comment.setValidators(null);
        this.form.beneficiary6Comment.updateValueAndValidity();
      }

      if (this.application.beneficiaryList.length == 2) {
        this.form.beneficiary3Comment.setValidators(null);
        this.form.beneficiary3Comment.updateValueAndValidity();
        this.form.beneficiary4Comment.setValidators(null);
        this.form.beneficiary4Comment.updateValueAndValidity();
        this.form.beneficiary5Comment.setValidators(null);
        this.form.beneficiary5Comment.updateValueAndValidity();
        this.form.beneficiary6Comment.setValidators(null);
        this.form.beneficiary6Comment.updateValueAndValidity();
      }

      if (this.application.beneficiaryList.length == 3) {
        this.form.beneficiary4Comment.setValidators(null);
        this.form.beneficiary4Comment.updateValueAndValidity();
        this.form.beneficiary5Comment.setValidators(null);
        this.form.beneficiary5Comment.updateValueAndValidity();
        this.form.beneficiary6Comment.setValidators(null);
        this.form.beneficiary6Comment.updateValueAndValidity();
      }

      if (this.application.beneficiaryList.length == 4) {
        this.form.beneficiary5Comment.setValidators(null);
        this.form.beneficiary5Comment.updateValueAndValidity();
        this.form.beneficiary6Comment.setValidators(null);
        this.form.beneficiary6Comment.updateValueAndValidity();
      }

      if (this.application.beneficiaryList.length == 5) {
        this.form.beneficiary6Comment.setValidators(null);
        this.form.beneficiary6Comment.updateValueAndValidity();
      }

      for (var i = 0; i <= this.application.controllingPersonList.length - 1; i++) {
        if (this.application.controllingPersonList[i].amlCheck == false) {
          if (i == 0) {
            this.form.controllingPerson1Comment.setValidators(null);
            this.form.controllingPerson1Comment.updateValueAndValidity();
          }
          if (i == 1) {
            this.form.controllingPerson2Comment.setValidators(null);
            this.form.controllingPerson2Comment.updateValueAndValidity();
          }
          if (i == 2) {
            this.form.controllingPerson3Comment.setValidators(null);
            this.form.controllingPerson3Comment.updateValueAndValidity();
          }
          if (i == 3) {
            this.form.controllingPerson4Comment.setValidators(null);
            this.form.controllingPerson4Comment.updateValueAndValidity();
          }
          if (i == 4) {
            this.form.controllingPerson5Comment.setValidators(null);
            this.form.controllingPerson5Comment.updateValueAndValidity();
          }
          if (i == 5) {
            this.form.controllingPerson6Comment.setValidators(null);
            this.form.controllingPerson6Comment.updateValueAndValidity();
          }
        }
      }

      if (this.application.controllingPersonList.length == 0) {
        this.form.controllingPerson1Comment.setValidators(null);
        this.form.controllingPerson1Comment.updateValueAndValidity();
        this.form.controllingPerson2Comment.setValidators(null);
        this.form.controllingPerson2Comment.updateValueAndValidity();
        this.form.controllingPerson3Comment.setValidators(null);
        this.form.controllingPerson3Comment.updateValueAndValidity();
        this.form.controllingPerson4Comment.setValidators(null);
        this.form.controllingPerson4Comment.updateValueAndValidity();
        this.form.controllingPerson5Comment.setValidators(null);
        this.form.controllingPerson5Comment.updateValueAndValidity();
        this.form.controllingPerson6Comment.setValidators(null);
        this.form.controllingPerson6Comment.updateValueAndValidity();
      }

      if (this.application.controllingPersonList.length == 1) {

        if (this.application.controllingPersonList[0].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson1Comment.setValidators(null);
          this.form.controllingPerson1Comment.updateValueAndValidity();
        }

        this.form.controllingPerson2Comment.setValidators(null);
        this.form.controllingPerson2Comment.updateValueAndValidity();
        this.form.controllingPerson3Comment.setValidators(null);
        this.form.controllingPerson3Comment.updateValueAndValidity();
        this.form.controllingPerson4Comment.setValidators(null);
        this.form.controllingPerson4Comment.updateValueAndValidity();
        this.form.controllingPerson5Comment.setValidators(null);
        this.form.controllingPerson5Comment.updateValueAndValidity();
        this.form.controllingPerson6Comment.setValidators(null);
        this.form.controllingPerson6Comment.updateValueAndValidity();
      }

      if (this.application.controllingPersonList.length == 2) {
        this.form.controllingPerson3Comment.setValidators(null);
        this.form.controllingPerson3Comment.updateValueAndValidity();
        this.form.controllingPerson4Comment.setValidators(null);
        this.form.controllingPerson4Comment.updateValueAndValidity();
        this.form.controllingPerson5Comment.setValidators(null);
        this.form.controllingPerson5Comment.updateValueAndValidity();
        this.form.controllingPerson6Comment.setValidators(null);
        this.form.controllingPerson6Comment.updateValueAndValidity();

        if (this.application.controllingPersonList[0].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson1Comment.setValidators(null);
          this.form.controllingPerson1Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[1].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson2Comment.setValidators(null);
          this.form.controllingPerson2Comment.updateValueAndValidity();
        }

      }

      if (this.application.controllingPersonList.length == 3) {
        this.form.controllingPerson4Comment.setValidators(null);
        this.form.controllingPerson4Comment.updateValueAndValidity();
        this.form.controllingPerson5Comment.setValidators(null);
        this.form.controllingPerson5Comment.updateValueAndValidity();
        this.form.controllingPerson6Comment.setValidators(null);
        this.form.controllingPerson6Comment.updateValueAndValidity();

        if (this.application.controllingPersonList[0].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson1Comment.setValidators(null);
          this.form.controllingPerson1Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[1].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson2Comment.setValidators(null);
          this.form.controllingPerson2Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[2].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson3Comment.setValidators(null);
          this.form.controllingPerson3Comment.updateValueAndValidity();
        }

      }

      if (this.application.controllingPersonList.length == 4) {
        this.form.controllingPerson5Comment.setValidators(null);
        this.form.controllingPerson5Comment.updateValueAndValidity();
        this.form.controllingPerson6Comment.setValidators(null);
        this.form.controllingPerson6Comment.updateValueAndValidity();

        if (this.application.controllingPersonList[0].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson1Comment.setValidators(null);
          this.form.controllingPerson1Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[1].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson2Comment.setValidators(null);
          this.form.controllingPerson2Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[2].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson3Comment.setValidators(null);
          this.form.controllingPerson3Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[3].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson4Comment.setValidators(null);
          this.form.controllingPerson4Comment.updateValueAndValidity();
        }
      }

      if (this.application.controllingPersonList.length == 5) {
        this.form.controllingPerson6Comment.setValidators(null);
        this.form.controllingPerson6Comment.updateValueAndValidity();

        if (this.application.controllingPersonList[0].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson1Comment.setValidators(null);
          this.form.controllingPerson1Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[1].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson2Comment.setValidators(null);
          this.form.controllingPerson2Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[2].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson3Comment.setValidators(null);
          this.form.controllingPerson3Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[3].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson4Comment.setValidators(null);
          this.form.controllingPerson4Comment.updateValueAndValidity();
        }

        if (this.application.controllingPersonList[4].isControllingPersonSameAsBeneficiary == "yes") {
          this.form.controllingPerson5Comment.setValidators(null);
          this.form.controllingPerson5Comment.updateValueAndValidity();
        }
      }


      for (var i = 0; i <= this.application.uboList.length - 1; i++) {
        console.log('this.application.uboList[i].amlCheck', this.application.uboList[i].amlCheck);
        if (this.application.uboList[i].amlCheck == false) {
          if (i == 0) {
            console.log('Inside zero');
            this.form.uboList1Comment.setValidators(null);
            this.form.uboList1Comment.updateValueAndValidity();
          }
          if (i == 1) {
            this.form.uboList2Comment.setValidators(null);
            this.form.uboList2Comment.updateValueAndValidity();
          }
          if (i == 2) {
            this.form.uboList3Comment.setValidators(null);
            this.form.uboList3Comment.updateValueAndValidity();
          }
          if (i == 3) {
            this.form.uboList4Comment.setValidators(null);
            this.form.uboList4Comment.updateValueAndValidity();
          }
        }
      }

      if (this.application.uboList.length == 0) {
        this.form.uboList1Comment.setValidators(null);
        this.form.uboList1Comment.updateValueAndValidity();
        this.form.uboList2Comment.setValidators(null);
        this.form.uboList2Comment.updateValueAndValidity();
        this.form.uboList3Comment.setValidators(null);
        this.form.uboList3Comment.updateValueAndValidity();
        this.form.uboList4Comment.setValidators(null);
        this.form.uboList4Comment.updateValueAndValidity();
      }

      if (this.application.uboList.length == 1) {
        this.form.uboList2Comment.setValidators(null);
        this.form.uboList2Comment.updateValueAndValidity();
        this.form.uboList3Comment.setValidators(null);
        this.form.uboList3Comment.updateValueAndValidity();
        this.form.uboList4Comment.setValidators(null);
        this.form.uboList4Comment.updateValueAndValidity();

        if (this.application.uboList[0].sameAsBeneficiary == "yes") {
          this.form.uboList1Comment.setValidators(null);
          this.form.uboList1Comment.updateValueAndValidity();
        }

      }

      if (this.application.uboList.length == 2) {

        if (this.application.uboList[0].sameAsBeneficiary == "yes") {
          this.form.uboList1Comment.setValidators(null);
          this.form.uboList1Comment.updateValueAndValidity();
        }

        if (this.application.uboList[1].sameAsBeneficiary == "yes") {
          this.form.uboList2Comment.setValidators(null);
          this.form.uboList2Comment.updateValueAndValidity();
        }

        this.form.uboList3Comment.setValidators(null);
        this.form.uboList3Comment.updateValueAndValidity();
        this.form.uboList4Comment.setValidators(null);
        this.form.uboList4Comment.updateValueAndValidity();
      }

      if (this.application.uboList.length == 3) {

        if (this.application.uboList[0].sameAsBeneficiary == "yes") {
          this.form.uboList1Comment.setValidators(null);
          this.form.uboList1Comment.updateValueAndValidity();
        }

        if (this.application.uboList[1].sameAsBeneficiary == "yes") {
          this.form.uboList2Comment.setValidators(null);
          this.form.uboList2Comment.updateValueAndValidity();
        }

        if (this.application.uboList[2].sameAsBeneficiary == "yes") {
          this.form.uboList3Comment.setValidators(null);
          this.form.uboList3Comment.updateValueAndValidity();
        }

        this.form.uboList4Comment.setValidators(null);
        this.form.uboList4Comment.updateValueAndValidity();
      }
    }

    const controls = this.AMLForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log("Required Fields ", name);
      }
    }

    if (this.AMLForm.invalid)
      return;

    let payload = {} as any;

    payload.AMLComments_A1 = this.form.applicant1Comment.value;
    payload.Applicant1 = this.application.holdersList[0].investorId.toString();

    payload.AMLComments_A2 = (this.application.holdersList[1]?.amlCheck == true && !this.application.holdersList[1]?.amlApprovedBy) ? this.form.applicant2Comment.value : "";
    payload.Applicant2 = (this.application.holdersList[1]?.amlCheck == true && !this.application.holdersList[1]?.amlApprovedBy) ? this.application.holdersList[1]?.investorId.toString() : "";

    payload.AMLComments_B1 = (this.application.beneficiaryList[0]?.amlCheck == true && !this.application.beneficiaryList[0]?.amlApprovedBy) ? this.form.beneficiary1Comment.value : "";
    payload.Beneficiary1 = (this.application.beneficiaryList[0]?.amlCheck == true && !this.application.beneficiaryList[0]?.amlApprovedBy) ? this.application.beneficiaryList[0]?.investorId.toString() : "";

    payload.AMLComments_B2 = (this.application.beneficiaryList[1]?.amlCheck == true && !this.application.beneficiaryList[1]?.amlApprovedBy) ? this.form.beneficiary2Comment.value : "";
    payload.Beneficiary2 = (this.application.beneficiaryList[1]?.amlCheck == true && !this.application.beneficiaryList[1]?.amlApprovedBy) ? this.application.beneficiaryList[1]?.investorId.toString() : "";

    payload.AMLComments_B3 = (this.application.beneficiaryList[2]?.amlCheck == true && !this.application.beneficiaryList[2]?.amlApprovedBy) ? this.form.beneficiary3Comment.value : "";
    payload.Beneficiary3 = (this.application.beneficiaryList[2]?.amlCheck == true && !this.application.beneficiaryList[2]?.amlApprovedBy) ? this.application.beneficiaryList[2]?.investorId.toString() : "";

    payload.AMLComments_B4 = (this.application.beneficiaryList[3]?.amlCheck == true && !this.application.beneficiaryList[3]?.amlApprovedBy) ? this.form.beneficiary4Comment.value : "";
    payload.Beneficiary4 = (this.application.beneficiaryList[3]?.amlCheck == true && !this.application.beneficiaryList[3]?.amlApprovedBy) ? this.application.beneficiaryList[3]?.investorId.toString() : "";

    payload.AMLComments_B5 = (this.application.beneficiaryList[4]?.amlCheck == true && !this.application.beneficiaryList[4]?.amlApprovedBy) ? this.form.beneficiary5Comment.value : "";
    payload.Beneficiary5 = (this.application.beneficiaryList[4]?.amlCheck == true && !this.application.beneficiaryList[4]?.amlApprovedBy) ? this.application.beneficiaryList[4]?.investorId.toString() : "";

    payload.AMLComments_B6 = (this.application.beneficiaryList[5]?.amlCheck == true && !this.application.beneficiaryList[5]?.amlApprovedBy) ? this.form.beneficiary6Comment.value : "";
    payload.Beneficiary6 = (this.application.beneficiaryList[5]?.amlCheck == true && !this.application.beneficiaryList[5]?.amlApprovedBy) ? this.application.beneficiaryList[5]?.investorId.toString() : "";

    payload.AMLComments_C1 = (this.application.controllingPersonList[0]?.amlCheck == true && !this.application.controllingPersonList[0]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.form.controllingPerson1Comment.value : "";
    payload.Controlling1 = (this.application.controllingPersonList[0]?.amlCheck == true && !this.application.controllingPersonList[0]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.application.controllingPersonList[0]?.investorId.toString() : "";

    payload.AMLComments_C2 = (this.application.controllingPersonList[1]?.amlCheck == true && !this.application.controllingPersonList[1]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.form.controllingPerson2Comment.value : "";
    payload.Controlling2 = (this.application.controllingPersonList[1]?.amlCheck == true && !this.application.controllingPersonList[1]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.application.controllingPersonList[1]?.investorId.toString() : "";

    payload.AMLComments_C3 = (this.application.controllingPersonList[2]?.amlCheck == true && !this.application.controllingPersonList[2]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.form.controllingPerson3Comment.value : "";
    payload.Controlling3 = (this.application.controllingPersonList[2]?.amlCheck == true && !this.application.controllingPersonList[2]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.application.controllingPersonList[2]?.investorId.toString() : "";

    payload.AMLComments_C4 = (this.application.controllingPersonList[3]?.amlCheck == true && !this.application.controllingPersonList[3]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.form.controllingPerson4Comment.value : "";
    payload.Controlling4 = (this.application.controllingPersonList[3]?.amlCheck == true && !this.application.controllingPersonList[3]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.application.controllingPersonList[3]?.investorId.toString() : "";

    payload.AMLComments_C5 = (this.application.controllingPersonList[4]?.amlCheck == true && !this.application.controllingPersonList[4]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.form.controllingPerson5Comment.value : "";
    payload.Controlling5 = (this.application.controllingPersonList[4]?.amlCheck == true && !this.application.controllingPersonList[4]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.application.controllingPersonList[4]?.investorId.toString() : "";

    payload.AMLComments_C6 = (this.application.controllingPersonList[5]?.amlCheck == true && !this.application.controllingPersonList[5]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.form.controllingPerson6Comment.value : "";
    payload.Controlling6 = (this.application.controllingPersonList[5]?.amlCheck == true && !this.application.controllingPersonList[5]?.amlApprovedBy && this.application.controllingPersonList[0]?.isControllingPersonSameAsBeneficiary != 'yes') ? this.application.controllingPersonList[5]?.investorId.toString() : "";

    payload.AMLComments_U1 = (this.application.uboList[0]?.amlCheck == true && !this.application.uboList[0]?.amlApprovedBy && this.application.uboList[0]?.sameAsBeneficiary != 'yes') ? this.form.uboList1Comment.value : "";
    payload.UBO1 = (this.application.uboList[0]?.amlCheck == true && !this.application.uboList[0]?.amlApprovedBy && this.application.uboList[0]?.sameAsBeneficiary != 'yes') ? this.application.uboList[0]?.investorId.toString() : "";

    payload.AMLComments_U2 = (this.application.uboList[1]?.amlCheck == true && !this.application.uboList[1]?.amlApprovedBy && this.application.uboList[1]?.sameAsBeneficiary != 'yes') ? this.form.uboList2Comment.value : "";
    payload.UBO2 = (this.application.uboList[1]?.amlCheck == true && !this.application.uboList[1]?.amlApprovedBy && this.application.uboList[1]?.sameAsBeneficiary != 'yes') ? this.application.uboList[1]?.investorId.toString() : "";

    payload.AMLComments_U3 = (this.application.uboList[2]?.amlCheck == true && !this.application.uboList[2]?.amlApprovedBy && this.application.uboList[2]?.sameAsBeneficiary != 'yes') ? this.form.uboList3Comment.value : "";
    payload.UBO3 = (this.application.uboList[2]?.amlCheck == true && !this.application.uboList[2]?.amlApprovedBy && this.application.uboList[2]?.sameAsBeneficiary != 'yes') ? this.application.uboList[2]?.investorId.toString() : "";

    payload.AMLComments_U4 = (this.application.uboList[3]?.amlCheck == true && !this.application.uboList[3]?.amlApprovedBy && this.application.uboList[3]?.sameAsBeneficiary != 'yes') ? this.form.uboList4Comment.value : "";
    payload.UBO4 = (this.application.uboList[3]?.amlCheck == true && !this.application.uboList[3]?.amlApprovedBy && this.application.uboList[3]?.sameAsBeneficiary != 'yes') ? this.application.uboList[3]?.investorId.toString() : "";

    payload.investmentId = this.application.investmentID;
    this.showLoader = true;
    this.holderService.updateHolderAMLComments(payload).subscribe(
      async (res: any) => {
        this.showLoader = false;
        this.modalController.dismiss({
          data: true,
        });
      });

  }

  dismissModal() {
    this.modalController.dismiss();
  }

  copyText(element,id): void {
    document.getElementById(id).style.display = "inline";
    setTimeout(function () {
      document.getElementById(id).style.display = "none";
    }, 1000);
    navigator.clipboard.writeText(element).catch(() => {
      console.error("Unable to copy text");
    });
  }

}
