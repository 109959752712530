import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BeneficiaryType, ErrorMessage } from 'src/app/core/constants/enum';
import { Application } from 'src/app/core/model/application.model';
import { Errors, validationType } from 'src/app/core/model/error.model';
import { CommonService } from 'src/app/services/common/common.service';
import { UboService } from 'src/app/services/ubo.service';
import { ModalController, ToastController } from '@ionic/angular';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component';
import { OfflinepopupComponent } from '../offline-popup/offline-popup.component';

@Component({
  selector: 'app-add-ubo',
  templateUrl: './add-ubo.component.html',
  styleUrls: ['./add-ubo.component.scss'],
})
export class AddUboComponent {
  @Input() application: any;
  @Input() ubo: any;
  @Input() allocation: any;
  @Input() beneficiaryKind: string;
  isHUF: boolean = false;
  addUBO: FormGroup;
  IsControllingPersonIsBeneficiaryYesOrNoValue = 'yes';
  error = Errors;
  todayDate = new Date();
  showLoader: boolean = false;
  headerText = 'Check CKYC';
  messageText = 'Please wait, Verifying CKYC';
  totalAllocation = 0;
  beneficiaryType = BeneficiaryType;
  minPercnt: string;

  constructor(
    private modalController: ModalController,
    private commonService: CommonService,
    private uboService: UboService,
    private toastController: ToastController
  ) {
    this.createForm();
  }

  ionViewWillEnter() {
    console.log('beneficiaryKind',this.beneficiaryKind);
    if (this.beneficiaryKind == "Karta" || this.beneficiaryKind === "Hindu Undivided Family") {
      this.isHUF = true
    }
    console.log('this.application.beneficiaryList',this.application.beneficiaryList);
    console.log("Filterbenefiary", this.application.beneficiaryList.filter(x => x.typeOfInvestor === "Individual"))


    var filterdList = this.application.beneficiaryList.filter(x => x.typeOfInvestor === "Individual")

    if (!this.ubo) {
      for (let i = 0; i < this.application.uboList.length; i++) {
        for (let b = 0; b < filterdList.length; b++) {
          console.log(this.application.uboList[i].investorId, filterdList[b].investorId)
          if (+this.application.uboList[i].investorId === +filterdList[b].investorId) {
            filterdList.splice(b, 1)
          }
          if (filterdList.length === 0) {
            this.form.sameAsBeneficiary.setValue('no');
            this.form.sameAsBeneficiary.updateValueAndValidity();
          }
        }
      }
      this.application.beneficiaryList = filterdList
    }

    // this.percentageHoldingListerner();
    if (this.ubo) {
      this.prefillUBO();
    }
    if (this.beneficiaryKind) {

      if (this.beneficiaryKind === 'Director/Promoter') {
        // this.minPercnt = '25';
        // this.form.percentageHolding.setValidators([
        //   Validators.required,
        //   Validators.min(25),
        // ]);
        // this.form.percentageHolding.updateValueAndValidity();
      }

      if (this.application.beneficiaryType === 'Trustee' || this.application.beneficiaryType === 'Trust' || this.application.beneficiaryType === 'Partners' || this.application.beneficiaryType === 'Partnership' || this.application.beneficiaryType === 'Partner' || this.application.beneficiaryType === 'LLP') {
        this.minPercnt = '15';
        this.form.percentageHolding.setValidators([Validators.required, Validators.min(15)]);
        this.form.percentageHolding.updateValueAndValidity();
      }

      if (this.application.beneficiaryType === 'Karta' || this.application.beneficiaryType === 'Hindu Undivided Family') {
        this.minPercnt = '100';

        this.form.percentageHolding.setValidators([Validators.required, Validators.min(100)]);
        this.form.percentageHolding.updateValueAndValidity();
      }
    }
  }

  validationBybeneficiaryKind() {
    if (this.beneficiaryKind) {

      if (this.beneficiaryKind === 'Director/Promoter') {
        this.minPercnt = '10';
        this.form.percentageHolding.setValidators([
          Validators.required,
          Validators.min(10),
        ]);
        this.form.percentageHolding.updateValueAndValidity();
      }

      if (this.application.beneficiaryType === 'Trustee' || this.application.beneficiaryType === 'Trust' || this.application.beneficiaryType === 'Partners' || this.application.beneficiaryType === 'Partnership' || this.application.beneficiaryType === 'Partner' || this.application.beneficiaryType === 'LLP') {
        this.minPercnt = '15';
        this.form.percentageHolding.setValidators([Validators.required, Validators.min(15)]);
        this.form.percentageHolding.updateValueAndValidity();
      }

      if (this.application.beneficiaryType === 'Karta' || this.application.beneficiaryType === 'Hindu Undivided Family') {
        this.minPercnt = '100';

        this.form.percentageHolding.setValidators([Validators.required, Validators.min(100)]);
        this.form.percentageHolding.updateValueAndValidity();
      }
    }
  }



  IsControllingPersonIsBeneficiaryYesOrNo(event) {
    this.IsControllingPersonIsBeneficiaryYesOrNoValue = event.value;
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  get form() {
    return this.addUBO.controls;
  }

  createForm() {
    this.addUBO = new FormGroup({
      sameAsBeneficiary: new FormControl('yes'),
      selectBeneficiary: new FormControl('', [Validators.required]),
      beneficiaryTaxResidentAnyOtherCountryIfYes: new FormControl(''),
      // pan: new FormControl(''),
      // dob: new FormControl(''),

      pan: new FormControl('', validationType.PAN),
      dob: new FormControl('', [Validators.required]),
      percentageHolding: new FormControl('', [Validators.required]),
    });
  }

  prefillUBO() {
    if (this.ubo.sameAsBeneficiary === null || this.ubo.sameAsBeneficiary === undefined) {
      this.form.sameAsBeneficiary.setValue('no');
      this.form.sameAsBeneficiary.updateValueAndValidity();
    }
    else {
      this.form.sameAsBeneficiary.setValue(this.ubo.sameAsBeneficiary);
    }
    this.form.pan.setValue(this.ubo.pan);
    this.form.pan.updateValueAndValidity();
    var dateArr = this.ubo.dateOfBirth.split('-');
    var date = dateArr[2].split('T')
    var dat = date[0] + '-' + dateArr[1] + '-' + dateArr[0] + 'T00:00:00.000Z'
    this.form.dob.setValue(dat);
    this.form.dob.updateValueAndValidity();
    this.form.percentageHolding.setValue(this.ubo.percentageHolding);
    this.form.percentageHolding.updateValueAndValidity();

    for (const iterator of this.application?.beneficiaryList) {
      if (+iterator.investorId === +this.ubo.investorId) {
        this.form.selectBeneficiary.setValue(iterator.investorId.toString());
        this.form.selectBeneficiary.updateValueAndValidity();
      }
    }
  }

  onChangeAllocation(e) {
    //  let remainAllocation = 100 - +this.form.percentageHolding.value;
    //  this.totalAllocation = this.allocation;

    // // if (this.form.percentageHolding.value && this.form.percentageHolding.value == 0) {
    // //   this.form.percentageHolding.setErrors({ minimum: true });
    // // }
    // if (
    //   this.form.percentageHolding.value &&
    //   this.form.percentageHolding.value > this.totalAllocation
    // ) {
    //   this.form.percentageHolding.setErrors({ invalid: true });
    // }
  }

  isNumberKey(event) {
    if (this.commonService.isNumberKey(event)) {
      return true;
    } else {
      return false;
    }
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }


  async submit() {



    if (this.form.sameAsBeneficiary.value === "yes") {
      // this.form.percentageHolding.setValidators([Validators.required]);
      // this.form.percentageHolding.setValidators([Validators.required, Validators.min(25),]);
      // this.form.percentageHolding.updateValueAndValidity();
      this.validationBybeneficiaryKind()

      this.form.selectBeneficiary.setValidators([Validators.required]);
      this.form.selectBeneficiary.updateValueAndValidity();


      this.form.pan.clearValidators();
      this.form.pan.updateValueAndValidity();

      this.form.dob.clearValidators();
      this.form.dob.updateValueAndValidity();


    }
    else {

      // this.form.percentageHolding.setValidators([Validators.required]);
      // this.form.percentageHolding.updateValueAndValidity();
    //  this.validationBybeneficiaryKind()

      this.form.selectBeneficiary.clearValidators();
      this.form.selectBeneficiary.updateValueAndValidity();

      this.form.pan.setValidators([Validators.required]);
      this.form.pan.updateValueAndValidity();

      this.form.dob.setValidators([Validators.required]);
      this.form.dob.updateValueAndValidity();

    }

     this.totalAllocation = this.allocation;

     let remainAllocation = 100 - this.allocation;
     var percentageHolding = this.form.percentageHolding.value
     this.totalAllocation = this.allocation;
    if (this.application.childFundId == 'FUND261' &&
      this.form.percentageHolding.value &&
      this.form.percentageHolding.value > this.totalAllocation
    ) {
      this.form.percentageHolding.setErrors({ invalid: true });
      return;
    }

    

    // if (this.ubo?.investorId) {
    //   if (this.application?.uboList.length > 0) {
    //     let data = this.application?.uboList.filter((val) => val.investorId !== this.ubo.investorId);
    //     console.log("Filtered Data   ===", data)
    //     let remaniningPercentage = 100 - (parseFloat(data[0].percentageHolding) + parseFloat(data[1].percentageHolding));

    //     if (remaniningPercentage !== parseFloat(this.form.percentageHolding.value)) {
    //       this.form.percentageHolding.setErrors({ invalid: true });
    //       return;
    //     }
    //   }
    // }

 // Commented for $UBO


    // if (!this.ubo?.investorId) {
    //   if (this.application?.uboList.length === 3) {
    //     let data = this.application?.uboList;
    //     let remaniningPercentage = 100 - (parseFloat(data[0].percentageHolding) + parseFloat(data[1].percentageHolding));
    //     if (remaniningPercentage !== parseFloat(this.form.percentageHolding.value)) {
    //       this.form.percentageHolding.setErrors({ invalid: true });
    //       return;
    //     }

    //   }
    // }

    // if (this.application?.uboList.length === 3) {
    //   console.log('Inside 1');
    //   console.log("this.form.percentageHolding.value", this.form.percentageHolding.value);
    //   let data = this.application?.uboList;
    //   let remaniningPercentage = 100 - (parseFloat(data[0].percentageHolding));
    //   console.log("remaniningPercentage", remaniningPercentage);
    //   if (remaniningPercentage != 0) {
    //     console.log('Inside if');
    //     if (remaniningPercentage < parseFloat(this.form.percentageHolding.value)) {
    //       this.form.percentageHolding.setErrors({ invalid: true });
    //       return;
    //     }
    //   }
    // }

    // if (this.application?.uboList.length === 3) {
    //   console.log("this.form.percentageHolding.value", this.form.percentageHolding.value);
    //   let data = this.application?.uboList;
    //   console.log('UBO 1' + data[0].percentageHolding);
    //   console.log('UBO 2' + data[1].percentageHolding);
    //   let remaniningPercentage = 100 - (parseFloat(data[0].percentageHolding) + parseFloat(data[1].percentageHolding));
    //   console.log("remaniningPercentage", remaniningPercentage);
    //   if (remaniningPercentage != 0) {
    //     if (remaniningPercentage < parseFloat(this.form.percentageHolding.value)) {
    //       this.totalAllocation = remaniningPercentage;
    //       console.log("this.totalAllocation" + this.totalAllocation);
    //       this.form.percentageHolding.setErrors({ invalid: true });
    //       return;
    //     }
    //   }
    // }


    // Commented for $UBO


    // if (this.application?.uboList.length === 3) {
    //   let total = parseFloat(this.application?.uboList[0].percentageHolding) + parseFloat(this.application?.uboList[1].percentageHolding) + parseFloat(this.application?.uboList[3].percentageHolding);

    // }

    // if (this.application?.uboList.length === 2) {
    //   let total = parseFloat(this.application?.uboList[0].percentageHolding) + parseFloat(this.application?.uboList[1].percentageHolding);
    //   let remaining = 100 - total;

    //   if (remaining !== parseFloat(this.form.percentageHolding.value)) {
    //     this.form.percentageHolding.setErrors({ invalid: true });

    //   }
    // }

    // if (
    //   this.form.pan.value &&
    //   this.form.pan.value.charAt(3).toUpperCase() !== 'P'
    // ) {
    //   this.form.pan.setErrors({ inProper: true });
    //   return;
    // }

    this.addUBO.markAllAsTouched();
    if (this.addUBO.invalid) return;
    let body = {} as any;

    if (this.form.pan.value.charAt(3).toUpperCase() !== 'P' && this.form.sameAsBeneficiary.value !== "yes") {
      this.dismissModal();
      const modal = await this.modalController.create({
        component: OfflinepopupComponent,
        backdropDismiss: false,
        cssClass: 'my-custom-class',
        componentProps: {
          errorMessage: ErrorMessage.IndividualError,
          isIndividual: true
        }
      });
      await modal.present();
      await modal.onDidDismiss().then((event) => { });
      return;
    }



    body.sameAsBeneficiary = this.form.sameAsBeneficiary.value;
    body.selectBeneficiary = this.form.selectBeneficiary.value;

    body.pan = this.form.pan.value;
    body.dob = this.form.dob.value;

    if (body.dob !== "") {
      let newDate = new Date(body.dob);
      newDate.setDate(newDate.getDate())
      let formatedDate = formatDate(newDate, 'yyyy-MM-dd', 'en');
      body.dob = formatedDate;
    }

    body.applicationUuid = this.application.investmentID;
    body.percentageHolding = this.form.percentageHolding.value;

    this.showLoader = true;
    if (this.ubo) {
      body.uuid = this.ubo.investorId.toString();
      body.dob = this.getDate(this.form.dob.value);

      if (
        this.ubo.sameAsBeneficiary === null || this.ubo.sameAsBeneficiary === undefined
      ) {
        body.sameAsBeneficiary = null;
      }

      this.uboService.editUBO(body).subscribe(
        (res) => {
          this.showLoader = false;
          this.modalController.dismiss();
        },
        async (err) => {

          this.showLoader = false;
          const toast = await this.toastController.create({
            message: err.error.message,
            duration: 2000,
          });
          toast.present();

        }
      );
    } else {
      this.uboService.AddUBO(body).subscribe(
        async (res: any) => {

          let uboDetail = JSON.parse(res.data)

          console.log("UBO Details  ", res)
          if (body.sameAsBeneficiary == "yes") {
            this.showLoader = false;
            this.modalController.dismiss();
          }
          else {
            if (uboDetail.ckycCheck === 0) {
              this.dismissModal();
              const modal = await this.modalController.create({
                component: nonkycModelComponent,
                backdropDismiss: false,
                cssClass: 'my-custom-class',
                componentProps: {
                  holder: uboDetail,
                  onPanCheck: false,
                  isUBO: true,
                  BeneficiaryTypeNo: 7
                }
              });
              await modal.present();
              modal.onDidDismiss().then((event) => { });
              return;
            }
            else {
              this.commonService.encrypt(this.application.investmentID).subscribe((res: any) => {

                var investmentId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');

                window.location.href = '/application/personal-details/' + investmentId

              });
              //window.location.href = '/application/personal-details/' + this.application.investmentID
              return;
            }
          }
        },
        async (err) => {
          this.showLoader = false;
          const toast = await this.toastController.create({
            message: err.error.message,
            duration: 2000,
          });
          toast.present();

        }
      );
    }
  }

  getDate(dateString: string) {
    const date = new Date(dateString);
    return moment(date).format('DD-MM-YYYY');
  }
}
