import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { BeneficiaryType, BeneficiaryTypeNo, ErrorMessage } from 'src/app/core/constants/enum';
import { Errors, validationType } from 'src/app/core/model/error.model';
import { BeneficiaryService } from 'src/app/services/beneficiary/beneficiary.service';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { Router } from '@angular/router';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component';
import { OfflinepopupComponent } from '../offline-popup/offline-popup.component'
import { InvestmentService } from 'src/app/services/investment/investment.service';
import { ApplicationService } from 'src/app/services/application/application.service';

@Component({
  selector: 'app-add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
  styleUrls: ['./add-beneficiary.component.scss'],
})
export class AddBeneficiaryComponent implements OnInit {
  beneficiaryForm: FormGroup;
  error = Errors;
  todayDate = new Date();
  showLoader: boolean = false;
  headerText = 'Check CKYC';
  messageText = 'Please wait, Verifying CKYC';
  @Input() application: any;
  @Input() beneficiaryType: string;
  BeneficiaryType = BeneficiaryType
  constructor(
    private modalController: ModalController,
    private readonly beneficiaryService: BeneficiaryService,
    private toastController: ToastController,
    private router: Router,
    private readonly applicationService: ApplicationService,
    private investmentService: InvestmentService,
  ) { }

  ngOnInit() {
    this.createForm();
    this.panListerner();
    if (this.application && this.application.beneficiaryType === this.BeneficiaryType.Director_Promoter) {
      this.form.relationship.setValidators(validationType.Required);
      this.form.relationship.updateValueAndValidity();
    }
  }

  get form() {
    return this.beneficiaryForm.controls;
  }

  createForm() {
    this.beneficiaryForm = new FormGroup({
      pan: new FormControl('', validationType.PAN),
      dob: new FormControl('', Validators.required),
      relationship: new FormControl('', Validators.required),
    });
  }

  panListerner() {
    this.form.pan.valueChanges.subscribe((data) => {
      if (data) {
        let pan = this.form.pan.value;
        for (const iterator of this.application.holdersList) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }

        for (const iterator of this.application.nomineeList) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }
        for (const iterator of this.application.beneficiaryList) {
          if (iterator.pan === pan) {
            this.form.pan.setErrors({ duplicate: true });
            break;
          }
        }
      }
    });
  }


  async offlineModelView(message, isIndividual) {
    this.dismissModal();
    const modal = await this.modalController.create({
      component: OfflinepopupComponent,
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      componentProps: {
        errorMessage: message,
        isIndividual: isIndividual
      }
    });
    await modal.present();
    await modal.onDidDismiss().then((event) => { });
  }

  async submit() {


    if (this.beneficiaryType !== BeneficiaryType.Director_Promoter) {
      this.form.relationship.setValidators(null);
      this.form.relationship.updateValueAndValidity();
    }
    if (this.beneficiaryForm.invalid) return;


    if (this.form.relationship.value === BeneficiaryType.Director || this.beneficiaryType === BeneficiaryType.Karta) {
      if (this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
        this.offlineModelView(ErrorMessage.IndividualError, true)
        return;
      }
    }
    // if (
    //   this.form.pan.value &&
    //   this.form.pan.value.charAt(3).toUpperCase() !== 'P'
    // ) {
    //   this.form.pan.setErrors({ inProper: true });
    //   return;
    // }


    let dob = "";
    if (this.form.dob.value !== "") {
      let newDate = new Date(this.form.dob.value);
      newDate.setDate(newDate.getDate())
      let formatedDate = formatDate(newDate, 'dd-MM-yyyy', 'en');
      dob = formatedDate;
    }

    let data = {
      applicationUuid: this.application.investmentID,
      pan: this.form.pan.value,
      dob: dob,
      typeOfInvestor: this.form.pan.value.charAt(3).toUpperCase() !== 'P' ? "Non-Individual" : "Individual",
      type: this.beneficiaryType,
      relationship: this.form.relationship.value !== '' ? this.form.relationship.value : this.beneficiaryType,
      ckycStatus: null,
      ckycDetail: null,
    };
    if (this.form.relationship.value) {
      data.type = this.form.relationship.value
    }
    this.showLoader = true;

    var reqBody;
    reqBody = {
      PAN: this.form.pan.value,
      DOB: dob
    }

    console.log(reqBody);
    console.log('Before ckyc call');
    this.applicationService.getCKYC(reqBody).subscribe(
      async (res: any) => {
        console.log('CKYC Response',res);
        this.showLoader = false;
        var datares=res.value;
        
        if (res.code == 400) {
          var ckycDetail = {
            "PAN":this.form.pan.value,
            "DateOfBirth":dob
          }

          data.ckycStatus = "error";
          data.ckycDetail = JSON.stringify(ckycDetail);
        }
        else {
         
         // datares.success ="ERROR";
          if(datares.status == "ERROR"){
            data.ckycStatus = "error";
            data.ckycDetail = JSON.stringify(ckycDetail);
          }
          else{
            data.ckycStatus = "SUCCESS";
            data.ckycDetail = JSON.stringify(res.value.data);
          }
       
        }

        console.log('Request Before creation call',data);
        this.beneficiaryService.createBeneficiary(data).subscribe(
          async (res: any) => {
            
            if (res.success == true) {

              let data = JSON.parse(res.data)
              if (data.ckycCheck === 1) {
                await this.modalController.dismiss({
                  data: data.investorId,
                });
                this.showLoader = false;
              }
              else {
                this.dismissModal();

                if (this.form.pan.value.charAt(3).toUpperCase() !== 'P') 
                {
                  console.log("this.form.pan.value.charAt(3).toUpperCase()")
                  this.investmentService.deleteInvestment(this.application.investmentID, data.investorId, false, true, BeneficiaryTypeNo.Beneficiary).subscribe(
                    (res: any) => {

                      this.offlineModelView(ErrorMessage.NonKYCError, false)
                    },
                    (err) => {
                    }
                  );
                  return;
                }
                else {
                  data.investmentId= this.application.investmentID;
                  const modal = await this.modalController.create({
                    component: nonkycModelComponent,
                    backdropDismiss: false,
                    cssClass: 'my-custom-class',
                    componentProps: {
                      holder: data,
                      onPanCheck: false,
                      isBeneficiary: true,
                      BeneficiaryTypeNo: 4
                    }
                  });
                  await modal.present();
                  modal.onDidDismiss().then((event) => { });
                  return;
                }
              }

              // const toast = await this.toastController.create({
              //   message: res.message,
              //   duration: 2000,
              // });
              // toast.present();

            }
            else {
              this.dismissModal();
              const modal = await this.modalController.create({
                component: OopsModalComponent,
                backdropDismiss: false,
                cssClass: 'my-custom-class',
              });
              await modal.present();
              modal.onDidDismiss().then((event) => { });

              this.showLoader = false;
              const toast = await this.toastController.create({
                message: res.message,
                duration: 2000,
              });
              toast.present();
            }
          },
          async (err) => {
            this.showLoader = false;
            const toast = await this.toastController.create({
              message: err.error.message,
              duration: 2000,
            });
            toast.present();
          }
        );



      });



    // this.beneficiaryService.createBeneficiary(data).subscribe(
    //   async (res: any) => {
    //     this.showLoader = false;
    //     if (res.success == true) {

    //       let data = JSON.parse(res.data)
    //       if (data.ckycCheck === 1) {
    //         await this.modalController.dismiss({
    //           data: data.investorId,
    //         });
    //         this.showLoader = false;
    //       }
    //       else {
    //         this.dismissModal();

    //         if (this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
    //           this.investmentService.deleteInvestment(this.application.investmentID, data.investorId, false, true, BeneficiaryTypeNo.Beneficiary).subscribe(
    //             (res: any) => {
    //               this.offlineModelView(ErrorMessage.NonKYCError, false)
    //             },
    //             (err) => {
    //             }
    //           );
    //           return;
    //         }
    //         else {
    //           const modal = await this.modalController.create({
    //             component: nonkycModelComponent,
    //             backdropDismiss: false,
    //             cssClass: 'my-custom-class',
    //             componentProps: {
    //               holder: data,
    //               onPanCheck: false,
    //               isBeneficiary: true,
    //               BeneficiaryTypeNo: 4
    //             }
    //           });
    //           await modal.present();
    //           modal.onDidDismiss().then((event) => { });
    //           return;
    //         }
    //       }

    //       // const toast = await this.toastController.create({
    //       //   message: res.message,
    //       //   duration: 2000,
    //       // });
    //       // toast.present();

    //     }
    //     else {
    //       this.dismissModal();
    //       const modal = await this.modalController.create({
    //         component: OopsModalComponent,
    //         backdropDismiss: false,
    //         cssClass: 'my-custom-class',
    //       });
    //       await modal.present();
    //       modal.onDidDismiss().then((event) => { });

    //       this.showLoader = false;
    //       const toast = await this.toastController.create({
    //         message: res.message,
    //         duration: 2000,
    //       });
    //       toast.present();
    //     }
    //   },
    //   async (err) => {
    //     this.showLoader = false;
    //     const toast = await this.toastController.create({
    //       message: err.error.message,
    //       duration: 2000,
    //     });
    //     toast.present();
    //   }
    // );

    // this.beneficiaryServ
  }
  dismissModal() {
    this.modalController.dismiss();
  }
}
