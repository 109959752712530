import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Application } from '../../core/model/application.model';
import { ApplicationService } from '../../services/application/application.service';

@Component({
  selector: 'right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
})
export class RightSidebarComponent implements OnInit {
  showEditInvestmentDetails: boolean = false;
  existingInvestorTransactionUuid: string;
  isTransactionEditable: boolean = true;
  private routeSubscription: Subscription;
  Existingtransaction: any;
  totalStepperCount = 4;
  elementId: any = 1;
  application: any;
  applicantUuid: any;
  headerName: string;
  showInvestment: boolean = true;
  @ViewChild('progress') progress: ElementRef;
  commitmentAmount: string;
  initContributionAmount: string;

  constructor(
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalController: ModalController,
    private readonly applicationService: ApplicationService,
  ) { }

  ngOnInit() {
    this.applicantUuid = this.route.snapshot.params.applicationuuid ? this.route.snapshot.params.applicationuuid : this.route.snapshot.params.uuid;
    this.fetchApplicationData();
    // this.panCheck = JSON.parse(localStorage.getItem('pancheckResponse'));
    // if (this.panCheck?.userType === EXISTING_INVESTOR) {
    //   this.getExistingUserTransactionDetails();
    //   this.panCheckListener();
    // } else {
    //   this.fetchFolioDetails();
    // }
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: any) => {
          if (
            event.url.includes('personal-details') ||
            event.url.includes('applicant-kyc-details')
          ) {
            this.headerName = 'Personal & FATCA Details';
            this.elementId = 1;
          } else if (event.url.includes('bank-details')) {
            this.headerName = 'Bank Details';
            this.elementId = 2;
          } else if (event.url.includes('investment-details')) {
            this.headerName = 'Investment Details';
            this.elementId = 3;
          } else if (event.url.includes('upload-documents')) {
            this.headerName = 'Upload Documents';
            this.elementId = 4;
          }

          this.progress.nativeElement.style.width =
            ((+this.elementId - 1) / (this.totalStepperCount - 1)) * 100 + '%';
          return event;
        })
      )
      .subscribe({
        next: (res) => { },
        error: (err) => { },
      });
  }

  ionViewWillEnter() {
    this.fetchApplicationData();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: any) => {
          if (
            event.url.includes('personal-details') ||
            event.url.includes('applicant-kyc-details')
          ) {
            this.headerName = 'Personal & FATCA Details';
            this.elementId = 1;
          } else if (event.url.includes('bank-details')) {
            this.headerName = 'Bank Details';
            this.elementId = 2;
          } else if (event.url.includes('investment-details')) {
            this.headerName = 'Investment Details';
            this.elementId = 3;
          } else if (event.url.includes('upload-documents')) {
            this.headerName = 'Upload Documents';
            this.elementId = 4;
          }

          this.progress.nativeElement.style.width =
            ((+this.elementId - 1) / (this.totalStepperCount - 1)) * 100 + '%';
          return event;
        })
      )
      .subscribe({
        next: (res) => { },
        error: (err) => { },
      });
  }

  fetchApplicationData() {
    this.showInvestment = false;
    this.applicationService.getApplicationByUuid(this.applicantUuid).subscribe(
      (res: any) => {
        this.application = JSON.parse(res.data);
        //console.log('this.application',this.application);
        this.application.holdersList = this.application.holdersList.sort(function (
          a,
          b
        ) {
          return a.holderIndex - b.holderIndex;
        });

        if (this.application.commitmentAmount) {
          this.commitmentAmount = this.commonService.commaSeparateNumber(
            this.application.commitmentAmount
          );
        }
        if (this.application.initContributionAmount) {
          this.initContributionAmount = this.commonService.commaSeparateNumber(
            this.application.initContributionAmount
          );
        }
        if (this.application.commitmentAmount !== "0")
          this.showInvestment = true;

      },
      (err) => {

      }
    )
  }

  // stepper(application?) {
  //   if (application.docs && application.investment.docs > 0) {
  //     this.elementId = 4;
  //   } else if (application.investment && application.investment) {
  //     this.elementId = 3;
  //   } else if (application.banks && application.banks.length > 0) {
  //     this.elementId = 2;
  //   } else {
  //     this.elementId = 1;
  //   }

  //   this.progress.nativeElement.style.width =
  //     ((+this.elementId - 1) / (this.totalStepperCount - 1)) * 100 + '%';
  // }
}
