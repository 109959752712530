import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  GET_USER,
  LOGIN_USER,
  LOGOUT,
  SIGNUP,
  SIGNUP_UPDATE,
  VERIFY_OTP,
  DELETE_DISTRIBUTOR
} from '../../core/constants/api-endpoints';
import { Signup } from '../../core/model/signup.model';
import { Login } from '../../core/model/auth.model';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
  Domain
} from '../../core/constants/app-strings';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly http: HttpClient) { }

  setStroage(key, value) {
    localStorage.setItem(key, value);
  }

  registerDistributorAndTriggerOTP(body: Signup) {
    const url = environment.APIURL + SIGNUP;
    let headers = {} as any;
    return this.http.post(url, body);
  }

  updateDistributor(body: Signup) {
    const url = environment.APIURL + SIGNUP_UPDATE;
    let headers = {} as any;
    return this.http.post(url, body);
  }

  deleteDistributor(uuid: number) {
    const url = environment.APIURL + DELETE_DISTRIBUTOR + `/${uuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }

  login(body: Login) {
    const url = environment.APIURL + LOGIN_USER;
    return this.http.post(url, body);
  }

  verifyOTP(body) {
    const url = environment.APIURL + VERIFY_OTP;
    return this.http.post(url, body, {});
  }

  getUser() {
    const url = environment.APIURL + GET_USER;
    const body = {};
    const headers = {} as any;
    var ATOken = "";
    if(localStorage.getItem(ACCESS_TOKEN) == null)
    {
ATOken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwQUE2QTNGOTM2RTk0MjU0OUJBQkFBNkIwRUVBQTMiLCJ1aWQiOiI0NDUiLCJleHAiOjE2NzI2MjA1MjMsImlzcyI6Imh0dHBzOi8vdWF0YXBpcy5lZGVsd2Vpc3NhbHRlcm5hdGl2ZXMuY29tL3N3aWZ0YXBpLyIsImF1ZCI6Imh0dHBzOi8vdWF0YXBpcy5lZGVsd2Vpc3NhbHRlcm5hdGl2ZXMuY29tL3N3aWZ0YXBpLyJ9.VQ6r5AAstA7tJDud3D-xCC2x7XuYewcsg7UmVzJNKNU";
    }
    else{
      ATOken = localStorage.getItem(ACCESS_TOKEN);
    }
    headers[AUTHORIZATION] = BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  logout() {
    const url = environment.APIURL + LOGOUT;
    const body = {};
    const headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);

    return this.http.post(url, body, { headers });
  }

  cookieDataFromOtherDomain() {
    let userCookieData = {} as any
    // document.cookie = "key=access_token;domain=http://localhost:4200"
    document.cookie = `key=access_token;domain=${Domain}`

    let cookiedata = localStorage.getItem("userDetail")
    // JSON.parse(cookiedata);
    userCookieData.access_token = localStorage.getItem(ACCESS_TOKEN)
    userCookieData.userData = JSON.parse(cookiedata) as any;
    console.log("cookiedata==", cookiedata)
    // if (document.cookie !== "") {
    //   let cookiesArray = document.cookie.split(";")
    //   let tokenCookie = cookiesArray[0].split("=")
    //   let userCookie = cookiesArray[1].split("=")
    //   if (tokenCookie.length > 1) {
    //     userCookieData.access_token = tokenCookie[1]
    //     if (userCookie.length > 1) {
    //       let enc = userCookie[1];
    //       enc = enc.replace(/%7B/g, '{');
    //       enc = enc.replace(/%22/g, '\"');
    //       enc = enc.replace(/%3A/g, ':');
    //       enc = enc.replace(/%2C/g, ',');
    //       enc = enc.replace(/%7D/g, '}');
    //       enc = enc.replace(/%20/g, ' ');
    //       enc = enc.replace(/%5B/g, '[');
    //       enc = enc.replace(/%5D/g, ']');

    //     }
    //   }
    // }
    return userCookieData;
  }
}
