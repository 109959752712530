import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ADD_POA,
  DELETE_POA,
  UPDATE_POA,
} from '../../core/constants/api-endpoints';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../../core/constants/app-strings';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class POAService {
  constructor(private readonly http: HttpClient) { }

  addPOA(body) {
    const url = environment.APIURL + ADD_POA;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  updatePOA(body) {
    const url = environment.APIURL + UPDATE_POA;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.put(url, body, { headers });
  }

  deletePOA(uuid: string, applicationuuid: string) {
    const url = environment.APIURL + DELETE_POA + `/${uuid}` + `/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }
}
