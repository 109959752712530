import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GET_IFSC_DETAILS_ENDPOINT,
  BANK_ENDPOINT_ENDPOINT,
  VERIFY_BANK_ACCOUNT_ENDPOINT,
  SAVE_PAYMENT_DETAILS_ENDPOINT,
  UPDATE_PAYMENT_DETAILS_ENDPOINT,
  GET_PAYMENT_MODE,
  GET_PAYMENT_TYPE,
  GET_ACCOUNT_TYPE,
  ADD_PAYMENT_DETAILS,
  GET_INVESTOR_BANK_DETAILS,
  GET_PAYMENT_DETAILS,
} from '../../core/constants/api-endpoints';
import { environment } from '../../../environments/environment';
import { Bank } from '../../core/model/bank.model';
import { InvestorPayments, PaymentRequest } from '../../core/model/payment.model';
import {
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
  ACCESS_TOKEN,
} from '../../core/constants/app-strings';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  constructor(private readonly http: HttpClient) {}

  getIFSCDetails(ifscCode: string) {
    const url = environment.APIURL + GET_IFSC_DETAILS_ENDPOINT + ifscCode;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  createBank(body: Bank) {
    const url = environment.APIURL + BANK_ENDPOINT_ENDPOINT;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  updateBank(body: Bank) {
    const url = environment.APIURL + BANK_ENDPOINT_ENDPOINT;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  verifyBankAccount(body: Bank) {
    const url = environment.APIURL + VERIFY_BANK_ACCOUNT_ENDPOINT;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  createPayment(body: PaymentRequest) {
    const url = environment.APIURL + SAVE_PAYMENT_DETAILS_ENDPOINT;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  updatePayment(body: PaymentRequest) {
    const url = environment.APIURL + UPDATE_PAYMENT_DETAILS_ENDPOINT;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.put(url, body, { headers });
  }

  // payment services
  getPaymentMode() {
    const url = environment.APIURL + GET_PAYMENT_MODE ;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  getPaymentType(){
    const url = environment.APIURL + GET_PAYMENT_TYPE;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  getAccountType(){
    const url = environment.APIURL + GET_ACCOUNT_TYPE;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.get(url, { headers });
  }

  savePaymentDetails(body: InvestorPayments){
    const url = environment.APIURL + ADD_PAYMENT_DETAILS;
    let headers = {};
    headers[AUTHORIZATION] = BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }

  getBankDetails(body:string){
    const url = environment.APIURL + GET_INVESTOR_BANK_DETAILS;
    let headers = {};
    headers[AUTHORIZATION] = BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }
  getPartialPaymentDetails(body:any){
    const url = environment.APIURL + GET_PAYMENT_DETAILS;
    let headers = {};
    headers[AUTHORIZATION] = BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body, { headers });
  }
}
  

