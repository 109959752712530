import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { CreateApplication } from '../../core/model/application.model';
import { ApplicationService } from '../../services/application/application.service';
import { DropdownService } from '../../services/dropdown/dropdown.service';
import { Errors, validationType } from '../../core/model/error.model';
import { CommonService } from '../../services/common/common.service';
import { UserService } from '../../services/user/user.service';
import { User } from '../../core/model/user.model';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component'
import { InvestorType, PanType } from 'src/app/core/constants/enum';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
// import { parse } from 'path';
import { formatDate } from '@angular/common';

@Component({
  selector: 'offline-popup',
  templateUrl: './offline-popup.component.html',
  styleUrls: ['./offline-popup.component.scss'],
})
export class OfflinepopupComponent implements OnInit {
  @Output() success: EventEmitter<any> = new EventEmitter();

  @Input() errorMessage: string
  @Input() isIndividual: boolean;
  applicantForm: FormGroup;
  floatLabelControl = new FormControl('always');
  status: boolean = false;
  error = Errors;
  showLoader = false;
  headerText = ''; //HEADER_TEXT_PANCHECK;
  messageText = ''; //MESSAGE_TEXT_PANCHECK;
  parentFund: any;
  childFund: any;
  RmName: [{ name: 'Jinal' }];
  distributorDetails: any;
  todayDate = new Date();
  NonCkycPopup: boolean = false;
  investorType = InvestorType;
  panType = PanType;
  forIndividual: boolean=false
  constructor(
    // private readonly userService: UserService,
    private modalController: ModalController,
    private readonly commonService: CommonService,
    private readonly dropdownService: DropdownService,
    private readonly applicationService: ApplicationService,
    private readonly loadingController: LoadingController,
    private userService: UserService,
    private toastController: ToastController,
    private router: Router
  ) { }

  ngOnInit() {
    this.forIndividual=this.isIndividual
  }

  dismissModal() {
    this.modalController.dismiss();
  }


}
