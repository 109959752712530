import { Component, Input, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from '../../../core/constants/date.adapter';
import { CommonService } from '../../../services/common/common.service';
import { GeneratedOtpResponse } from '../../../core/model/auth.model';
import { HolderService } from '../../../services/holder/holder.service';
import { Holder } from '../../../core/model/holder.model';

@Component({
  selector: 'app-add-new-applicant',
  templateUrl: './add-new-applicant.page.html',
  styleUrls: ['./add-new-applicant.page.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class AddNewApplicantPage implements OnInit {
  @Input() holderUuid: string;
  @Input() applicationHolder: any;
  @Input() show: string;
  @Input() applicationUuid: string;
  @Input() isShowDiv;
  @Input() allocation;
  @Input() application;
  holder: any;
  showDiv: string;
  otpData: GeneratedOtpResponse;
  showKYCLoader: boolean = false;
  holderapplication: any;
  kycComp = 'kycComp';
  holderData: any;
  constructor(
    private readonly modalController: ModalController,
    private router: Router,
    private commonService: CommonService,
    private holderService: HolderService
  ) { }

  ngOnInit() {
    console.log("testring- ", this.show)
    this.commonService.holder$.subscribe((res) => {
      this.holder = res;
    });
    this.holderapplication= this.applicationHolder;
  }

  ionViewWillEnter() {
    this.commonService.holder$.subscribe((res) => {
      this.holder = res;
    });
  }

  panCheck($event) {
    this.show = 'description';
  }


  confirmOtp(event) {
    this.show = event.show;
    const otpResponse = {} as GeneratedOtpResponse;
    otpResponse.phone = event.value.mobile;
    otpResponse.email = event.value.email;
    otpResponse.userUuid = event.value.uuid;
    this.otpData = otpResponse;
    this.holderData = event.holder;
  }

  otp() {
    this.commonService.holder$.subscribe((res) => {
      this.holder = res;
    });
    // let holderData = JSON.parse(this.holder.data)
    let holderData: any;
    if (this.holder.data === undefined)
      holderData = this.holder
    else
      holderData = JSON.parse(this.holder.data)

    let body = {} as Holder;
    body.otpTriggered = true;
    body.uuid = holderData.investorId;

    this.holderService.updateHolderOTP(body).subscribe(
      (res) => {
        this.show = 'ckyc';
        setTimeout(() => {
          this.modalController.dismiss();
          
          this.commonService.encrypt(holderData.investorId).subscribe((res: any) => {

            var investorId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');
  
            this.commonService.encrypt(holderData.investmentId).subscribe((res: any) => {
  
              var investmentId = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');
  
              window.location.href = '/application/applicant-kyc-details/' + investorId + "/" + investmentId;
  
            });
  
          });



          // this.router.navigateByUrl(
          //   '/application/applicant-kyc-details/' + holderData.investorId + "/" + holderData.investmentId
          // );
        }, 3000);
      },
      (err) => { }
    );
    // this.show = 'success';
    // this.showDiv = 'kycComp';
    // this.dismissmodal()
  }
}
