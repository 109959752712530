import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, validationType } from '../../core/model/error.model';
import { AddHolder } from '../../core/model/holder.model';
import { CommonService } from '../../services/common/common.service';
import { HolderService } from '../../services/holder/holder.service';
import { TopUpService } from '../../services/topup/topup.service';
import { ApplicationService } from '../../services/application/application.service';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';
import { formatDate } from '@angular/common';
import { UserService } from '../../services/user/user.service';
import { parse } from 'path';
import { DropdownService } from '../../services/dropdown/dropdown.service';

@Component({
  selector: 'app-add-download-offline',
  templateUrl: './add-download-offline.component.html',
  styleUrls: ['./add-download-offline.component.scss'],
})
export class AddDownloadOfflineComponent implements OnInit {
  @Input() applicationDetails: any;
  holderForm: FormGroup;
  error = Errors;
  showLoader = false;
  headerText = ''; //HEADER_TEXT_PANCHECK;
  messageText = ''; //MESSAGE_TEXT_PANCHECK;
  todayDate = new Date();
  application: any;
  applicantUuid: string;
  version: string;
  parentFund: any;
  caList: any = [];
  showValidation = false;
  showDraftValidation = false;
  isOnSubmit = true;
  isOnSuccess = false;
  showversion = false;
  fundDropdownDisable = false;
  showAffiliateValidation = false;
  showInvestorType = true;
  @Input() applicationUuid: string;
  @Output() add: EventEmitter<any> = new EventEmitter();
  constructor(
    private modalController: ModalController,
    private holderService: HolderService,
    private loadingController: LoadingController,
    private commonService: CommonService,
    private toastController: ToastController,
    private applicationService: ApplicationService,
    private topupService: TopUpService,
    private route: ActivatedRoute,
    private userService: UserService,
    private readonly router: Router,
    private readonly dropdownService: DropdownService,

  ) { }

  ngOnInit() {
    this.createForm();
    console.log('Hii Inside add applicant');
    this.getDropDownValues()
    this.getCA_Master();
    //console.log('Hii',this.applicationUuid);
  }

  get form() {
    return this.holderForm.controls;
  }

  createForm() {
    this.holderForm = new FormGroup({
      fundName: new FormControl('', [Validators.required]),
      typeOfInvestor: new FormControl('Individual'),
      typeOfApplication: new FormControl('New'),
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }


  getDropDownValues() {
   
    this.dropdownService
    .getChildFund()
   // .getChildFund(this.form.fundName.value.fundID)
    .subscribe(
      (res: any) => {
        this.parentFund = res;
        this.parentFund=this.parentFund.filter(x=> x.fundId=="FUND252"  || x.fundId == "FUND255"|| x.fundId == "FUND241" || x.fundId == "FUND239" || x.fundId == "FUND261" || x.fundId == "FUND343" || x.fundId == "FUND360" );
       // this.parentFund = this.parentFund.filter(x=> x.fundId=="FUND252"  || x.fundId == "FUND255" || x.fundId == "FUND261" || x.fundId == "FUND360" || );
        console.log(this.parentFund);
      },
      (err) => { }
    );
  }

  
// downloadOffline(){
 
// var childfundid="FUND239";
// var typeOfInvestor="Individual";

//    this.applicationService.downloadOfflineCA(childfundid,typeOfInvestor).subscribe(
//     async (res: any) => {
//     //    this.commonService.updateLoader(false);
//      console.log(res);
// if(res.success){
//   var urlRs=res.message;

//       var a = document.createElement('a');
//        a.href = urlRs; a.target = '_blank';
//        a.download = res.data;
//        document.body.appendChild(a);
//         a.click();
// }

//      },
//      async (err) => {
//        console.log("Affiliate     ", err);
//        const toast = await this.toastController.create({
//          message:  "File not present",
//          duration: 2000,
//        });
//        toast.present();
//        this.commonService.updateLoader(false);
//      }
//    );
 
// }


 
async getCA_Master(){
 

     this.applicationService.getCA_Master().subscribe(
      async (res: any) => {
      //    this.commonService.updateLoader(false);
       console.log(res);
  if(res.success){
    this.caList = res.data;
  }
  
       },
       async (err) => {
       
         const toast = await this.toastController.create({
           message:  "Error Occurred",
           duration: 2000,
         });
         toast.present();
         this.commonService.updateLoader(false);
       }
     );
   
  }


  onTypeOfApplication(event){
this.showInvestorType=true;
var calistData;
var  childfundID = this.form.fundName.value.fundId; 

var typeOfApplication = this.form.typeOfApplication.value;
if(typeOfApplication == "New"){
  this.showInvestorType=true;
}
else{
  this.showInvestorType=false;
 
}

this.onTypeOfInvestor(event);

  }


onTypeOfInvestor(event){
  var  childfundID = this.form.fundName.value.fundId; 
  var typeOfInvestor = this.form.typeOfInvestor.value;
  var calistData;
  if(childfundID=="" || childfundID == undefined || childfundID == null){
   return;
  }
  else{
    this.onSelectionChange(event);
  }
}
  onSelectionChange(event){
    var  childfundID = this.form.fundName.value.fundId; 
    var typeOfInvestor = this.form.typeOfInvestor.value;
    var calistData;
    this.showversion=true;
    var typeOfApplication = this.form.typeOfApplication.value;
if(typeOfApplication == "New"){
    if(typeOfInvestor == "Individual"){
       calistData= this.caList.filter(x=>x.childFundId == childfundID && x.forIndividual == 1 && x.isAffiliate == 0 && x.isTopUp == 0 && (x.endDate == "" || x.endDate == null));
       this.version = calistData[0].version;
    }
    else{
       calistData= this.caList.filter(x=>x.childFundId == childfundID);
       calistData= this.caList.filter(x=>x.childFundId == childfundID && x.forIndividual == 0 && x.isAffiliate == 0 && x.isTopUp == 0 && (x.endDate == "" || x.endDate == null));
       this.version = calistData[0].version;
    }
  }
  else if(typeOfApplication == "Top-up"){
    calistData= this.caList.filter(x=>x.childFundId == childfundID &&   x.isTopUp == 1 && (x.endDate == "" || x.endDate == null));
    this.version = calistData[0].version;
  }
  else{
    calistData= this.caList.filter(x=>x.childFundId == childfundID &&   x.isAffiliate == 1 && (x.endDate == "" || x.endDate == null));
    this.version = calistData[0].version;
  }
  
  }



  submit(){
   var  childfundID = this.form.fundName.value.fundId;
   console.log(childfundID);
   var typeOfInvestor = this.form.typeOfInvestor.value;
   var typeOfData="";
   console.log(typeOfInvestor);
   if( childfundID=="" || childfundID == undefined || childfundID == null){
    return;
   }
   var typeOfApplication = this.form.typeOfApplication.value;
   if(typeOfApplication == "New"){
    typeOfData=typeOfInvestor;
   }
   else if(typeOfApplication == "Top-up"){
    typeOfData="Topup";
   }
   else{
    typeOfData="Affiliate";
   }
   this.showLoader = true;
   this.applicationService.downloadOfflineCA(childfundID,typeOfData).subscribe(
    async (res: any) => {
    //    this.commonService.updateLoader(false);
    this.showLoader = false;
     console.log(res);
      if(res.success){
        var urlRs=res.message;

            var a = document.createElement('a');
            a.href = urlRs; a.target = '_blank';
            a.download = res.data;
            document.body.appendChild(a);
              a.click();
      }
     },
     async (err) => {
      this.showLoader = false;
       console.log("Affiliate     ", err);
      //  const toast = await this.toastController.create({
      //    message:  "File not present",
      //    duration: 2000,
      //  });
      // toast.present();
       this.commonService.updateLoader(false);
     }
   );
  }

  onCancel() {
    this.showValidation = false
    this.showDraftValidation = false
    this.isOnSubmit = true
    this.isOnSuccess = false
    this.fundDropdownDisable = false
  }


}
