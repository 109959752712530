import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { flatMap, map, mergeMap, share } from 'rxjs/operators';
import { GET_MASTER_DATA } from '../../core/constants/api-endpoints';
import { environment } from '../../../environments/environment';
import { Application } from '../../core/model/application.model';
import { User } from '../../core/model/user.model';
import { GET_ARN_DISTRIBUTOR_DATA ,GET_AIF_DATA} from '../../core/constants/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient) { }

  private loaderState = new BehaviorSubject<boolean>(false);
  loader$ = this.loaderState.asObservable().pipe(share());

  private applicationState = new Subject<Application>();
  application$ = this.applicationState.asObservable().pipe(share());

  private userState = new Subject<User>();
  user$ = this.userState.asObservable().pipe(share());

  private holderState = new Subject<any>();
  holder$ = this.holderState.asObservable().pipe(share());

  updateApplication(application) {
    this.applicationState.next(application);
  }

  updateUser(user: User) {
    this.userState.next(user);
  }

  updateHolder(holder) {
    this.holderState.next(holder);
  }

  updateLoader(isLoading: boolean) {
    this.loaderState.next(isLoading);
  }

  getMasterData(id, type, subType?) {
    const url =
      environment.APIURL + GET_MASTER_DATA + id + '/' + type + '/' + subType;
    return this.http.get(url);
  }

  getArnAndDistributor() {
    const url =
      environment.APIURL + GET_ARN_DISTRIBUTOR_DATA;
    return this.http.get(url);
  }

  getAIFData() {
    const url =
      environment.APIURL + GET_AIF_DATA;
    return this.http.get(url);
  }

  getCCData() {
    const url =
      environment.MASTERAPIURL + "/Fund/getFundCC";
    return this.http.get(url);
  }

  encrypt(plainText) {
    const url =
      environment.MASTERAPIURL +
      '/global/Encrypt?plainText=' +
      plainText;
    //const headers = {};
    //headers[CONTENT_TYPE] = APPLICATION_JSON_CONTENT_TYPE;
    //headers[AUTHORIZATION] = '8a5148d0-e7fc-4fc5-959f-d1fdc6df4444';
    // const agent = new httpss.Agent({
    //   rejectUnauthorized: false
    // });

    return this.http.post(url, "");
  }

  decrypt(encyptedText) {
    const url =
      environment.MASTERAPIURL +
      '/global/Decrypt?encyptedText=' +
      encyptedText;
    //const headers = {};
    //headers[CONTENT_TYPE] = APPLICATION_JSON_CONTENT_TYPE;
    //headers[AUTHORIZATION] = '8a5148d0-e7fc-4fc5-959f-d1fdc6df4444';
    // const agent = new httpss.Agent({
    //   rejectUnauthorized: false
    // });

    return this.http.post(url, "");
  }

  isNumberKey(evt: { which: any; keyCode: any }) {
    const charCode = evt.which ? evt.which : evt.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isAlphaNumericKey(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  isAlphaKey(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z- ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  commaSeparateNumber(val: any) {
    if (val === 0 || val === null || val === '0') {
      return '0';
    }
    // tslint:disable-next-line: prefer-const
    var input = val;
    var parts = input.toString().split('.');

    var x = parts[0];
    var decimals = parts[1];

    if (x > 7) {
      var crore = x.substring(0, x.length - 7);
      var lastThree = x.substring(x.length - 3);
      var thousandlakh = x.substring(x.length - 7, x.length - 3);
    } else if (x <= 7) {
      var lastThree = x.substring(x.length - 3);
      var thousandlakh = x.substring(0, x.length - 3);
      var crore: any = '';
    } else if (x <= 5) {
      var lastThree = x.substring(x.length - 3);
      var thousandlakh = x.substring(0, x.length - 3);
      var crore: any = '';
    } else {
      var lastThree: any = '';
      var thousandlakh: any = '';
      var crore: any = '';
    }

    if (thousandlakh != '') lastThree = ',' + lastThree;

    if (crore != '') thousandlakh = ',' + thousandlakh;

    var crorefinal = crore.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    var res =
      crore.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      thousandlakh.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
      lastThree;
    if (res == 0) return '';

    if (parts[1] && parts[1].length > 0) {
      decimals = decimals.toString().substring(2, 0);
    }
    if (val.toString().includes('.')) res = res + '.' + decimals;

    return res;
  }

  maskInput(inputData: string, type: string) {
    let val, data, first2char, last2char, domain, len, maskL;
    data = type === 'email' ? inputData.split('@') : '';
    val = type === 'email' ? data[0] : inputData;

    if (val.length >= 10) {
      len = 3;
      maskL = val.length - 5;
    } else if (val.length >= 6) {
      len = 2;
      maskL = val.length - 3;
    } else if (val.length >= 5) {
      len = 1;
      maskL = val.length - 1;
    } else {
      len = 1;
      maskL = val.length - len * 2;
    }

    const mask =
      type === 'email' ? Array(maskL).join('*') : Array(maskL).join('*');
    domain = type === 'email' ? `@` + data[1] : '';
    last2char =
      type === 'email'
        ? val.substring(val.length - len, val.length)
        : val.substring(val.length - len, val.length);
    first2char =
      type === 'email' ? val.substring(0, len) : val.substring(0, len);

    return `${first2char}${mask}${last2char}${domain}`;
  }

  setValidation(name, validator) {
    name.setValidators(validator);
    name.updateValueAndValidity();
  }
}
