import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  CREATE_CONTACT_PERSON,
  DELETE_CONTACT_PERSON,
  UPDATE_BENEFICIARY,
  UPDATE_CONTACT_PERSON,
} from '../core/constants/api-endpoints';
import { Beneficiary } from '../core/model/beneficiary.model';
import {
  ACCESS_TOKEN,
  AUTHORIZATION,
  BEARER_TOKEN_PREFIX,
} from '../core/constants/app-strings';

@Injectable({
  providedIn: 'root',
})
export class ContactPersonService {
  constructor(private http: HttpClient) {}

  updateContactPerson(body) {
    const url = environment.APIURL + UPDATE_BENEFICIARY;
    //let headers = {} as any;
    // headers[AUTHORIZATION] =
    //   BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body);
  }

  createContactPerson(body) {
    const url = environment.APIURL + CREATE_CONTACT_PERSON;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.post(url, body,{headers});
  }

  updatContactPersonDetails(body) {
    const updateContactUrl = environment.APIURL + UPDATE_CONTACT_PERSON;
    return this.http.post(updateContactUrl, body);
  }

  deleteContactPerson(uuid: string,applicationuuid :string) {
    const url = environment.APIURL + DELETE_CONTACT_PERSON + `${uuid}/${applicationuuid}`;
    let headers = {} as any;
    headers[AUTHORIZATION] =
      BEARER_TOKEN_PREFIX + localStorage.getItem(ACCESS_TOKEN);
    return this.http.delete(url, { headers });
  }
}
