import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { ApplicationService } from 'src/app/services/application/application.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UploadDocument } from 'src/app/core/model/application.model';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpClient } from '@angular/common/http';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-physical-sign',
  templateUrl: './upload-physical-sign.component.html',
  styleUrls: ['./upload-physical-sign.component.scss'],
})
export class UploadPhysicalSignComponent implements OnInit {
  @Input() applicationDetails: any;
  @ViewChild('fileInput') fileInput: any;
  uploadSign: FormGroup;
  files: File[] = [];
  fileType: string;
  updateList = [];
  fileAttr: string = 'Choose file';
  maxSizeError: boolean = false;
  fileExistingErr: boolean = false;
  allowedFiles: boolean = false;
  fileformatError: boolean = false;
  selectedFileBLOB: any;
  successUpload: boolean = false;
  physicalSignDetails: boolean = true;
  submitted = false;
  fieldValidateErr: boolean = false;
  fileReq: boolean = false;
  maxDate = new Date();
  updateListUrls: SafeUrl[] = [];
  showLoader = false;
  headerText = '';
  messageText = '';
  constructor(
    private modalController: ModalController,
    private applicationService: ApplicationService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private readonly toastController: ToastController,
    private router: Router,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  get form() {
    return this.uploadSign.controls;
  }
  createForm() {
    this.uploadSign = new FormGroup({
      dateOfSign: new FormControl('', [Validators.required]),
      uploadFile: new FormControl(null, [Validators.required]),
    });
  }

  onFileSelected(event: any) {
    if (this.fileInput.nativeElement.files.length == 0) {
      return;
    }

    this.fileAttr =
      this.fileInput.nativeElement.files.length > 0
        ? this.fileInput.nativeElement.files[0].name
        : 'Choose file';
  }

  updateUploadList() {
    this.maxSizeError = false;
    this.fileExistingErr = false;
    this.allowedFiles = false;
    this.fileformatError = false;
    if (this.fileInput.nativeElement.files.length == 0) {
      this.fileReq = true;
    } else {
      this.fileReq = false;
    }
    if (
      this.fileInput.nativeElement.files &&
      this.fileInput.nativeElement.files.length > 0
    ) {
      this.fileReq = false;
      this.fileType = this.fileInput.nativeElement.files[0].name
        .split('.')
        .pop();
      let filesize = this.fileInput.nativeElement.files[0].size;

      //already exists file
      const newFile = this.fileInput.nativeElement.files[0];
      const isFileAlreadyUploaded = this.updateList.some(
        (file) => file.name === newFile.name
      );
      if (isFileAlreadyUploaded) {
        this.fileExistingErr = true;
        this.fileAttr = '';
        return;
      }

      //file size
      if (filesize > 20000000) {
        this.maxSizeError = true;
        this.fileAttr = '';
        return;
      }

      //file type
      if (this.fileType != 'pdf') {
        this.fileformatError = true;
        this.fileAttr = '';
        return;
      }

      if (this.updateList.length >= 2) {
        this.allowedFiles = true;
        this.fileAttr = '';
        return;
      }

      this.updateList.push(this.fileInput.nativeElement.files[0]);
      this.fileAttr = '';
      this.updateListUrls = []; // Initialize an array to store URLs

      for (const file of this.updateList) {
        const url = URL.createObjectURL(file);
        this.updateListUrls.push(this.sanitizer.bypassSecurityTrustUrl(url));
      }
    }
  }

  ConfirmDialog() {
    this.submitted = true;
    if (this.updateList.length == 0) {
      this.fileReq = true;
    }
    if (this.uploadSign.valid && this.updateList.length > 0) {
      this.fileReq = false;
      this.openConfirmDialog();
    } else {
      console.log('error found ');
    }
  }

  async openConfirmDialog() {
    const modal = await this.modalController.create({
      component: ConfirmModalComponent,
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      componentProps: {},
    });
    await modal.present();
    modal.onDidDismiss().then((event) => {
      if (event.role === 'confirm') {
        this.onSubmit();
      }
    });
  }

  // save date and documentName
  async saveUploadDetails() {
    let doc = {} as any;
    const date = new Date();
    this.physicalSignDetails = true;
    const selectedDate = this.uploadSign.get('dateOfSign')?.value;
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');

    doc.investorId = this.applicationDetails.investorId;
    doc.investmentId = this.applicationDetails.investmentId;
    doc.dateOfSign = `${year}-${month}-${day}`;
    doc.userId = Number(localStorage.getItem('userId'));
    if (this.updateList.length == 2) {
      // doc.documentName1 = 'PhysicalSign_Upload_' + 1 + '_' + date.toISOString();
      // doc.documentName2 = 'PhysicalSign_Upload_' + 2 + '_' + date.toISOString();
      doc.documentName1 = this.updateList[0].name;
      doc.documentName2 = this.updateList[1].name;
    } else if (this.updateList.length == 1) {
      doc.documentName1 = this.updateList[0].name;
      doc.documentName2 = '';
    }

    doc.documentType = 'PhsicalSign_Upload';

    try {
      const res = await this.applicationService
        .savePhysicalUploadDetails(
          doc.investmentId,
          doc.investorId,
          doc.userId,
          doc.documentName1,
          doc.documentName2,
          doc.dateOfSign
        )
        .toPromise();

      if (!res) {
        this.physicalSignDetails = false;
      }
    } catch (err) {
      console.log('Error:', err);
      const toast = await this.toastController.create({
        message: 'Something went wrong',
        duration: 2000,
      });
      toast.present();
    }
  }

  async onSubmit() {
    try {
      this.showLoader = true;
      const body = {} as UploadDocument;
      body.applicationUuid = this.applicationDetails.investmentId;
      body.holderUuid = this.applicationDetails.investorId;
      body.documentType = 'PhsicalSign_Upload';
      const date = new Date();
      let i = 0;
      let allUploadsSuccessful = true; // Flag to track if all uploads were successful

      const uploadFileRecursively = async (index: number) => {
        if (index < this.updateList.length) {
          const file = this.updateList[index];
          ++i;
          body.filename = 'PhsicalSign_Upload_' + i + '_' + date.toISOString();

          try {
            await this.applicationService.uploadFile(file, body);
          } catch (err) {
            console.error('Error during file upload:', err);
            allUploadsSuccessful = false;
          }

          // Call the recursive function for the next file
          await uploadFileRecursively(index + 1);
        } else {
          // After all file uploads are completed
          this.showLoader = false;

          if (allUploadsSuccessful) {
            this.successUpload = true;
            this.saveUploadDetails();
          } else {
            this.showLoader = false;
            const toast = await this.toastController.create({
              message: 'Something went wrong',
              duration: 2000,
            });
            toast.present();
          }

          if (this.physicalSignDetails) {
            this.showLoader = false;
            const toast = await this.toastController.create({
              message: 'Application sent to R&T for verification.',
              duration: 2000,
            });
            toast.present();
          } else {
            this.showLoader = false;
            const toast = await this.toastController.create({
              message: 'Something went wrong',
              duration: 2000,
            });
            toast.present();
          }

          this.dismissModal();
        }
      };

      // Start the recursive function to upload files sequentially
      await uploadFileRecursively(0);
    } catch (error) {
      console.error('Error during onSubmit:', error);
      this.showLoader = false;
    }
  }

  async showToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  //delete file

  deleteFile(index: number) {
    let a = this.updateList.splice(index, 1);
    this.updateListUrls.splice(index, 1);
    this.fileInput.nativeElement.value = '';

    this.fileAttr = 'Choose file';
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
