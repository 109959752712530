import {
  Validators,
  FormControl,
  FormGroup,
  FormArray,
  AbstractControl,
} from '@angular/forms';

const accountNo = /^[0-9]{6,18}$/;
const pinCode = /^[0-9]{6}$/;
const pinNRI = /^[a-zA-Z0-9]{2,12}$/;
const aadhar = /^[0-9]{12}$/;
const ifscCode = /^[A-Za-z0-9]{1,99}$/;
const address = /^[a-zA-Z0-9\s,'-]*$/;
const email =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const arn = /^[0-9]{3,10}$/;
const mobile = /^[0-9]{10}$/;
const pan = /^[a-zA-Z]{5}\d{4}[a-zA-Z]{1}$/;
const alphabetsWithSpace = /^[a-zA-Z ]{2,100}$/;
const alphabetsWithSpaceDot = /^[a-zA-Z .]{3,32}$/;
const alphabetsWithSpaceDotEntity = /^[a-zA-Z .-]{3,255}$/;
const alphabets = /^[a-zA-Z]{1,100}$/;
const alphaNumericWithSpace = /^[a-zA-Z0-9 ]{2,1000}$/;
const alphaNumericWithOutSpace = /^[a-zA-Z0-9]{2,50}$/;
const alphaNumericWithOutSpaceId = /^[a-zA-Z0-9]{1,99}$/;
const fullNamePattern = /^[a-zA-Z]{1,30}(?: [a-zA-Z \. \s]+){1,2}$/;
const upi = /^.+@.+$/;
const chequeNumber = /^[0-9]{6,9}$/;
const passportNumber = /^[a-zA-Z0-9]{8,10}$/;
const dinNo = /^(([0-9]{8}))$/;
const uid = /^(([0-9]{12}))$/;

const giin = /^[a-zA-Z0-9]{16,19}$/;
const giinOfSponser = /^[a-zA-Z0-9 ]{2,1000}$/;
const nameOfSponser = /^[a-zA-Z0-9 ]{2,1000}$/;
const cin = /^[a-zA-Z0-9]{6,21}$/;

export const Errors = {
  requiredError: {
    country: 'Country required',
    ifsc: 'IFSC Code required',
    name: 'Name is required',
    bankName: 'Bank Name required',
    branchName: 'Branch Name required',
    pinCode: 'Pin code required',
    Address: 'Address required',
    City: 'City required',
    State: 'State required',
    accountNo: 'Account Number required',
    accType: 'Account Type required',
    email: 'Email Id required',
    mobile: 'Mobile number required',
    arn: 'ARN Number required',
    pan: 'PAN required',
    dob: 'Date of birth required',
    password: 'Password is required',
    otp: 'Please enter OTP',
    legalStatus: 'Legal Status is required',
    occupation: 'Occupation is required',
    income: 'Income is required',
    countryOfBirth: 'Country of Birth is required',
    placeOfBirth: 'City of Birth is required',
    taxOutsideIndia: 'Tax Outside India is required',
    relationship: 'Relationship is required',
    nomineeType: 'Nominee Type is required',
    allocation: 'Allocation is required',
    overseasAddress: 'Overseas Address is required',
    politicalStatus: 'Political Status is required',
    identificationType: 'Tax Identification Type is required',
    referenceNumber: 'Tax Reference Number is required',
    overseasCountry: 'Overseas Country is required',
    overseasZipcode: 'Overseas Zip Code is required',
    nationality: 'Nationality is required',
    guardianName: 'Guardian Name is required',
    bankAccountType: 'Account type required',
    scheme: 'Please select Scheme',
    plan: 'Please select Plan',
    option: 'Please select Option',
    amount: 'Please enter Amount',
    paymentMode: 'Please select a Payment Mode',
    broker: 'Broker is required',
    ria: 'RIA is required',
    subBrokerCode: 'Sub Broker Code is required',
    internalSubBrokerCode: 'Internal Sub Broker Code is required',
    euin: 'EUIN is required',
    eCode: 'E-Code is required',
    bankAddress: 'Bank Address is required',
    sipAmount: 'Please Enter Sip Amount',
    sipFrequency: 'Please enter sip frequency',
    sipStartDate: 'Please enter sip start date',
    sipInstallments: 'Please enter sip installments',
    upi: 'Please enter UPI-ID',
    folio: 'Please select Folio',
    selectBank: 'Please select Bank',
    switchBy: 'Please select Switch By',
    units: 'Please enter units',
    periodOfStatement: 'Please choose Period of Statement',
    fromDate: 'Please enter date',
    toDate: 'Please enter date',
    pauseStartDate: 'Please enter start date',
    pausePeriod: 'Please select pause period',
    compensatoryContribution: 'Compensatory Contribution required',
    ARN: 'ARN number required',
    managementFee: 'Management Fee is required',
    entityName: 'Distributor entity name required',
    fundName: 'Scheme name required',
    aifName: 'Fund name required',
    required: 'Required',
    aadhar: 'Aadhar number required',
    referenceDate: 'Date required',
    paymentReferenceNumber: 'Cheque/DD no required',
    initialContribution: 'Initial Contribution required',
    compensatingContribution: 'Compensating Contribution required',
    classOfShare: 'Class required',
    setUpFee: 'Set up fee required',
    tp_setUpFee: 'Set up fee required',
    passportNumber: 'Passport number required',
    designation: 'Designation is Required',
    nerthworth: 'NetWorth is Required',
    giin: 'GIIN is Required',
    ifGiinAvailableNo: 'Please select one option',
    subsidiaryCompanyYes: 'Please select one option',
    ifSubsidiaryCompanyNo: 'Please select one option',
    specifyCategory: 'Please specify the category',
    giinSponsoringEntity: 'Please enter GIIN of sponsoring entity',
    nameSponsoringEntity: 'Please enter Name of sponsoring entity',
    subsidaryOthers: 'Please Specify others',
    nameOfListedCompany: 'Please specify the name of listed company',
    ifSubsidiaryCompanyNoWithOtherOptionInSelectBob: 'Please Specify',
    // taxCountry
    ///taxIndentificationType
    // taxIndentificationNo
    entityExemtionCode:
      'Please provide Entity Exemtion Code only if Entity is not a Specified U.S Person ',
    //    personalDetailEntityName:'Entity Name is required',
    countyOfIncorporation: 'Country of Incorporation is required',
    cityOfIncorporation: 'City of incorporation is required',
    registrationNumber: 'Registration No is required',
    typeOfBusiness: 'Occupation /Type of business is required',
    customerId: 'Customer Id is required',
    globalEntityId: 'Global Entity Identification No is required',
    addressType: 'Please select address type',
    selectBeneficiary: 'Please select Beneficary',
    signatoryName: 'Signatory is required',
    signatoryEmailId: 'Signatory Email Id is required',
    nameCoparnerOne: 'Name of the Coparcener 1 is required',
    emailIdCoparnerOne: 'Email Id of the Coparcener 1 is required',
    ckycNumber: 'CKYC No is required',
    percentageHolding: 'Percentage is required',
    // designation: '',
    dinNo: 'DIN/UID Number is required',
    comment:'Comment is required'
   // dd_compensatoryContributionForm:'Compensating Contribution Amount required',
  //  dd_tp_compensatoryContributionForm:'Compensating Contribution Amount required',
    
    //relationship: '',
    // payTaxOutsideIndia:'',
  },

  minError: {
    allocation: 'Allocation should be 1-100%',
    commitmentAmount: 'Minimum commitment amount is INR',
    minSetUpFee: 'Set up fee percentage should be atleast 0.1',
    initialContributionPercentage: 'Minimum 0.1%',
  },

  maxError: {
    maxSetUpFee: 'Maximum 0.50% allowed for set up fee ',
    maxUpto2SetUpFee: 'Maximum 2% allowed for set up fee ',
    maxUpto1SetUpFee: 'Maximum 1% allowed for set up fee ',
    initialContributionPercentage: 'Maximum 100%',
  },

  patternError: {
    name: 'Invalid Name',
    ifsc: 'Invalid IFSC Code',
    bankName: 'Invalid Bank Name',
    branchName: 'Invalid Branch Name',
    address: 'Invalid Address',
    city: 'Invalid City',
    state: 'Invalid State',
    accountNo: 'Account Number should be 6-18 digits is mandatory',
    country: 'Invalid Country',
    email: 'Enter valid Email ID',
    mobile: 'Enter valid mobile number',
    arn: 'Enter valid ARN Number',
    pan: 'Enter a valid PAN, e.g. ABCDE1234F',
    dob: 'Enter date of birth',
    fullName: 'Please enter full name (as per PAN)',
    password: 'Please enter correct password',
    allocation: 'Please enter only 2 decimal value',
    otp: 'Please enter correct 4 digit OTP',
    upi: 'Please enter valid UPI-ID',
    sipInstallments: 'Please enter minimum 12 Installments',
    onlyAlphaCharactersAllowed: 'Only alpha characters(a-z,A-Z) are allowed',
    nonCkycPan: 'This is Non-kyc pan',
    decimalUnits: 'Please enter only 2 decimal value',
    aadhar: 'Invalid Aadhaar',
    passportNumber: 'Invalid Passport',
    paymentReferenceNumber: 'Invalid Cheque/DD no',
  },

  invalidError: {
    pan: 'Only PAN with individual holders are allowed',
    password: 'Please enter correct password',
    otp: 'Please enter correct OTP',
    confirmPassword: 'Entered password did not match',
    ifscCode: 'Invalid IFSC Code',
    duplicatePan: 'PAN number cannot be same for holders',
    greaterDob: 'Date of Birth cannot be greater than current date',
    minorDob: `Minor's age should be less than 18`,
    majorDob: `Applicant's Age should be greater than 18`,
    dob: 'Date cannot be greater than todays date',
    duplicateTaxCountry: 'Tax Country is already selected',
    guardianMajorDob: `Guardian's Age should be greater than 18`,
    secondApplicantMajorDob: `Second applicant cannot be minor`,
    fromFutureDate: 'Please select From Date less than today',
    toFutureDate: 'Please select To Date less than today',
    toDateLessThanFrom: 'Please select To Date Greater than From Date',
    duplicateAccountNo: 'Please enter a different account number',
    maxAmountError:
      'To place a STP/SWP transaction, you must have minimum Rs.25,000 in the selected scheme in the folio. Please try Switch or Redemption instead.',
  },

  specialError: {
    invalidCharacter: 'Invalid character',
    nonCkycPan: 'Entered PAN is Non-KYC Compliance',
    blocked: 'Max attempts exceeded. Your account is blocked for 5 mins',
    expired: 'Entered OTP has expired, Please click on resend OTP',
    nfoSchemeNotAllowed:
      'The window to switch into new NFO from overseas funds has been closed',
    userNotFoundError: 'User not found',
  },
};

export const validationType = {
  name: [Validators.required, Validators.pattern('^[a-zA-Z0-9.\\- ]{1,}$')],
  accountNo: [Validators.required, Validators.pattern(accountNo)],
  ifsc: [Validators.required, Validators.pattern(ifscCode)],
  varAlpha: [Validators.required, Validators.pattern(alphabetsWithSpace)],
  varAlphaDot: [Validators.required, Validators.pattern(alphabetsWithSpaceDot)],
  varAlphaDotEntity: [
    Validators.required,
    Validators.pattern(alphabetsWithSpaceDotEntity),
  ],
  varAlphaNonmandatory: [Validators.pattern(alphabetsWithSpace)],
  email: [Validators.required, Validators.pattern(email)],
  emailId: [Validators.pattern(email)],
  mobile: [Validators.required, Validators.pattern(mobile)],
  arnNumber: [Validators.required, Validators.pattern(arn)],
  alphabetsWithSpace: [Validators.pattern(alphabetsWithSpace)],
  PAN: [Validators.required, Validators.pattern(pan)],
  fullName: [Validators.required], // Validators.pattern(fullNamePattern)
  Required: [Validators.required],
  Password: [Validators.required, Validators.minLength(8)],
  OTP: [Validators.required, Validators.minLength(4)],
  city: [Validators.required],
  allocation: [Validators.required, Validators.min(1)],
  onlyAlphaCharactersAllowed: [
    Validators.required,
    Validators.pattern('^[a-zA-Z ]*$'),
  ],
  upi: [Validators.required, Validators.pattern(upi)],
  invalidCharacter: [Validators.required, specialCharValidator],
  pinCode: [Validators.required, Validators.pattern(pinCode)],
  pinCodeNRI: [Validators.required, Validators.pattern(pinNRI)],
  aadhar: [Validators.required, Validators.pattern(aadhar)],
  passportNumber: [Validators.required, Validators.pattern(passportNumber)],
  chequeNumber: [Validators.required, Validators.pattern(chequeNumber)],
  dinNo: [Validators.pattern(dinNo)],
  //uid: [Validators.required, Validators.pattern(uid)],
  uid: [Validators.pattern(uid)],
  giin: [Validators.required, Validators.pattern(giin)],

  giinOfSponser: [Validators.required, Validators.pattern(giinOfSponser)],

  nameOfSponser: [Validators.required, Validators.pattern(nameOfSponser)],
  alphaNumericWithSpace: [
    Validators.required,
    Validators.pattern(alphaNumericWithSpace),
  ],
  //cin: [Validators.required, Validators.pattern(cin)],
  cin: [Validators.pattern(cin)],
};

function specialCharValidator(abstractControl: AbstractControl) {
  if (!abstractControl.value) {
    return;
  }
  const noSpecialChar =
    /\`|\~|\!|\-|\_|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:/.test(
      abstractControl.value
    );
  if (noSpecialChar) {
    return { specialError: true };
  } else {
    return null;
  }
}
