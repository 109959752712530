import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, validationType } from '../../core/model/error.model';
import { POA } from '../../core/model/poa.model';
import { CommonService } from '../../services/common/common.service';
import { POAService } from '../../services/poa/poa.service';
import { nonkycModelComponent } from '../non-kyc-modal/non-kyc-model.component';
import { OopsModalComponent } from '../oops-modal/oops-modal.component';

@Component({
  selector: 'app-add-poa',
  templateUrl: './add-poa.component.html',
  styleUrls: ['./add-poa.component.scss'],
})
export class AddPOAComponent implements OnInit {
  POAForm: FormGroup;
  error = Errors;
  showLoader = false;
  isIndividual = false;
  isNonIndividual = false;
  headerText = ''; //HEADER_TEXT_PANCHECK;
  messageText = ''; //MESSAGE_TEXT_PANCHECK;
  todayDate = new Date();
  @Input() applicationUuid: string;
  @Input() POALength: string;
  @Output() add: EventEmitter<any> = new EventEmitter();
  constructor(
    private modalController: ModalController,
    private POAService: POAService,
    private loadingController: LoadingController,
    private commonService: CommonService,
    private toastController: ToastController,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.createForm();
    console.log(this.applicationUuid);
    console.log(this.POALength);
  }

  get form() {
    return this.POAForm.controls;
  }

  createForm() {
    this.POAForm = new FormGroup({
      pan: new FormControl('', validationType.PAN),
      POAName: new FormControl('', validationType.Required),
      POAEmail: new FormControl('', validationType.email),
      sameasApplicant1: new FormControl('No'),
      signatoryName1: new FormControl(''),
      signatoryEmail1: new FormControl(''),
      signatoryName2: new FormControl(''),
      signatoryEmail2: new FormControl(''),
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  showHideDetails() {
    if (this.form.pan.value.length == 10) {
      console.log('Inside Show details');
      if (this.form.pan.value.charAt(3).toUpperCase() == 'P') {
        this.isIndividual = true;
        this.isNonIndividual = false;
      }
      else {
        this.isIndividual = false;
        this.isNonIndividual = true;
      }
    }
  }

  async submit() {
    // if (this.form.pan.value && this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
    //   this.form.pan.setErrors({ inProper: true });
    // }

    // this.POAForm.markAllAsTouched();
    // if (this.POAForm.invalid) return;
    // if (this.form.pan.value.charAt(3).toUpperCase() !== 'P') {
    //   this.form.pan.setErrors({ inProper: true });
    //   return;
    // }

    //console.log(this.form.pan.value.charAt(3).toUpperCase());
    //console.log('sameasApplicant1',this.form.sameasApplicant1.value);

    if(this.form.signatoryEmail1.value != '' && this.form.signatoryEmail2.value ! !='')
    {
        if(this.form.signatoryEmail1.value == this.form.signatoryEmail2.value)
        {
          this.showLoader = false;
          this.form.signatoryEmail1.setErrors({ duplicate: true });
          this.form.signatoryEmail2.setErrors({ duplicate: true });
          return;
        }
    }
    


    let payload = {} as POA;
    payload.applicationUuid = this.applicationUuid
    payload.pan = this.form.pan.value;
    payload.name = this.form.POAName.value;
    payload.email = this.form.POAEmail.value;
    payload.sameAsApplicant1 = this.form.sameasApplicant1.value;
    payload.signatoryName1 = this.form.signatoryName1.value;
    payload.signatoryEmail1 = this.form.signatoryEmail1.value;
    payload.signatoryName2 = this.form.signatoryName2.value;
    payload.signatoryEmail2 = this.form.signatoryEmail2.value;
    console.log('this.isIndividual', this.isIndividual);

    if (this.form.pan.value.charAt(3).toUpperCase() == 'P') {
      payload.typeOfInvestor = "Individual";

      if (this.form.POAName.value == '') {
        this.form.POAName.setErrors({ required: true });
        return;
      }

    }
    else {
      payload.typeOfInvestor = "Non-Individual";
      console.log('this.form.signatoryName1.value ', this.form.signatoryName1.value);

      if (this.form.signatoryName2.value != '') {
        if (this.form.signatoryName1.value == '') {
          this.form.signatoryName1.setErrors({ required: true });
          return;
        }
      }

      if (this.form.signatoryEmail2.value != '') {
        if (this.form.signatoryEmail1.value == '') {
          this.form.signatoryEmail1.setErrors({ required: true });
          return;
        }
      }
    }

    this.showLoader = true;
    this.headerText = 'Check CKYC';
    this.messageText = 'Please wait, Verifying CKYC and AML';

    this.POAService.addPOA(payload).subscribe(
      async (res: any) => {


        console.log("POA Respoonse ", res.message);
        if (res.message == "Email Id Already exist") {
          this.showLoader = false;
          this.form.POAEmail.setErrors({ duplicate: true });
        }
        else if(res.message == "Duplicate Email Id for Signatory 1")
        {
          this.showLoader = false;
          this.form.signatoryEmail1.setErrors({ duplicate: true });
        }
        else if(res.message == "Duplicate Email Id for Signatory 2" || res.message == "Email Id Already exist")
        {
          this.showLoader = false;
          this.form.signatoryEmail2.setErrors({ duplicate: true });
        }
        else if (res.message == "Pan Already exist") {
          this.showLoader = false;
          this.form.pan.setErrors({ duplicate: true });
        }
        else {
          this.commonService.encrypt(this.applicationUuid).subscribe((res: any) => {

            var applicationuuid = res.encryptedText.replaceAll('/', 'slh').replaceAll('==', '--').replaceAll('=', '-');

            window.location.href = '/application/personal-details/' + applicationuuid
          });
        }
      },
      async (err) => {
        this.showLoader = false;
        const toast = await this.toastController.create({
          message: err.error.message,
          duration: 2000,
        });
        toast.present();
      }
    );
  }
}
